import React from 'react';
import { Button } from '@mui/material';

export default function EmailMarketing() {
   return (
    <div className='smlwindowHeight'>
      <div className='contentForm smallPageWindow'>
        <div className='formHeading'>
          <div className='btnwithText'>
            <h2>Email Marketing</h2></div>
          <Button className='black-colorBtn'>Next</Button>
        </div>
          <div className='contentFormBody'>
            <div className='textwithimgBlock'>
              <div className='textblck'>
                <p>
                Drive more orders and more restaurant app downloads. Our dedicated success team provides support with custom marketing campaigns to boost customer engagement and increase sales. Increase loyalty using our email marketing best practices and strategies.
Interested?
                </p>
                <div className='formButtonMain'>
                  <Button>Schedule a Call</Button></div>
              </div>
            </div>
          </div>
      </div >
    </div>
  )
}