import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { LevelContext } from "../../App";
import { LiaEnvelope } from "react-icons/lia";
import { LiaPhoneSolid } from "react-icons/lia";

function SupportContent() {
  var isClass = "";
  if (localStorage.getItem("UserDetails")) {
    var IsApprovedUser = JSON.parse(
      localStorage.getItem("UserDetails")
    ).IsApprovedUser;
    if (IsApprovedUser === false) isClass = "form-overlay";
  }
  // const {isapproveduser, setIsApprovedUser} = useContext(LevelContext)

  const navigate = useNavigate();

  function RedirectToChildLink(link) {
    if (link != undefined) navigate(link);
  }

  return (
    <div className="sidebarOuter">
      <div className="sidebarInner">
        <div id="submenuHeading">
          <div class="sidebar-item open">
            <div id="menuHeading" class="sidebar-title toggle-btn">
              <span>SUPPORT</span>
            </div>
            {/* <div class="sidebar-content nosetup">
              <a>
                <a
                  onClick={() =>
                    RedirectToChildLink("/reseller/support/contact_us")
                  }
                  href="#"
                  class="sidebar-item plain"
                >
                  - Contact Us
                </a>
              </a>
            </div> */}
              <div className="contentForm form-container contactInfo">
        <div class="contact-block">
          <h1>Customer Support</h1>
          <div class="icon emailIcon">
            <a href="mailto:support@imenu360.com">
              <span>
                <LiaEnvelope />{" "}
              </span>
              support@imenu360.com
            </a>
          </div>

          <div class="icon callIcon">
            <a href="tel:86-629-9646">
              <span>
                <LiaPhoneSolid />{" "}
              </span>
              (866)629-9646 Ext. 1
            </a>
          </div>
        </div>
        <div class="contact-block">
          <h1>Contact Sales</h1>
          <div class="icon emailIcon">
            <a href="mailto:sales@imenu360.com">
              <span>
                <LiaEnvelope />{" "}
              </span>
              sales@imenu360.com
            </a>
          </div>

          <div class="icon callIcon">
            <a href="tel:86-629-9646">
              <span>
                <LiaPhoneSolid />{" "}
              </span>
              (866)629-9646 Ext. 2
            </a>
          </div>
        </div>
        <div class="contact-block">
          <h1>Billing</h1>
          <div class="icon emailIcon">
            <a href="mailto:billing@imenu360.com">
              <span>
                <LiaEnvelope />{" "}
              </span>
              billing@imenu360.com
            </a>
          </div>
          <div class="icon callIcon">
            <a href="tel:86-629-9646">
              <span>
                <LiaPhoneSolid />{" "}
              </span>
              (866)629-9646 Ext. 4
            </a>
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>

      <div id="divSub" className="RightMenu main">
        <div className={isClass}>
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
}
export default SupportContent;
