import React, { useEffect, useState } from 'react';
import { Input, PasswordInput, Form } from '@start-base/react-form-elements';
import { Button, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Modal, message } from 'antd'
import axios from 'axios';
import { APIUrl, StripeUrl } from '../..';
import SessionEnd from './SessionEnd';

export default function OnlinePayment() {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage();
  const [inputs, setInputs] = useState({});
  const [style, setStyle] = useState(null)
  const [colorYes, setYesColor] = useState('white')
  const [colorNo, setNoColor] = useState('white')
  const [colorTextYes, setColorTextYes] = useState('black')
  const [colorTextNo, setColorTextNo] = useState('black')
  const [yesNo, setYesNo] = useState('')
  const [unauthorized, setUnauthorized] = useState(false)
  const [restId, setRestId] = useState(null)
  const [isStripe, setIsStripe] = useState(false)
  const [showStripe, setShowStripe] = useState(false)

  const onChange = (e) => {
    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? !prevState[name] : value,
    }));
  };

  const getOnlinePayment = async () => {
    try {
      const locationID = localStorage.getItem("RestLocationId")
      const restChainId = localStorage.getItem("RestChainId")
      if (restChainId == 33566 || restChainId == 33590) {
        setStyle({
          pointerEvents: 'none',
          opacity: 0.5
        })
      }
      if (locationID) {
        setRestId(locationID)
        if (locationID == -1) {
          messageApi.open({
            type: 'warning',
            content: 'Please select restaurant first & then you can edit'
          })
          setStyle({
            pointerEvents: 'none',
            opacity: 0.5
          })
        } else {
          let Token = ""
          if (localStorage.getItem("UserDetails")) {
            Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
          }
          const dataTosend = { "data": { "token": Token, "RestLocationId": locationID } }
          const getPaymentResponse = await axios.post(APIUrl + 'GetAcceptOnlinePayment', dataTosend, {
            headers: { 'Content-Type': 'application/json' }
          })
          if (getPaymentResponse.data.data.isOnlinePayment === true) {
            setShowStripe(true)
            setYesNo('Yes')
            setYesColor('#1BC5BD')
            setColorTextYes('white')
          } else {
            setShowStripe(false)
            setYesNo('No')
            setNoColor('#F64E60')
            setColorTextNo('white')
          }
        }
      } else {
        messageApi.open({
          type: 'warning',
          content: 'Please select restaurant first & then you can edit'
        })
        setStyle({
          pointerEvents: 'none',
          opacity: 0.5
        })
      }
    } catch (error) {
      console.log(error);
      if (error.response.data == "Unauthorized") {
        setUnauthorized(true)
      }
    }
  }

  useEffect(() => {
    getOnlinePayment()
  }, [])

  const sendOnlinePaymentMail = async (isOnlinePaymentSelected, option) => {
    if (isOnlinePaymentSelected === true) {
      setYesColor('#1BC5BD')
      setColorTextNo('black')
      setNoColor('white')
      setShowStripe(true)
    } else {
      setNoColor('#F64E60')
      setColorTextYes('black')
      setYesColor('white')
      setShowStripe(false)
    }

    if (option == yesNo) {
      console.log('same')
    } else {
      console.log('diffrenet');
      setYesNo(option)
      const locationID = localStorage.getItem("RestLocationId")
      let Token = ""
      if (localStorage.getItem("UserDetails")) {
        Token = await JSON.parse(localStorage.getItem("UserDetails")).Token;
        if (locationID) {
          try {
            const dataTosend = { "data": { "token": Token, "RestLocationId": locationID, "isOnlinePayment": isOnlinePaymentSelected } }
            const getPaymentResponse = await axios.post(APIUrl + 'AcceptOnlinePayment', dataTosend, {
              headers: { 'Content-Type': 'application/json' }
            })
            if (getPaymentResponse.data.serviceStatus == 200) {
              if (isOnlinePaymentSelected) {
                messageApi.open({
                  type: 'success',
                  content: 'Online Payment Added'
                })
              } else {
                messageApi.open({
                  type: 'success',
                  content: 'Online Payment Removed'
                })
              }
            }
          }
          catch (error) {
            console.log(error)
            if (error.response.data == "Unauthorized") {
              setUnauthorized(true)
            } else {
              messageApi.open({
                type: 'error',
                content: 'Something went wrong, try again ?'
              })
            }
          }
        } else {
          messageApi.open({
            type: 'warning',
            content: 'Please select restaurant first & then you can edit'
          })
        }
      }
    }
  }

  return (
    <div className='smlwindowHeight'>
      {contextHolder}
      <div className='contentForm smallPageWindow'>
        {/* <div className='formHeading'>
        <Button onClick={() => { navigate('/reseller/setup/payment_methods') }}>Back</Button>
        <h2>Online Payment</h2><Button onClick={() => { navigate('/reseller/setup/receive_orders') }}>Next</Button>
      </div> */}

        <div className='formHeading'>
          <div className='btnwithText'>
            <Button onClick={() => {
              if (localStorage.getItem("RestLocationId")) {
                navigate('/reseller/setup/location')
              } else {
                navigate("/reseller/restaurant/list");
              }
            }}>Back</Button>
            {/* <div className='btnwithText'><Button onClick={() => { navigate('/reseller/setup/service_charges') }}>Back</Button> */}
            <h2>Online Payment</h2></div>
          {/* <Button className='black-colorBtn' onClick={() => { navigate('/reseller/setup/receive_orders') }}>Next</Button> */}
        </div>

        {/* <Form>
          <div className='Form_root_Inner'>
            <label>Accept online / credit card payments?
            </label>  <Button>Yes</Button>
            <Button>No</Button>
          </div>
          <div className='formButtonMain'>
            Select a payment processing:
            <Button>Stripe</Button>
            <Button>TSYS</Button>
            <Button>Others</Button>
          </div>
        </Form> */}

        <div className='onlinepaymentInner'>
          {/* <div>
            <span style={{ fontSize: 18, color: '#2C394B' }}>Accept online / credit card payments ?</span>
            <div>
              <div className='yesinputBox'>
                <input type='checkbox' className='yesinput' style={{ opacity: 0.011, zIndex: 100 }} />
                <label className='yeslabel'>Yes</label>
              </div>
            </div>
          </div> */}

          {/* <div>
            <div><span style={{ fontSize: 18, color: '#2C394B' }}>Accept online / credit card payments ?</span></div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <div className='yesinputBox'>
                <input className='yesinput' type="checkbox" />
                <label className='yeslabel'>Yes</label>
              </div>
              <div className='yesinputBox'>
                <input className='yesinput' type="checkbox" />
                <label className='yeslabel'>No</label>
              </div>
            </div>
          </div>
          <br/> */}

          {/* <div>
            <div>
              <span style={{ fontSize: 18, color: '#2C394B' }}>Select a payment processing</span>
            </div>
            <div className='paymentmethodslist'>
              <label className='choosepayment'>
                <div>
                  <Checkbox
                    size='small'
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    style={{ color: '#2C394B' }}
                  />
                  <span className='daylabel'>Stripe</span>
                </div>
              </label>
              <label className='choosepayment'>
                <div>
                  <Checkbox
                    size='small'
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    style={{ color: '#2C394B' }}
                  />
                  <span className='daylabel'>TSYS</span>
                </div>
              </label>
              <label className='choosepayment'>
                <div>
                  <Checkbox
                    size='small'
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    style={{ color: '#2C394B' }}
                  />
                  <span className='daylabel'>Others</span>
                </div>
              </label>
            </div>
          </div> */}

          <div style={style}>
            <div className='onlinePaymenTitleDiv'>
              <span className='onlinePaymenTitle'>Accept online / credit card payments ?</span>
            </div>

            <div className='YesNoBtnsDiv'>
              <Button style={{ backgroundColor: colorYes, color: colorTextYes, border: '1px solid #cccccc' }} onClick={() => sendOnlinePaymentMail(true, 'Yes')}>Yes</Button> &emsp;
              <Button style={{ backgroundColor: colorNo, color: colorTextNo, border: '1px solid #cccccc' }} onClick={() => sendOnlinePaymentMail(false, 'No')}>No</Button>
            </div>

            <div>
              {showStripe &&
                <div className='stripeDiv'>
                  <Checkbox size='small' style={{ color: '#2C394B' }} checked={isStripe} onChange={(e) => { setIsStripe(!isStripe) }} />
                  <span>Stripe</span>

                  {isStripe &&
                    <span className='clickherestripe'><a href={StripeUrl + restId} target='_blank'>(Click Here To Setup)</a></span>
                  }
                </div>
              }
            </div>
          </div>
          {/* <div>
            <div>
              <span style={{ fontSize: 18, color: '#2C394B' }}>Select a payment processing</span>
            </div>
            <br />
            <div>
              <Button style={{ backgroundColor: '#1BC5BD', color: 'white', border: '1px solid #cccccc'  }} onClick={() => { }}>Stripe</Button> &emsp;
              <Button style={{ backgroundColor: '#F64E60', color: 'white', border: '1px solid #cccccc'  }} onClick={() => { }}>TSYS</Button> &emsp;
              <Button style={{ backgroundColor: '#F64E60', color: 'white', border: '1px solid #cccccc'  }} onClick={() => { }}>Others</Button>
            </div>
          </div> */}
        </div>
        <div className='bottomDiv' style={style}>
          <button className='savebtn' onClick={() => { navigate('/reseller/setup/pickup') }}>Next</button>
          {/* <button className='savebtn' onClick={() => { navigate('/reseller/setup/receive_orders') }}>Next</button> */}
        </div>
        <SessionEnd openOrNot={unauthorized} />
      </div>
    </div>
  )
}