import React from 'react';
import { Button } from '@mui/material';

export default function POSIntegration() {
   return (
    <div className='smlwindowHeight'>
      <div className='contentForm smallPageWindow'>
        <div className='formHeading'>
          <div className='btnwithText'>
            <h2>POS Integration</h2></div>
          <Button className='black-colorBtn'>Next</Button>
        </div>
          <div className='contentFormBody'>
            <div className='textwithimgBlock'>
              <div className='textblck'>
                <p>
                This integration will help you aggregate all your 3rd party online orders and synchronizes them with your 360 POS, improving your restaurant’s operational flow in the process. Below are the three major benefits of using Deliverect:-All your online orders straight to your POS.Manage your menus better (from a single platform)Easy sales reportingThey are completely integrated with us. This means you can see all the orders from iMenu360 and other 3rd parties in your POS without those dozens of tablets at the counter. Interested?
                </p>
                <div className='formButtonMain'>
                  <Button>Schedule a Call Now</Button></div>
              </div>
            </div>
            <div className='textwithimgBlock'>
              <div className='textblck'>
                <p>
                Imagine a POS that will eliminate all tablets from your counters and route orders to printers throughout the restaurant.
And when a menu change is made, it needs to be done in only one place.
Also, imagine having this system without a contract, with pricing that is very fair and manageable.

Interested?
                </p>
                <div className='formButtonMain'>
                  <Button>Schedule a Call</Button></div>
              </div>
            </div>
          </div>
      </div >
    </div>
  )
}