import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { message } from 'antd'
import { Form } from '@start-base/react-form-elements';
import { APIUrl } from '../..';
import axios from 'axios';
import SessionEnd from './SessionEnd';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function Confirmation() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [tokenForUrl, setTokenForUrl] = useState('')
  const [messageApi, contextHolder] = message.useMessage();
  const [unauthorized, setUnauthorized] = useState(false)
  const [actionType, setActionType] = useState(localStorage.getItem("actiontype"))
  const [btnText, setBtnText] = useState('')
  const [details, setDetails] = useState({
    OwnerDetails: {
      Ownername: '',
      Owneremail: '',
      Ownerphone: '',
      Owneraddress: ''
    },
    RestaurantDetails: {
      Restaurantname: '',
      Restaurantemail: '',
      Restaurantphone: '',
      Restaurantaddress: [],
      Restaurantwebsite: '',
      Restauranturl: ''
    }
  })

  const getAllDetails = async () => {
    const actionType = localStorage.getItem("actiontype")
    if (actionType == 'add') {
      setBtnText('Submit')
    } else {
      setBtnText('Next')
    }
    const locationID = localStorage.getItem("RestLocationId")
    if (locationID) {
      try {
        let Token = ""
        if (localStorage.getItem("UserDetails")) {
          Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
          setTokenForUrl(Token)
        }
        const dataTosend = { "data": { "token": Token, "RestLocationId": locationID } }
        const getDetails = await axios.post(APIUrl + 'GetLocationById', dataTosend, {
          headers: { 'Content-Type': 'application/json' }
        })
        if (getDetails.data.serviceStatus == 200) {
          const newState = { ...details }
          newState.OwnerDetails.Ownername = getDetails.data.data.OwnerDetails.OwnerName
          newState.OwnerDetails.Owneremail = getDetails.data.data.OwnerDetails.OwnerEmail
          newState.OwnerDetails.Ownerphone = getDetails.data.data.OwnerDetails.OwnerPhone
          if (getDetails.data.data.OwnerDetails.OwnerAddress == null) {
            console.log(true);
            if (getDetails.data.data.RestoDetails.RestAddress == null) {
              return
            }
            newState.RestaurantDetails.Restaurantname = getDetails.data.data.RestoDetails.RestName
            newState.RestaurantDetails.Restaurantemail = getDetails.data.data.RestoDetails.RestEmail
            newState.RestaurantDetails.Restaurantphone = getDetails.data.data.RestoDetails.RestPhone
            newState.RestaurantDetails.Restaurantwebsite = getDetails.data.data.RestoDetails.RestWebsite
            newState.RestaurantDetails.Restauranturl = getDetails.data.data.RestoDetails.RestOrderingURL
            let address = getDetails.data.data.RestoDetails.RestAddress
            newState.RestaurantDetails.Restaurantaddress = address.StreetNameNumber + ", " + address.City + ", " + address.State + ", " + address.Country + ", " + address.PostalCode
            setDetails(newState)
            return
          }
          let oaddress = getDetails.data.data.OwnerDetails.OwnerAddress
          newState.OwnerDetails.Owneraddress = oaddress.StreetNameNumber + ", " + oaddress.City + ", " + oaddress.State + ", " + oaddress.Country + ", " + oaddress.PostalCode
          newState.RestaurantDetails.Restaurantname = getDetails.data.data.RestoDetails.RestName
          newState.RestaurantDetails.Restaurantemail = getDetails.data.data.RestoDetails.RestEmail
          newState.RestaurantDetails.Restaurantphone = getDetails.data.data.RestoDetails.RestPhone
          newState.RestaurantDetails.Restaurantwebsite = getDetails.data.data.RestoDetails.RestWebsite
          newState.RestaurantDetails.Restauranturl = getDetails.data.data.RestoDetails.RestOrderingURL
          if (getDetails.data.data.RestoDetails.RestAddress == null) {
            return
          }
          let address = getDetails.data.data.RestoDetails.RestAddress
          newState.RestaurantDetails.Restaurantaddress = address.StreetNameNumber + ", " + address.City + ", " + address.State + ", " + address.Country + ", " + address.PostalCode
          setDetails(newState)
        }
      } catch (error) {
        console.log(error);
        if (error.response?.data == "Unauthorized") {
          setUnauthorized(true)
        } else {
          messageApi.open({
            type: 'error',
            content: 'Something went wrong, try again ?'
          })
        }
      }
    } else {
      messageApi.open({
        type: 'warning',
        content: 'Please select restaurant first & then owner information can be visible'
      })
    }
  }

  const sendCofirmationMail = async () => {
    setLoading(true)
    const locationID = localStorage.getItem("RestLocationId")
    const RestChainName = localStorage.getItem("RestChainName")
    const actionType = localStorage.getItem("actiontype")
    try {
      if (actionType == 'add') {
        if (locationID) {
          let Token = ""
          if (localStorage.getItem("UserDetails")) {
            Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
            setTokenForUrl(Token)
          }
          const dataTosend = { "data": { "token": Token, "confirmationEmail": { "RestLocationId": locationID, "RestName": details.RestaurantDetails.Restaurantname, "RestChainName": RestChainName } } }
          const getDetails = await axios.post(APIUrl + 'SendConfirmationMail', dataTosend, {
            headers: { 'Content-Type': 'application/json' }
          })
          if (getDetails.data.serviceStatus == 200) {
            setLoading(false)
            messageApi.open({
              type: 'success',
              content: 'Check inbox for restaurant creation confirmation'
            })
            localStorage.setItem("actiontype", '')
            setTimeout(() => {
              navigate('/reseller/restaurant/list')
            }, 1000);
          }
        }
      } else {
        navigate('/reseller/restaurant/list')
      }
    } catch (error) {
      setLoading(false)
      if (error.response.data == "Unauthorized") {
        setUnauthorized(true)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllDetails()
  }, [])

  const RedirectURL = async () => {
    const restChainId = localStorage.getItem("RestChainId")
    let Token
    if (localStorage.getItem("UserDetails")) {
      Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
    }
    try {
      const getTokenResponse = await axios.post(APIUrl + 'GetRestAdminToken', { "data": { token: Token, RestChainId: restChainId } },
        { headers: { 'Content-Type': 'application/json' } })
      console.log(getTokenResponse);
      if (getTokenResponse.data.status == "s") {
        window.open(
          "https://restadmin.imenu360.com/index.html?tid=" +
          getTokenResponse.data.data.Token,
          "_blank"
        );
      }
    } catch (error) {
      if (error.response.data == "Unauthorized") {
        setUnauthorized(true);
      }
    }
  }

  return (
    <div className='smlwindowHeight'>
      <div className='contentForm smallPageWindow'>
        <div className='formHeading'>
          <div className='btnwithText'><Button onClick={() => { navigate('/reseller/setup/receive_orders') }}>Back</Button>
            <h2>Confirmation</h2></div>
          <Button className='black-colorBtn' onClick={() => { navigate('/reseller/setup/owner_personal_info') }}>Edit</Button>
        </div>

        <div className='confirmationBox'>
          {contextHolder}

          <div className='confirmInfo'>
            <h1 className='confirmationTitle' >Hi {details.OwnerDetails.Ownername ? (details.OwnerDetails.Ownername) : ('Owner Name')} !</h1>
            {/* <span className='confirmationSubTitle'>
              Before Submit please review your details below.
            </span><br />
            <span className='confirmationSubTitle'>
              I hope all the information is correct and usefull to submit.
            </span> */}
          </div>

          <hr />

          <div className='confirmInfo'>
            <h5 className='confirmOwner'>Owner Details</h5>
            <div className='confirmOwnerDiv'>
              <div className='confirmOwnerInner'>
                <span className='confirmTitle'>Name</span><br />
                <span className='confirmSubTitle'>{details.OwnerDetails.Ownername ? (details.OwnerDetails.Ownername) : ('NA')}</span>
              </div>
              <div className='confirmOwnerInner'>
                <span className='confirmTitle'>Email</span><br />
                <span className='confirmSubTitle'>{details.OwnerDetails.Owneremail ? (details.OwnerDetails.Owneremail) : ('NA')}</span>
              </div>
              <div className='confirmOwnerInner'>
                <span className='confirmTitle'>Phone</span><br />
                <span className='confirmSubTitle'>{details.OwnerDetails.Ownerphone ? (details.OwnerDetails.Ownerphone) : ('NA')}</span>
              </div>
            </div><br className='mobilebr' />
            <div className='confirmOwnerDiv'>
              <div className='confirmOwnerInner'>
                <span className='confirmTitle'>Address</span><br />
                <span className='confirmSubTitle'>{details.OwnerDetails.Owneraddress ? (details.OwnerDetails.Owneraddress) : ('NA')}</span>
              </div>
            </div>
          </div>

          <hr />

          <div className='confirmInfo'>
            <h5 className='confirmOwner'>Restaurant Details</h5>
            <div className='confirmOwnerDiv'>
              <div className='confirmOwnerInner'>
                <span className='confirmTitle'>Restaurant Name</span><br />
                <span className='confirmSubTitle'>{details.RestaurantDetails.Restaurantname ? (details.RestaurantDetails.Restaurantname) : ('NA')}</span>
              </div>
              <div className='confirmOwnerInner'>
                <span className='confirmTitle'>Restaurant Email</span><br />
                <span className='confirmSubTitle'>{details.RestaurantDetails.Restaurantemail ? (details.RestaurantDetails.Restaurantemail) : ('NA')}</span>
              </div>
              <div className='confirmOwnerInner'>
                <span className='confirmTitle'>Restaurant Phone</span><br />
                <span className='confirmSubTitle'>{details.RestaurantDetails.Restaurantphone ? (details.RestaurantDetails.Restaurantphone) : ('NA')}</span>
              </div>
            </div><br className='mobilebr' />
            <div className='confirmOwnerDiv'>
              <div className='confirmOwnerInner'>
                <span className='confirmTitle'>Restaurant Address</span><br />
                <span className='confirmSubTitle'>{details.RestaurantDetails.Restaurantaddress ? (details.RestaurantDetails.Restaurantaddress) : ('NA')}</span>
              </div>
              <div className='confirmOwnerInner'>
                <span className='confirmTitle'>This is Restaurant Website</span><br />
                <a onClick={() => RedirectURL()} >{details.RestaurantDetails.Restaurantwebsite ? (details.RestaurantDetails.Restaurantwebsite) : ('NA')}</a>
              </div>
            </div><br className='mobilebr' />
            <div className='confirmOwnerDiv'>
              <div className='confirmOwnerInner'>
                <span className='confirmTitle'>This is Restaurant URL</span><br />
                <a href={'https://orderonlinemenu.com/new_ui/default.aspx?urlname=' + details.RestaurantDetails.Restauranturl} target='_blank'>{details.RestaurantDetails.Restauranturl ? (details.RestaurantDetails.Restauranturl) : ('NA')}</a>
              </div>
            </div>
          </div>

        </div>
        {/* {actionType == 'add' && */}
        <div className='bottomDiv'>
          {/* <button className='savebtn' onClick={() => { sendCofirmationMail(); }}>{btnText}</button> */}
          <LoadingButton
            className='savebtn'
            onClick={() => sendCofirmationMail()}
            loading={loading}
            loadingPosition="end"
            endIcon={loading ? <CheckBoxOutlineBlankIcon color={"#2C394B"} style={{ color: "#2C394B" }} /> : ''}
          >
            <span>Next</span>
          </LoadingButton>
        </div>
        {/* } */}
        <SessionEnd openOrNot={unauthorized} />
      </div >
    </div>
  )
}