import React from 'react';
import { Button } from '@mui/material';

export default function NewMobileApps() {
   return (
    <div className='smlwindowHeight'>
      <div className='contentForm smallPageWindow'>
        <div className='formHeading'>
          <div className='btnwithText'>
            <h2>New Mobile Apps</h2></div>
          <Button className='black-colorBtn'>Next</Button>
        </div>
          <div className='contentFormBody'>
            <div className='textwithimgBlock'>
              <div className='textblck'>
                <p>
                Keep up with the competition with custom-built iPhone and Android restaurant apps that are branded to your business. Our mobile apps showcase your logo and food, creating the ultimate brand experience from mobile, to the web, to in-store.
Interested?
                </p>
                <div className='formButtonMain'>
                  <Button>Schedule a Call</Button></div>
              </div>
            </div>
          </div>
      </div >
    </div>
  )
}