import React, { useState } from "react";
import { message } from "antd";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Restaurant_AddUpdate() {
  const [inputs, setInputs] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [showerror, setShowError] = useState("");

  const newdata = [];
  newdata.isRestaurant = inputs.isRestaurant;

  const onChange = (e) => {
    setShowError("");
    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));
  };

  const navigate = useNavigate();
  return (
    <div className="smlwindowHeight">
      <div className="contentForm smallPageWindow">
        <div className="Form_root">
        {contextHolder}
          <div className="form-row location-block">
            <div class="radio-list">
              <label>
                <input
                  type="radio"
                  id="test1"
                  onChange={onChange}
                  name="isRestaurant"
                  value="-1"
                />
                <i></i>
                <span>
                  <p>New Location. </p>
                  <small>Please choose to add a new restaurant</small>
                </span>
              </label>
            </div>

            <div class="radio-list">
              <label>
                <input
                  type="radio"
                  id="test2"
                  onChange={onChange}
                  name="isRestaurant"
                  value="1"
                />
                <i></i>
                <span>
                  <p>Existing Chain.</p>
                  <small>
                    Please choose this if you have existing restaurant chain
                  </small>
                </span>
              </label>
            </div>
            <div className="error-msg">{showerror}</div>
          </div>
          <div className="formButtonMain">
            <Button
              onClick={() => {
                if (newdata.isRestaurant === undefined)
                  // setShowError("Choose one option.");
                  messageApi.open({
                    type: "warning",
                    content: "Please choose one option.",
                  });
                else if (newdata.isRestaurant == -1){
                  localStorage.removeItem("RestaurantDetails");
              localStorage.setItem("RestaurantEditDetails", -1);
              localStorage.setItem(
                "RestLocationId",
              -1
              );
                  navigate("/reseller/setup/name_adress");
                }
                else if (newdata.isRestaurant == 1)
                  navigate("/reseller/setup/chainlist");
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}