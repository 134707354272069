import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Input, TextArea, Label } from "@start-base/react-form-elements";
import { Button } from "@mui/material";
import { RadioGroup, RadioButton } from "react-radio-buttons";
import ReactDOM from "react-dom";
import TimezoneSelect from "react-timezone-select";
import Axios from "axios";
import validator from "validator";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import ZoomImg from "../images/zoom.svg";
import GoogleMeetImg from "../images/googlemeet.svg";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { APIUrl } from "..";
import dayjs from "dayjs";
import { message } from 'antd';
import globe from '../images/globe-icon.png'

function ScheduleCall() {
  const resellerparam = useLocation();
  const [optionList, setOptionList] = useState([]);
  const [showphonevalid, setShowPhoneValid] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [restaurantTimezone, setrestaurantTimezone] = useState()
  const [timeZoneName, setTimeZoneName] = useState('')

  const [inputs, setInputs] = useState({});
  const newdata = [];
  newdata.email = inputs.email;
  newdata.name = inputs.name;
  newdata.guestemail = inputs.guestemail;
  newdata.scheduleby = inputs.scheduleby;
  newdata.phoneno = inputs.phoneno;
  newdata.help = inputs.help;

  const getTZ = () => {
    var Token = "";
    if (localStorage.getItem("UserDetails"))
      Token = JSON.parse(localStorage.getItem("UserDetails")).Token;

    Axios.post(APIUrl + "getTimeZones", {
      data: { token: Token },
    })
      .then((response) => {
        setOptionList(response.data.data.listTimeZones);
      })
      .catch((e) => {
        setOptionList([]);
      });
  };

  useEffect(() => {
    getTZ()
  }, [])

  const onChange = (e) => {
    if (e.target.name == "name") setShowNameError("");
    if (e.target.name == "email") setShowEmailError("");
    //if (e.target.name == "phoneno") setShowPhoneError("");
    if (e.target.name == "guestemail") setShowGuestEmailError("");

    if (e.target.name == "phoneno") {
      setShowPhoneError("");
      const result = e.target.value.replace(/\D/g, "");
      setShowPhoneValid(result);
    }

    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));
  };

  // const [selectedTimezone, setSelectedTimezone] = useState("");

  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date());

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const [showdts, setShowDts] = useState(false);
  const [show, setShow] = useState(true);
  const [showguest, setShowGuest] = useState(false);

  const [showzoom, setShowZoomDts] = useState(false);
  const [showgooglemeet, setShowGoogleMeet] = useState(false);
  const [showphone, setShowPhone] = useState(false);

  const [showtimezoneerror, setShowTimeZoneError] = useState("");

  const [shownameerror, setShowNameError] = useState("");
  const [showemailerror, setShowEmailError] = useState("");
  const [showlocationerror, setShowLocationError] = useState("");
  const [showphoneerror, setShowPhoneError] = useState("");
  const [showguestemailerror, setShowGuestEmailError] = useState("");

  const [datetimeinfo, setDateTimeInfo] = useState({});

  function ShowDscheduleDts() {
    // if (selectedTimezone) {
    if (restaurantTimezone) {
      setShowTimeZoneError("");

      // var a = new Date(startDate).toString("HH:MM").split("GMT")[0];
      // setDateTimeInfo(" " + a + " " + selectedTimezone.label);

      setShowDts(true);
      setShow(false);
    } else {
      //alert("Select timezone");
      setShowTimeZoneError("Select timezone");
    }
  }

  function ScheduleEvent() {
    var isPassOn = true;

    if (!newdata.name) setShowNameError("Can't be blank.");

    if (!newdata.email) setShowEmailError("Can't be blank.");

    if (!newdata.scheduleby)
      setShowLocationError("One location must be selected.");

    if (newdata.guestemail) {
      var a = newdata.guestemail.split(",");
      for (var i = 0; i < a.length; i++) {
        if (!validator.isEmail(a[i])) {
          //alert("Guest email is not valid");
          setShowGuestEmailError("Guest email is not valid");
          return false;
        }
      }
    }

    if (newdata.scheduleby === "phonecall") {
      if (showphonevalid) {
        if (!validator.isMobilePhone(showphonevalid)) {
          //alert("Enter valid phone number");
          setShowPhoneError("Enter valid phone number");
          return false;
        }
      } else {
        //alert("Enter phone number");
        setShowPhoneError("Enter phone number");
        return false;
      }
    }

    if (newdata.name && newdata.email && newdata.scheduleby && startDate) {
      if (validator.isEmail(newdata.email)) {
        var Token = "";
        if (localStorage.getItem("UserDetails"))
          Token = JSON.parse(localStorage.getItem("UserDetails")).Token;

        Axios.post(APIUrl + "ScheduleCall", {
          data: {
            token: Token,
            scheduleCall: {
              ResellerId: resellerparam.state.UId,
              Name: newdata.name,
              CallTime: dayjs(startDate).format('YYYY-MM-DD HH:mm:ss'),
              Email: newdata.email,
              GuestEmails: newdata.guestemail,
              ScheduledOver: newdata.scheduleby,
              Phone: showphonevalid,
              Notes: newdata.help,
              TimeZoneId: restaurantTimezone
              //"GuestEmails" : "test1@gmail.com,test2@gmail.com,test3@gmail.com",
              //"CallTime":"2024-01-05 11:06:12.197",
              //"ScheduledOver":"googleMeet",//zoomCall,phoneCall
            },
          },
        })
          .then((response) => {
            console.log(response.data.serviceStatus);
            console.log(response.data.message);
            if (response.data.serviceStatus == 200) {
              if (response.data.status == 'f') {
                messageApi.open({
                  type: 'warning',
                  content: response.data.message
                })
              }
              if (response.data.status == 's') {
                messageApi.open({
                  type: 'success',
                  content: response.data.message
                })
              }
              setTimeout(() => {
                navigate('/reseller/restaurant/list')
              }, 1000);
            }
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      } else setShowEmailError("Email is not valid."); //alert("Email is not valid");
    } else if (!newdata.name) setShowNameError("Can't be blank.");
    else if (!newdata.email) setShowEmailError("Can't be blank.");
    else if (!newdata.scheduleby)
      setShowLocationError("One location must be selected.");
  }

  function findTimeZoneNameById(id) {
    const timezone = optionList.find(tz => tz.TimezoneId == id);
    setTimeZoneName(timezone.TimeZoneName)
  }

  return (
    // <div>
    //   {contextHolder}
    //   {show && (
    //     <div id="divSchedule" className="white-block-container">
    //       <p>iMenu360</p>
    //       <h1>Discovery call - My Cloud POS</h1>
    //       <div class="scheduleDtls">
    //         <div className="titleIcon">
    //           <AccessTimeOutlinedIcon /> 30min
    //         </div>
    //       </div>
    //       <div className="calendar-wrapper">
    //         {/* <label>Date</label> */}
    //         {new Date(startDate).toString("HH:MM").split("GMT")[0]}
    //         <div class="lineDiv"></div>
    //         <div className="heading-block">
    //           <h2>Select a Date & Time</h2>
    //         </div>
    //         <DatePicker
    //           selected={startDate}
    //           onChange={(date) => {
    //             setStartDate(date);
    //             //ShowDscheduleDts(date);
    //           }}
    //           open="true"
    //           showTimeSelect
    //           filterTime={filterPassedTime}
    //           dateFormat="MMMM d, yyyy h:mm aa"
    //           name="scheduledDatetime"
    //         />

    //         <div className="time-zone">
    //           <h2>Time zone</h2>
    //           <div className="time-zone-inner">
    //             <div className=" dropdown-block">
    //               <div className="select-wrapper">
    //                 {/* <PublicOutlinedIcon /> */}
    //                 {/* <TimezoneSelect
    //                   menuPlacement="top"
    //                   value={selectedTimezone}
    //                   onChange={setSelectedTimezone}
    //                   onFocus={() => {
    //                     setShowTimeZoneError("");
    //                   }}
    //                 /> */}
    //                 <img className="img-fluid" src={globe} />
    //                 <select
    //                   value={restaurantTimezone}
    //                   required="true"
    //                   name="restaurantTimezone"
    //                   onChange={(e) => { setrestaurantTimezone(e.target.value); findTimeZoneNameById(e.target.value); setShowTimeZoneError(); }}
    //                 >
    //                   <option value="">Choose Timezone</option>
    //                   {optionList.map((item) => (
    //                     <option key={item.TimezoneId} value={item.TimezoneId}>
    //                       {item.TimeZoneName}
    //                     </option>
    //                   ))}
    //                 </select>
    //               </div>
    //               <div className="error-block">{showtimezoneerror}</div>
    //             </div>
    //             <Button
    //               className="next-btn"
    //               onClick={() => {
    //                 ShowDscheduleDts();
    //               }}
    //             >
    //               Next
    //             </Button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    //   {showdts && (
    //     <div id="divScheduleDts">
    //       <div className="white-block-container">
    //         <Button
    //           className="back-btn"
    //           onClick={() => {
    //             setShowDts(false);
    //             setShow(true);
    //           }}
    //         >
    //           <ArrowBackIosIcon />
    //         </Button>
    //         <p>iMenu360</p>
    //         <h1>Discovery call - My Cloud POS</h1>
    //         {/* <p>30 min  {datetimeinfo}</p> */}
    //         <div class="scheduleDtls">
    //           <div className="titleIcon">
    //             <AccessTimeOutlinedIcon /> <span>30min</span>
    //           </div>
    //           <div className="titleIcon">
    //             <DateRangeOutlinedIcon />
    //             {/* 12:00am - 12:30am, */}
    //             <span>{new Date(startDate).toString("HH:MM").split("GMT")[0] + 'ss'}</span>
    //           </div>
    //         </div>
    //         <div class="scheduleDtls">
    //           <div className="titleIcon">
    //             <PublicOutlinedIcon />
    //             {/* {selectedTimezone.label} */}
    //             <span>{timeZoneName}</span>
    //           </div>
    //         </div>
    //         <div className="lineDiv"></div>
    //         <div className="form-block">
    //           <Form>
    //             <div className="form-row">
    //               <Input
    //                 label="Name*"
    //                 name="name"
    //                 value={inputs.name}
    //                 onChange={onChange}
    //               ></Input>
    //               <div className="error-msg">{shownameerror}</div>
    //             </div>
    //             <div className="form-row">
    //               <Input
    //                 label="Email*"
    //                 name="email"
    //                 value={inputs.email}
    //                 onChange={onChange}
    //               />
    //               <div className="error-msg">{showemailerror}</div>
    //             </div>
    //             <Button className="blue-btn" onClick={() => setShowGuest(true)}>
    //               Add Guests
    //             </Button>

    //             {showguest && (
    //               <div className="form-row msg-error-div">
    //                 <TextArea
    //                   rows={3}
    //                   label="Guest Email(s)"
    //                   name="guestemail"
    //                   value={inputs.guestemail}
    //                   onChange={onChange}
    //                 ></TextArea>
    //                 <div className="error-msg">{showguestemailerror}</div>
    //               </div>
    //             )}

    //             <div className="guests-dtls">
    //               <div className="radio-list zoom">
    //                 <input
    //                   type="radio"
    //                   name="scheduleby"
    //                   value="zoom"
    //                   onChange={onChange}
    //                   onClick={() => {
    //                     setShowZoomDts(true);
    //                     setShowGoogleMeet(false);
    //                     setShowPhone(false);
    //                     setShowLocationError("");
    //                   }}
    //                 ></input>
    //                 <img src={ZoomImg} className="img-fluid"></img>
    //                 <span>Zoom</span>
    //               </div>
    //               {showzoom && (
    //                 <label className="note">
    //                   Web conferencing details provided upon confirmation.
    //                 </label>
    //               )}
    //             </div>
    //             <div className="guests-dtls">
    //               <div className="radio-list googlemeet">
    //                 <input
    //                   type="radio"
    //                   name="scheduleby"
    //                   value="googleMeet"
    //                   onChange={onChange}
    //                   onClick={() => {
    //                     setShowZoomDts(false);
    //                     setShowGoogleMeet(true);
    //                     setShowPhone(false);
    //                     setShowLocationError("");
    //                   }}
    //                 ></input>
    //                 <img src={GoogleMeetImg} className="img-fluid"></img>
    //                 <span>Google Meet</span>
    //               </div>
    //               {showgooglemeet && (
    //                 <label className="note">
    //                   Web conferencing details provided upon confirmation.
    //                 </label>
    //               )}
    //             </div>
    //             <div className="guests-dtls">
    //               <div className="radio-list phonecall">
    //                 <input
    //                   type="radio"
    //                   name="scheduleby"
    //                   value="phonecall"
    //                   onChange={onChange}
    //                   onClick={() => {
    //                     setShowZoomDts(false);
    //                     setShowGoogleMeet(false);
    //                     setShowPhone(true);
    //                     setShowLocationError("");
    //                   }}
    //                 ></input>
    //                 <LocalPhoneIcon />
    //                 <span>Phone Call</span>
    //               </div>
    //               {showphone && (
    //                 <div className="phoneNum form-row">
    //                   <Input
    //                     name="phoneno"
    //                     onChange={onChange}
    //                     value={showphonevalid}
    //                   />
    //                   <div className="error-msg">{showphoneerror}</div>
    //                 </div>
    //               )}
    //             </div>
    //             <div className="error-msg">{showlocationerror}</div>
    //             <TextArea
    //               rows={3}
    //               label="Please share anything that will help prepare for our meeting."
    //               name="help"
    //               onChange={onChange}
    //               value={inputs.help}
    //             ></TextArea>
    //             <div className="btn-row">
    //               <Button onClick={() => ScheduleEvent()}>
    //                 Schedule Event
    //               </Button>
    //             </div>
    //           </Form>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </div>
    <div className="scheduleCallCalendlyMain">

      <div className="scheduleCallCalendlyInner">
        <button className="savebtn" onClick={() => navigate('/reseller/restaurant/list')}>Close</button>
      </div>
      {/* <iframe src="https://calendly.com/imenu360demo/30min?embed_domain=staging.m1.imenu360.com%2F&embed_type=Inline&hide_event_type_details=1&month=2024-03" width="100%" height="100%" ></iframe> */}
      {/* <iframe src="https://calendly.com/imenu360demo/30min?embed_domain=staging.m1.imenu360.com&amp;embed_type=Inline&amp;hide_event_type_details=1" width="100%" height="100%" frameborder="0" title="Select a Date &amp; Time - Calendly"></iframe> */}
      <iframe src="https://calendly.com/imenu360demo/discovery-call-my-cloud-pos" width="100%" height="100%" frameborder="0"></iframe>
    </div>
  );
}

export default ScheduleCall;
