import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom';

function ApprovalContent() {
    var isClass = "";
    if (localStorage.getItem("UserDetails")){
    var IsApprovedUser = JSON.parse(
        localStorage.getItem("UserDetails")
      ).IsApprovedUser;
      if(IsApprovedUser===false)
      isClass="form-overlay";
    }

    const navigate = useNavigate()

    function RedirectToChildLink(link) {
        if (link != undefined)
            navigate(link)
    }

    return (
        <div className='sidebarOuter'>
            <div className='sidebarInner'>
                <div id="submenuHeading">
                    <div class="sidebar-item open">
                        <div id="menuHeading" class="sidebar-title toggle-btn">
                            <span>PENDING APPROVAL</span>
                        </div>
                        <div class="sidebar-content nosetup">
                            <a><a onClick={() => RedirectToChildLink("/reseller/approval/pending_approval")}  href="#" class="sidebar-item plain">- Pending Approval</a></a>
                            </div>
                    </div>
                </div></div>

            <div id="divSub" className='RightMenu main'>
            <div className={isClass}>
                <Outlet></Outlet>
                </div>
            </div>
        </div>
    )
}
export default ApprovalContent