import React from 'react';
import { Button } from '@mui/material';

export default function DeliveryIntegration() {
   return (
    <div className='smlwindowHeight'>
      <div className='contentForm smallPageWindow'>
        <div className='formHeading'>
          <div className='btnwithText'>
            <h2>Delivery Integration</h2></div>
          <Button className='black-colorBtn'>Next</Button>
        </div>
          <div className='contentFormBody'>
            <div className='textwithimgBlock'>
              <div className='textblck'>
                <p>
Nash is a software that makes it easy to activate delivery for your restaurant without hiring staff on your payroll. They are now integrated with us and many of our restaurants are using this delivery solution rather than losing customers on third-party portals. There is No Set-Up fee and you only pay the delivery fee shown for drivers and a $1 per delivery. You can auto-dispatch for every delivery or you can choose delivery for specific orders at your convenience.

Also, we can update the delivery cost for your customers at checkout.

Interested?
                </p>
                <div className='formButtonMain'>
                  <Button>Schedule a Call Now</Button></div>
              </div>
            </div>
          </div>
      </div >
    </div>
  )
}