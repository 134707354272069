import React from 'react';

export default function Commission() {


  var isClass = "",
    IsSuperAdmin = false;
  if (localStorage.getItem("UserDetails")) {
    var IsApprovedUser = JSON.parse(
      localStorage.getItem("UserDetails")
    ).IsApprovedUser;

    IsSuperAdmin = JSON.parse(localStorage.getItem("UserDetails")).IsSuperAdmin;
    if (IsApprovedUser === false) isClass = "form-overlay";
  }

  return (
    // <div className='smlwindowHeight'>
    //   <div className='contentForm smallPageWindow'>
    //     <div className='formHeading'>
    //       <div className='btnwithText'>
    //         <h2>COMMISSION</h2></div>
    //     </div>
    //       <div className='contentFormBody'>
    //         <div className='textwithimgBlock'>
    //           <div className='textblck'>
    //             <p>
    //             COMING SOON
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //   </div >
    // </div>
    <div class={"coming-soon"}></div>
  )
}