import React from 'react';
import { Button } from '@mui/material';

export default function Review() {
   return (
    <div className='smlwindowHeight'>
      <div className='contentForm smallPageWindow'>
        <div className='formHeading'>
          <div className='btnwithText'>
            <h2>5 Star Ratings</h2></div>
          <Button className='black-colorBtn'>Next</Button>
        </div>
          <div className='contentFormBody'>
            <div className='textwithimgBlock'>
              <div className='textblck'>
                <p>

                This new tool (ovation) is driving 5-star reviews on Google/Yelp/Tripadvisor. It's completely integrated with our online ordering and with every order you get a chance to get a new 5-star review or eliminate any bad reviews. We have been testing it at hundreds of restaurants and it has tremendous value.
This is 100% automated and you don't have to do anything(just respond to bad comments to eliminate bad reviews and cherish the customers). Every customer who places an online order is automatically sent a text asking them to rate their experience. If they rate their experience 5 stars they are then asked to post it on Google and if they leave a bad rating then you get notified in the app so you can resolve the issue on the spot.

Interested?
                </p>
                <div className='formButtonMain'>
                  <Button>Schedule a Call</Button></div>
              </div>
            </div>
          </div>
      </div >
    </div>
  )
}