import React, { useState } from 'react';
import { Input, Checkbox } from '@start-base/react-form-elements';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Delivery_Zone() {
    const navigate = useNavigate()

    const [inputs, setInputs] = useState({});
    const onChange = (e) => {
        const { name, value, type } = e.target;

        setInputs((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? !prevState[name] : value,
        }));
    };

    return (
        <div className='smlwindowHeight'>
            <div className='contentForm smallPageWindow'>
                <div className='formHeading'>
                    <div className='btnwithText'><Button onClick={() => { navigate('/reseller/setup/delivery') }}>Back</Button>
                        <h2>Delivery Zone</h2></div>
                    <Button className='black-colorBtn' onClick={() => { navigate('/reseller/setup/dine_in') }}>Next</Button>
                </div>
                <div>
                    <table border={'true'}>
                        <tr><td>Name</td><td>Address</td><td>Telephone</td></tr><tr><td>Demo Restaurant</td><td>12 Birchwood Ave, Peobody MA USA 96218</td><td>773-404-7587</td></tr>
                    </table>
                </div>
                <div>Note :
                    On initial set up zone 1 creates a 1 mile radius, zone 2 a 3 mile radius and zone 3 a 5 mile radius.
                    These zones can be adjusted by moving the small circles. You must save for the zones to take effect.
                    In addition, these zones act as a guide to your customers and do allow orders to be taken outside the zone.
                    To block customers from ordering outside these delivery zones, please check box.</div>
                <div>
                    <Checkbox label="I understand and accept the terms and conditions and privacy policy." />
                    Delivery Area Charges:<Checkbox label="Zone 1" /> <Input /><Checkbox label="Zone 2" /> <Input /><Checkbox label="Zone 3" /> <Input />
                </div>
            </div >
        </div>
    )
}