import { Button, Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Form, Input } from "@start-base/react-form-elements";
import { message } from 'antd'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SessionEnd from "./SessionEnd";
import { APIUrl } from "../..";
import axios, { Axios } from "axios";
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function Menu() {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [unauthorized, setUnauthorized] = useState(false)
    const [style, setStyle] = useState(null)
    const [menuType, setMenuType] = useState('')
    const [isOption1, setIsOption1] = useState(false)
    const [isOption2, setIsOption2] = useState(false)
    const [token, setToken] = useState('')
    const [uploadMenuUrl, setUploadMenuUrl] = useState('')
    const [fileArray, setFileArray] = useState([])
    const [err, setErr] = useState('')
    const [err2, setErr2] = useState('')
    const [loading, setLoading] = useState(false);

    const getMenus = async () => {
        let Token
        if (localStorage.getItem("UserDetails")) {
            Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
            setToken(Token)
        }
        const locationID = localStorage.getItem("RestLocationId")
        const restChainId = localStorage.getItem("RestChainId")
        if (restChainId == 33566 || restChainId == 33590) {
            setStyle({
                pointerEvents: 'none',
                opacity: 0.5
            })
        } else {
            if (locationID) {
                if (locationID == -1) {
                    messageApi.open({
                        type: 'warning',
                        content: 'Please select restaurant first & then you can edit'
                    })
                    setStyle({
                        pointerEvents: 'none',
                        opacity: 0.5
                    })
                } else {
                    try {
                        const dataTosend = { "data": { "token": Token, "RestLocationId": locationID } }
                        const getMenuResponse = await axios.post(APIUrl + 'GetMenuDetailsSetUp', dataTosend, {
                            headers: { 'Content-Type': 'application/json' }
                        })
                        setMenuType(getMenuResponse.data.data.MenuSetupType ? getMenuResponse.data.data.MenuSetupType : 'Choose')
                        setUploadMenuUrl(getMenuResponse.data.data.ShareMenuUrl)
                        setIsOption1(getMenuResponse.data.data.isUploadMenu)
                        setIsOption2(getMenuResponse.data.data.isShareUrl)
                    } catch (error) {
                        console.log(error)
                        if (error.response.data == "Unauthorized") {
                            setUnauthorized(true)
                        } else {
                            messageApi.open({
                                type: 'error',
                                content: 'Something went wrong, try again ?'
                            })
                        }
                    }
                }
            } else {
                messageApi.open({
                    type: 'warning',
                    content: 'Please select restaurant first & then you can edit'
                })
                setStyle({
                    pointerEvents: 'none',
                    opacity: 0.5
                })
            }
        }
    }

    useEffect(() => {
        getMenus()
    }, [])

    const selectFiles = async (files) => {
        for (let index = 0; index < files.length; index++) {
            const reader = new FileReader();
            reader.readAsDataURL(files[index])
            reader.onload = async () => {
                console.log('ss');
                const base64String = reader.result
                console.log(base64String);
                const newArray = fileArray
                newArray.push(base64String)
                setFileArray(newArray)
            }
        }
        console.log(fileArray.length);
    }

    const saveMenu = async () => {
        setLoading(true)
        if (menuType == 'Choose') {
            setErr2('Cannot select default')
            return
        }
        if (menuType == 'Upload Menu') {
            if (!isOption1 && !isOption2) {
                console.log('both false');
                setErr2('Select any of option')
                return
            }
            if (isOption2) {
                // if (!/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(uploadMenuUrl)) {
                if (!/^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(?:\.[a-zA-Z]{2,})?\/?$/.test(uploadMenuUrl)) {
                    setErr('Enter a valid url')
                    return
                }
            }
            if (isOption1) {
                console.log('op1');
                if (fileArray.length == 0) {
                    setErr('Please select the files')
                    return
                }
            }
        }
        let newOption1 = isOption1
        let newOption2 = isOption2
        if (menuType == 'Create Menu Yourself') {
            console.log('inside');
            newOption1 = false
            newOption2 = false
        }
        const locationID = localStorage.getItem("RestLocationId")
        let Token = ""
        if (localStorage.getItem("UserDetails")) {
            Token = await JSON.parse(localStorage.getItem("UserDetails")).Token;
            if (locationID) {
                try {
                    const dataTosend = {
                        "data": {
                            "token": Token,
                            "menuSetup": {
                                "RestLocationId": locationID,
                                "MenuSetupType": menuType,
                                "isUploadMenu": newOption1,
                                "isShareUrl": newOption2,
                                "files": fileArray,
                                "ShareMenuUrl": uploadMenuUrl
                            }
                        }
                    }
                    const getMenuResponse = await axios.post(APIUrl + 'MenuSetUp', dataTosend, {
                        headers: { 'Content-Type': 'application/json' }
                    })
                    if (getMenuResponse.data.serviceStatus == 200) {
                        setLoading(false)
                        messageApi.open({
                            type: 'success',
                            content: 'Menu setup email sent successfully'
                        })
                        setTimeout(() => {
                            navigate('/reseller/setup/receive_orders')
                        }, 1000);
                    }
                }
                catch (error) {
                    setLoading(false)
                    console.log(error)
                    if (error.response.data == "Unauthorized") {
                        setUnauthorized(true)
                    } else {
                        messageApi.open({
                            type: 'error',
                            content: 'Something went wrong, try again ?'
                        })
                    }
                }
            } else {
                messageApi.open({
                    type: 'warning',
                    content: 'Please select restaurant first & then you can edit'
                })
            }
        }
        setLoading(false)
    }

    const RedirectURL = async () => {
        const restChainId = localStorage.getItem("RestChainId")
        let Token
        if (localStorage.getItem("UserDetails")) {
            Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
            setToken(Token)
        }
        try {
            const getTokenResponse = await axios.post(APIUrl + 'GetRestAdminToken', { "data": { token: Token, RestChainId: restChainId } },
                { headers: { 'Content-Type': 'application/json' } })
            console.log(getTokenResponse);
            if (getTokenResponse.data.status == "s") {
                window.open(
                    "https://restadmin.imenu360.com/index.html?tid=" +
                    getTokenResponse.data.data.Token,
                    "_blank"
                );
            }
        } catch (error) {
            if (error.response.data == "Unauthorized") {
                setUnauthorized(true);
            }
        }
    }

    return (
        <>
            <div className="smlwindowHeight">
                <div className="contentForm">
                    <div className="formHeading">
                        <Button onClick={() => {
                            if (localStorage.getItem("RestLocationId")) {
                                navigate("/reseller/setup/service_charges");
                            } else {
                                navigate("/reseller/restaurant/list");
                            }
                        }} >Back</Button>
                        <h2>Menu</h2>
                    </div>
                    <Form>
                        {contextHolder}
                        <div className="Form_root_Inner" style={style}>
                            <div className="InputSelect">
                                <label>Select menu setup type:</label>
                                <select required="true" onChange={(e) => { setMenuType(e.target.value); setErr(''); setErr2(''); }} value={menuType}>
                                    <option value="Choose">Choose Menu Setup Type</option>
                                    <option value="Create Menu Yourself">Create Menu Yourself</option>
                                    <option value="Upload Menu">Upload Menu for support to create it for you</option>
                                </select>
                            </div>
                            {menuType == "Upload Menu" &&
                                <div className="uploadmenuDiv">
                                    <label>
                                        <Radio size="small" style={{ color: '#2C394B' }} onChange={(e) => { setIsOption1(true); setIsOption2(false); setErr(''); setErr2(''); }} checked={isOption1} />
                                        <span style={{ fontSize: 13 }}>Upload Menu</span>
                                    </label>
                                    <label>
                                        <Radio size="small" style={{ color: '#2C394B' }} onChange={(e) => { setIsOption1(false); setIsOption2(true); setErr(''); setErr2(''); }} checked={isOption2} />
                                        <span style={{ fontSize: 13 }}>Share Menu URL</span>
                                    </label>
                                </div>
                            }
                            {/* {!isOption1 && !isOption2 && menuType == "Choose" && */}
                            <span style={{ color: 'red', marginTop: 10 }}>{err2}</span>
                            {/* } */}
                            {/* {!isOption1 && !isOption2 && menuType == "Upload Menu" && */}
                            {/* <span style={{ color: 'red', marginTop: 10 }}> &nbsp; {err}</span> */}
                            {/* } */}
                            {menuType == "Create Menu Yourself" &&
                                <div className="createMenuDiv">
                                    <span><a onClick={() => RedirectURL()}>Click here to create menu</a></span>
                                </div>
                            }
                            <div style={{ margin: '30px 0 0 0' }}>
                                {menuType == "Upload Menu" && isOption1 &&
                                    <div className="InputSelect">
                                        <label>Select menu file</label>
                                        <input className="menuInput" type="file" multiple onChange={(e) => { selectFiles(e.target.files); setErr(''); setErr2(''); }} />
                                        <span style={{ color: '#2C394B' }}> &nbsp; *file can be image, pdf, word, excel, text</span><br />
                                        <span style={{ color: 'red' }}> &nbsp; {err}</span>
                                    </div>
                                }
                                {menuType == "Upload Menu" && isOption2 &&
                                    <div className="InputSelect">
                                        <label>Enter menu url</label>
                                        <Input value={uploadMenuUrl} onChange={(e) => { setUploadMenuUrl(e.target.value); setErr(''); setErr2(''); }} type="url" required />
                                        <span style={{ color: 'red' }}> &nbsp; {err}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="formButtonMain" style={style}>
                            {/* <Button onClick={() => saveMenu()} >Next</Button> */}
                            <LoadingButton
                                onClick={() => saveMenu()}
                                loading={loading}
                                loadingPosition="end"
                                endIcon={loading ? <CheckBoxOutlineBlankIcon color={"#2C394B"} style={{ color: "#2C394B" }} /> : ''}
                            >
                                <span>Save</span>
                            </LoadingButton>
                        </div>
                    </Form>
                </div>
                <SessionEnd openOrNot={unauthorized} />
            </div>
        </>
    )
}