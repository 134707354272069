import React, { useState, useEffect } from "react";
import { Form, Input } from "@start-base/react-form-elements";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { message } from 'antd'
import Axios from "axios";
import { APIUrl } from "../..";
import SessionEnd from './SessionEnd';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function Taxation() {
  const [messageApi, contextHolder] = message.useMessage();
  const [unauthorized, setUnauthorized] = useState(false)
  const [style, setStyle] = useState(null)
  const [loading, setLoading] = useState(false);

  var Token = "";
  if (localStorage.getItem("UserDetails"))
    Token = JSON.parse(localStorage.getItem("UserDetails")).Token;

  useEffect(() => {
    const restChainId = localStorage.getItem("RestChainId")
    if (restChainId == 33566 || restChainId == 33590) {
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
    }
    if (
      localStorage.getItem("RestLocationId") == -1 ||
      localStorage.getItem("RestLocationId") == null
    ) {
      messageApi.open({
        type: 'warning',
        content: 'Please select restaurant first & then you can edit'
      })
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
      // return
    }
    // setErrorMsg("Please fill restaurant info first.");
  }, []);

  const navigate = useNavigate();

  const [inputs, setInputs] = useState({});
  const [optionList, setOptionList] = useState([]);
  const [errormsg, setErrorMsg] = useState("");
  const [showtaxvalid, setShowTaxValid] = useState("");

  const newdata = [];
  newdata.currency = inputs.currency;

  const onChange = (e) => {
    if (e.target.name == "tax") {
      //const result = e.target.value.replace(/\D/g, "");
      const result = e.target.value.replace(/[^0-9.]+/g, "");
      setShowTaxValid(result);
    }
    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));
  };

  const getCurrency = () => {
    Axios.post(APIUrl + "getCurrency", { data: { token: Token } })
      .then((response) => {
        setOptionList(response.data.data.listCurrency);
        if (localStorage.getItem("RestaurantEditDetails")) {
          if (localStorage.getItem("RestaurantEditDetails") != -1)
            handleUpdate();
        }
      })
      .catch((e) => {
        if (e.response.data == "Unauthorized") {
          setUnauthorized(true)
        }
        setOptionList([]);
      });
  };

  React.useEffect(() => getCurrency(), []);

  function SaveTaxation() {
    // e.preventDefault();
    setLoading(true)
    if (
      localStorage.getItem("RestLocationId") != -1 &&
      localStorage.getItem("RestLocationId") != null
    ) {
      Axios.post(APIUrl + "SetRestTax", {
        data: {
          token: Token,
          RestLocationId: localStorage.getItem("RestLocationId"),
          tax: {
            TaxPercent: showtaxvalid,
            LookUpCurrencyId: newdata.currency,
          },
        },
      })
        .then((response) => {
          if (response.data.status == "s") {
            setLoading(false)
            messageApi.open({
              type: 'success',
              content: 'Taxation & Currency Service Updated !'
            })
            setTimeout(() => {
              navigate("/reseller/setup/service_charges");
            }, 500);
          }
          else if (response.data.status == "f") {
            setLoading(false)
            // setErrorMsg(response.data.message);
            messageApi.open({
              type: 'error',
              content: 'Something went wrong, try again'
            })
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false)
          // alert(error.response.data);
          if (error.response.data == "Unauthorized") {
            setUnauthorized(false)
          }
        });
      // navigate("/reseller/setup/service_charges");
    }
    else {
      // setErrorMsg("Please fill restaurant info first.");
      setLoading(false)
      messageApi.open({
        type: 'warning',
        content: 'Please select restaurant first & then you can edit'
      })
    }
    setLoading(false)
  }

  function handleUpdate() {
    var RestLocationId = localStorage.getItem("RestLocationId");
    Axios.post(APIUrl + "GetTaxAndCurrency", {
      data: {
        token: Token,
        RestLocationId: RestLocationId,
      },
    })
      .then((response) => {
        if (response.data.status == "s") {
          setInputs((prevState) => ({
            ...prevState,
            currency: response.data.data.LookupCurrencyId,
          }));
          setShowTaxValid(response.data.data.Tax);
        }
      })
      .catch((error) => {
        // alert(error.response.data.message);
        if (error.response.data == "Unauthorized") {
          setUnauthorized(true)
        }
      });
  }

  return (
    <div className="smlwindowHeight">
      <div className="contentForm">
        <div className="formHeading">
          <Button
            onClick={() => {
              if (localStorage.getItem("RestLocationId")) {
                navigate("/reseller/setup/opening_hours");
              } else {
                navigate("/reseller/restaurant/list");
              }
            }}
          >
            Back
          </Button>
          <h2>Taxation and Currency</h2>
        </div>
        <Form onSubmit={SaveTaxation}>
          {contextHolder}

          <div className="Form_root_Inner" style={style}>
            {/* <div className="InputSelect">
            <label>Sales Tax:</label>
            <select>
              <option value="1">Menu prices already include taxes</option>
              <option value="2">Apply tax on top of my menu prices</option>
            </select>
          </div> */}

            <div className="formRow">
              <Input
                label="Tax (%) *"
                name="tax"
                onChange={onChange}
                value={showtaxvalid}
                placeholder="Enter Tax..."
                required="true"
              />
            </div>

            <div className="InputSelect">
              <label>Currency *</label>
              <select required="true" name="currency" onChange={onChange} value={inputs.currency}>
                <option value="">Choose Currency</option>
                {optionList.map((item) => (
                  <option key={item.CurrencyId} value={item.CurrencyId}>
                    {item.CurrencyCode} - {item.CurrencyName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          { /* <div className="error-msg">{errormsg}</div> */}
          <div className="formButtonMain" style={style}>
            {/* <Button type="submit">Next</Button> */}
            <LoadingButton
              // onClick={() => SaveTaxation()}
              type="submit"
              loading={loading}
              loadingPosition="end"
              endIcon={loading ? <CheckBoxOutlineBlankIcon color={"#2C394B"} style={{ color: "#2C394B" }} /> : ''}
            >
              <span>Save</span>
            </LoadingButton>
          </div>
        </Form>
      </div>
      <SessionEnd openOrNot={unauthorized} />
    </div>
  );
}
