import React,{ useContext} from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { LevelContext } from '../../App';

function ActivateServiceContent() {
    var isClass = "";
    if (localStorage.getItem("UserDetails")){
    var IsApprovedUser = JSON.parse(
        localStorage.getItem("UserDetails")
      ).IsApprovedUser;
      if(IsApprovedUser===false)
      isClass="form-overlay";
    }

   // const {isapproveduser, setIsApprovedUser} = useContext(LevelContext) 

    const navigate = useNavigate()

    function RedirectToChildLink(link) {
        if (link != undefined)
            navigate(link)
    }

    return (
        <div className='sidebarOuter'>
            <div className='sidebarInner'>
                <div id="submenuHeading">
                    <div class="sidebar-item open">
                        <div id="menuHeading" class="sidebar-title toggle-btn">
                            <span>ACTIVATE MORE SERVICES</span>
                        </div>
                        <div class="sidebar-content nosetup">
                            <a><a onClick={() => RedirectToChildLink("/reseller/activateservice/delivery_integration")} href="#" class="sidebar-item plain">- Delivery Integration</a></a>
                            <a><a onClick={() => RedirectToChildLink("/reseller/activateservice/POS_integration")} href="#" class="sidebar-item plain">- POS Integration</a></a>
                            <a><a onClick={() => RedirectToChildLink("/reseller/activateservice/review")} href="#" class="sidebar-item plain">- 5 Star Reviewsn</a></a>
                            <a><a onClick={() => RedirectToChildLink("/reseller/activateservice/new_websites")} href="#" class="sidebar-item plain">- New Websites</a></a>
                            <a><a onClick={() => RedirectToChildLink("/reseller/activateservice/new_mobile_apps")} href="#" class="sidebar-item plain">- New Mobile Apps</a></a>
                            <a><a onClick={() => RedirectToChildLink("/reseller/activateservice/email_marketing")} href="#" class="sidebar-item plain">- Email Marketing</a></a>
                        </div>
                    </div>
                </div></div>

            <div id="divSub" className='RightMenu main'>
                <div className={isClass}>
                <Outlet></Outlet>
                </div>
            </div>
        </div>
    )
}
export default ActivateServiceContent