import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const APIUrl = 'https://reseller.mycloudpos.com/iMenu360ResellerAPI/api/Reseller/'
// const APIUrl = 'http://staging.m1.imenu360.com/iMenu360ResellerAPI/api/Reseller/'
const StripeUrl = 'https://restadmin.imenu360.com/stripeconnect_imenu/?restid='

export { APIUrl }
export { StripeUrl }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();