import { Button } from "@mui/material";
import { Input } from "@start-base/react-form-elements";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { TbWorld } from "react-icons/tb";
import { LiaTagSolid } from "react-icons/lia";
import { LiaCreditCard } from "react-icons/lia";
import { CiMobile2 } from "react-icons/ci";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { FaTrash } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect } from "react";
import React, { useState } from "react";
import Axios from "axios";
import { APIUrl } from "../..";
import SessionEnd from '../Setup/SessionEnd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function RestaurantReports() {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [showCustom, setShowCustom] = useState(false);

  const [inputs, setInputs] = useState({});
  const [restList, setRestList] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false)
  const [newReportsList, setnewReportsList] = useState([])

  var obj = [];
  obj.restaurant = -1;
  obj.duration = "YTD";

  const onChange = (e) => {
    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));

    if (e.target.name == "restaurant") {
      obj.restaurant = value;
      obj.duration = inputs.duration;
    } else if (e.target.name == "duration") {
      obj.restaurant = inputs.restaurant;
      obj.duration = value;
    }

    getReportList(obj);
  };

  var ResellerId = -1,
    Token = "";
  if (localStorage.getItem("UserDetails")) {
    ResellerId = JSON.parse(localStorage.getItem("UserDetails")).ResellerId;
    Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
  }

  const getReportList = (obj) => {
    var restaurant = -1,
      duration = "YTD",
      FromDate = "",
      ToDate = "";

    if (obj) {
      restaurant = obj.restaurant ? obj.restaurant : -1;
      duration = obj.duration ? obj.duration : "YTD";
    }

    if (duration == "custom") {
      setShowCustom(true);

      FromDate =
        fromDate.getFullYear() +
        "-" +
        Number(fromDate.getMonth() + 1) +
        "-" +
        fromDate.getDate();
      ToDate =
        toDate.getFullYear() +
        "-" +
        Number(toDate.getMonth() + 1) +
        "-" +
        toDate.getDate();
    } else {
      setShowCustom(false);

      let newDate = new Date();

      ToDate =
        newDate.getFullYear() +
        "-" +
        (newDate.getMonth() + 1) +
        "-" +
        newDate.getDate();
      if (duration == "YTD") FromDate = newDate.getFullYear() + "-01-01";
      else {
        if (duration == "7") newDate.setDate(new Date().getDate() - 7);
        else if (duration == "30") newDate.setDate(new Date().getDate() - 30);
        else if (duration == "90") newDate.setDate(new Date().getDate() - 90);

        FromDate =
          newDate.getFullYear() +
          "-" +
          (newDate.getMonth() + 1) +
          "-" +
          newDate.getDate();
      }
    }

    SetGrid(duration, restaurant, FromDate, ToDate);
  };

  const [width, setWidth] = useState()
  React.useEffect(() => {
    getReportList(obj);
    window.onload = function () {
      adjustSecondDivWidth();
      window.addEventListener('resize', adjustSecondDivWidth);
    };
  }, [width]);

  function adjustSecondDivWidth() {
    if (window.innerWidth < 600) {
      setWidth(200)
      console.log(width);
    }
  }

  const [rowData, setRowData] = useState([]);

  const [columnDefs, setColumnDefs] = useState([
    { headerName: "Restaurant Name", field: "LocationName", width: width < 600 ? width : 450 },
    { headerName: "Accepted Orders", field: "AcceptedOrders", width: 350 },
    { headerName: "Total Revenue", field: "TotalRevenue", width: 350 },
  ]);

  function SetGrid(duration, restaurant, FromDate, ToDate) {
    Axios.post(APIUrl + "RevenueReport", {
      data: {
        token: Token,
        RestLocationId: restaurant, //-1 when not required
        ResellerId: ResellerId, //compulsary if superAdmin then return data of all resellers
        FromDate: FromDate, //compulsary
        ToDate: ToDate, //compulsary
      },
    })
      .then((response) => {
        setnewReportsList(response.data.data.revenueReport)
        setRowData(response.data.data.revenueReport);
      })
      .catch((e) => {
        if (e.response.data == "Unauthorized") {
          setUnauthorized(true)
        }
        setRowData([]);
      });
  }

  const getR = () => {
    Axios.post(APIUrl + "GetLocationReseller", {
      data: {
        token: Token,
        ResellerId: ResellerId,
        RestLocationName: "",
        RestChainId: -1,
      },
    })
      .then((response) => {
        const restaurants = response.data.data.list_locations;

        // const filtered = restaurants.filter((restaurants) => {
        //   return restaurants.IsActive === true;
        // });
        setRestList(restaurants);
      })
      .catch((e) => {
        if (e.response.data == "Unauthorized") {
          setUnauthorized(true)
        }
        setRestList([]);
      });
  };
  React.useEffect(() => getR(), []);

  function RedirectURL(RestChainId) {
    Axios.post(APIUrl + "GetRestAdminToken", {
      data: {
        token: Token,
        RestChainId: RestChainId,
      },
    })
      .then((response) => {
        if (response.data.status == "s") {
          window.open(
            "https://restadmin.imenu360.com/index.html?tid=" +
            response.data.data.Token,
            "_blank"
          );
        }
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  }

  return (
    <div className="contentForm" style={{ width: "100%" }}>
      <div className="rListTable">
        <div>
          <h2 className="rListTitle2">Restaurant Reports</h2>
        </div>
        <div className="rListHeader">
          <div className="rListSearch">
            <select
              className="rListSearch1"
              name="restaurant"
              onChange={onChange}
              value={inputs.restaurant}
            >
              <option value="">Choose Restaurant</option>
              {restList.map((item) => (
                <option key={item.RestLocationId} value={item.RestLocationId}>
                  {item.Name}
                </option>
              ))}
            </select>
          </div>
          <div className="rListSearch">
            <select
              className="rListSearch2"
              name="duration"
              onChange={onChange}
              value={inputs.duration}
            >
              <option value="YTD">Year To Date</option>
              <option value="7">Last 7 Days</option>
              <option value="30">Last 30 Days</option>
              <option value="90">Last 90 Days</option>
              <option value="custom">Custom</option>
            </select>
          </div>
        </div>
        <div className="customeSearch">
          {showCustom && (
            <div className="rListSearch search-block-top">
              <label>From Date :</label>
              <DatePicker
                name="dateFrom"
                selected={fromDate}
                onChange={(date) => {
                  setFromDate(date);
                  var FromDate =
                    date.getFullYear() +
                    "-" +
                    Number(date.getMonth() + 1) +
                    "-" +
                    date.getDate();
                  var ToDate =
                    toDate.getFullYear() +
                    "-" +
                    Number(toDate.getMonth() + 1) +
                    "-" +
                    toDate.getDate();
                  SetGrid("custom", inputs.restaurant, FromDate, ToDate);
                }}
                value={inputs.dateFrom}
              />
              <label>To Date :</label>
              <DatePicker
                name="dateTo"
                selected={toDate}
                onChange={(date) => {
                  {
                    setToDate(date);
                    var FromDate =
                      fromDate.getFullYear() +
                      "-" +
                      Number(fromDate.getMonth() + 1) +
                      "-" +
                      fromDate.getDate();
                    var ToDate =
                      date.getFullYear() +
                      "-" +
                      Number(date.getMonth() + 1) +
                      "-" +
                      date.getDate();
                    SetGrid("custom", inputs.restaurant, FromDate, ToDate);
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      {/* <div className="gridPanel">
        <div className="ag-theme-alpine" style={{ height: 550 }}>
          <AgGridReact rowData={rowData} columnDefs={columnDefs} />
        </div>
      </div> */}
      <div className="resListTableOuter">
        <div className="resListTable">
          <table>
            <tr>
              <th>Restaurant Name</th>
              <th>Accepted Orders</th>
              <th>Total Revenue</th>
            </tr>
            {newReportsList.map((list) => (
              <tr>
                <td>{list.LocationName}</td>
                <td>{list.AcceptedOrders}</td>
                <td style={{ display: "flex", justifyContent: 'start', alignItems:'center', gap: 14 }}><p>$</p> <p>{list.TotalRevenue}</p></td>
              </tr>
            ))
            }
          </table>
        </div>
      </div>
      <SessionEnd openOrNot={unauthorized} />
    </div>
  );
}
