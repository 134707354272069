import React, { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { LevelContext } from "../../App";

function RestaurantContent() {
  var isClass = "";
  if (localStorage.getItem("UserDetails")) {
    var IsApprovedUser = JSON.parse(
      localStorage.getItem("UserDetails")
    ).IsApprovedUser;
    if (IsApprovedUser === false) isClass = "form-overlay";
  }

  const navigate = useNavigate();
  function RedirectToChildLink(link) {
    if (link != undefined) navigate(link);
  }

  const [open, setOpen] = useState(true);

  return (
    // <div className="sidebarOuter">
    //   <div className="sidebarInner">
    //     <div id="submenuHeading">
    //       <div class="sidebar-item open">
    //         <div id="menuHeading" class="sidebar-title toggle-btn">
    //           <span>RESTAURANT</span>
    //         </div>
    //         <div class="sidebar-content nosetup">
    //           <a>
    //             <a
    //               onClick={() =>
    //                 RedirectToChildLink("/reseller/restaurant/list")
    //               }
    //               href="#"
    //               class="sidebar-item plain"
    //             >
    //               - Restaurant List
    //             </a>
    //           </a>
    //           <a>
    //             <a
    //               onClick={() =>
    //                 RedirectToChildLink("/reseller/restaurant/reports")
    //               }
    //               href="#"
    //               class="sidebar-item plain"
    //             >
    //               - Restaurant Reports
    //             </a>
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div id="divSub" className="RightMenu main">
    //     <div className={isClass}>
    //       <Outlet></Outlet>
    //     </div>
    //   </div>
    // </div>
    <div className="sidebarOuter">
      <div className="sidebarInner">
        <div id="submenuHeading">
          <div class="sidebar-item open">
            <div id="menuHeading" class="sidebar-title toggle-btn">
              <span>RESTAURANT</span>
            </div>
            <div>
              <a>
                <div className={open ? "sidebar-item open" : "sidebar-item"}>
                  <div
                    class="sidebar-title toggle-btn"
                    onClick={() => setOpen(!open)}
                  >
                    <span>Restaurant</span>
                  </div>
                  <div class="sidebar-content">
                    <a>
                      <a
                        href="#"
                        onClick={() =>
                          RedirectToChildLink("/reseller/restaurant/list")
                        }
                        class="sidebar-item plain"
                      >
                        Restaurant List
                      </a>
                    </a>
                    <a>
                      <a
                        href="#"
                        onClick={() =>
                          RedirectToChildLink("/reseller/restaurant/reports")
                        }
                        class="sidebar-item plain"
                      >
                        Restaurant Reports
                      </a>
                    </a>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="divSub" className="RightMenu main">
        <div className={isClass}>
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
}
export default RestaurantContent;
