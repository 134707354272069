import { Dialog } from "@mui/material";
import { Modal } from "antd";
import { useState } from "react";
import { PiWarningCircle } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';

export default function SessionEnd(props) {
    const navigate = useNavigate()
    return (
        <>
            {/* <Modal
                style={{ maxHeight: 500 }}
                title=""
                open={props.openOrNot ? props.openOrNot : false}
                footer={false}
                centered
                onCancel={() => { navigate("/") }}
            >
                <div className="sessionEnd1">
                    <PiWarningCircle color="#FFC300" size={25} />&nbsp;&nbsp;<h2>Session Expired !</h2>
                </div>
                <div className="sessionEnd2">
                    <h4>Please log in again to continue accessing our services</h4>
                </div>
                <div className="sessionEnd3">
                    <button className="savebtn1" onClick={() => { navigate("/"); localStorage.clear(); }}>OK</button>
                </div>
            </Modal> */}
            <Dialog
                PaperProps={{ sx: { width: 400 } }}
                open={props.openOrNot ? props.openOrNot : false}
            >
                <div className="sessionEnd1">
                    <PiWarningCircle color="#FFC300" size={25} />&nbsp;&nbsp;<h2>Session Expired !</h2>
                </div>
                <div className="sessionEnd2">
                    <h4>Please log in again to continue accessing our services</h4>
                </div>
                <div className="sessionEnd3">
                    <button className="savebtn1" onClick={() => { navigate("/"); localStorage.clear(); }}>OK</button>
                </div>
            </Dialog>
        </>
    )
}