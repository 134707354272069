import React, { useState, useEffect } from "react";
import { Input, Form } from "@start-base/react-form-elements";
import { Button, Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { APIUrl } from "../..";
import Axios from "axios";
import SessionEnd from "./SessionEnd";
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import PhoneInput from "react-phone-input-2";

export default function ReceiveOrder() {
  const navigate = useNavigate();

  const [receiveOrderOption, setOrderOption] = useState('POS Setup')
  const [showphonevalid, setShowPhoneValid] = useState("");
  const [showfaxvalid, setShowFaxValid] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [style, setStyle] = useState(null);
  const [inputs, setInputs] = useState({});
  const [posnameerr, setposnameerr] = useState('')
  const [unauthorized, setUnauthorized] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState()
  const [loading, setLoading] = useState(false);
  const [newNO, setnewNo] = useState('')

  const onChange = (e) => {
    setposnameerr('')
    if (e.target.name == "telephone") {
      const result = e.target.value.replace(/\D/g, "");
      setShowPhoneValid(result);
    } else if (e.target.name == "faxId") {
      const result = e.target.value.replace(/\D/g, "");
      setShowFaxValid(result);
    }

    const { name, value, type } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));

    var b = 0;

    if (
      e.target.type == "select-one" &&
      (e.target.name == "printerAppPriority" ||
        e.target.name == "emailPriority" ||
        e.target.name == "tapPriority")
    ) {
      console.log(e.target.name);
      if (e.target.value != "0") {
        if (e.target.name != "printerAppPriority") {
          if (e.target.value == inputs.printerAppPriority) b = 1;
        }
        if (e.target.name != "faxPriority") {
          if (e.target.value == inputs.faxPriority) b = 1;
        }
        if (e.target.name != "emailPriority") {
          if (e.target.value == inputs.emailPriority) b = 1;
        }
        if (e.target.name != "tapPriority") {
          if (e.target.value == inputs.tapPriority) b = 1;
        }

        if (b == 1) {
          //alert("Priority is already selected. Select any other priority.");
          messageApi.open({
            type: "warning",
            content: "Priority is already selected. Select any other priority.",
          });

          setInputs((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? !prevState[name] : "0",
          }));
        }
      }
    }
    console.log(inputs.possetupname);
  };

  const newdata = [];
  newdata.printerAppPriority = inputs.printerAppPriority;
  newdata.printerAppTimeout = inputs.printerAppTimeout;
  newdata.faxPriority = inputs.faxPriority;
  newdata.faxTimeout = inputs.faxTimeout;
  newdata.emailId = inputs.emailId;
  newdata.emailPriority = inputs.emailPriority;
  newdata.emailTimeout = inputs.emailTimeout;
  newdata.tapPriority = inputs.tapPriority;
  newdata.tapTimeout = inputs.tapTimeout;
  newdata.autoConfimrationTimeout = inputs.autoConfimrationTimeout;
  newdata.manualEmail = inputs.manualEmail;
  newdata.emailCopy = inputs.emailCopy;
  newdata.feedbackEmail = inputs.feedbackEmail;
  newdata.reminder = inputs.reminder;
  newdata.possetupname = inputs.possetupname

  {
    /* confirmation start */
  }
  newdata.printerAppConfirmMethod = inputs.printerAppConfirmMethod;
  newdata.printerAppConfirmTimeout = inputs.printerAppConfirmTimeout;
  newdata.printerAppRepeat = inputs.printerAppRepeat;

  newdata.emailConfirmMethod = inputs.emailConfirmMethod;
  newdata.emailConfirmTimeout = inputs.emailConfirmTimeout;
  newdata.emailRepeat = inputs.emailRepeat;

  newdata.tapConfirmMethod = inputs.tapConfirmMethod;
  newdata.tapConfirmTimeout = inputs.tapConfirmTimeout;
  newdata.tapRepeat = inputs.tapRepeat;
  {
    /* confirmation end */
  }

  var Token = "";
  if (localStorage.getItem("UserDetails"))
    Token = JSON.parse(localStorage.getItem("UserDetails")).Token;

  function SaveOrder(e) {

    e.preventDefault();
    setLoading(true)
    if (newNO?.split('+')[1]?.split(' ')[1].length > 10) {
      messageApi.open({
        type: "error",
        content: "Enter a valid phone number",
      });
      setLoading(false)
      return
    }
    if (receiveOrderOption == 'POS Setup') {
      if (!inputs.possetupname) {
        setposnameerr('Please enter POS name')
        return
      }
    }
    if (
      localStorage.getItem("RestLocationId") != -1 &&
      localStorage.getItem("RestLocationId") != null
    ) {
      Axios.post(APIUrl + "ReceiveOrders", {
        data: {
          token: Token,
          RestLocationId: localStorage.getItem("RestLocationId"),
          ReceiveOrdersThrough: receiveOrderOption,
          POSSetupName: newdata.possetupname ? newdata.possetupname : '',
          OrdNotifications: {
            // isPrinterApp: false,
            // printerPriority: newdata.printerAppPriority,
            // printerTimeOut: newdata.printerAppTimeout,
            // isFax: true,
            // faxNotificationId: showfaxvalid,
            // faxPriority: newdata.faxPriority,
            // faxTimeOut: newdata.faxTimeout,
            // isEmail: false,
            // EmailNotificationId: newdata.emailId,
            // emailPriority: newdata.emailPriority,
            // emailTimeOut: newdata.emailTimeout,
            // isTap: true,
            // tapPriority: newdata.tapPriority,
            // tapTimeOut: newdata.tapTimeout,
            // AutoConfirmTimeOut: newdata.autoConfimrationTimeout,
            // Telephone: "+" + countryCode?.dialCode + "-" + phoneNumber + "," + countryCode?.iso2,
            Telephone: newNO,
            ManualRMCEmail: newdata.manualEmail,
            EmailCopy: newdata.emailCopy,
            FeedbackEmail: newdata.feedbackEmail,
            SetReminder: document.getElementById("sendReminder").checked,

            // confirmation start
            // printerConfirmationMethod: newdata.printerAppConfirmMethod,
            // printerConfirmationtimeOut: newdata.printerAppConfirmTimeout,
            // printerRepeat: newdata.printerAppRepeat,
            // emailConfirmationMethod: newdata.emailConfirmMethod,
            // emailConfirmationtimeOut: newdata.emailConfirmTimeout,
            // emailRepeat: newdata.emailRepeat,
            // tapConfirmationMethod: newdata.tapConfirmMethod,
            // tapConfirmationtimeOut: newdata.tapConfirmTimeout,
            // tapRepeat: newdata.tapRepeat
            // confirmation end
          },
        },
      })
        .then((response) => {
          if (response.data.status == "s") {
            setLoading(false)
            messageApi.open({
              type: "success",
              content: "Successfully saved",
            });
            setTimeout(() => {
              navigate("/reseller/setup/confirmation");
            }, 500);
          } else if (response.data.status == "f") {
            setLoading(false)
            messageApi.open({
              type: "warning",
              content: response.data.message,
            });
          }
        })
        .catch((error) => {
          setLoading(false)
          if (error.response.data == "Unauthorized") {
            setUnauthorized(true)
          } else {
            messageApi.open({
              type: "warning",
              content: error.response.data,
            });
          }
        });
    } else {
      setLoading(false)
      messageApi.open({
        type: "warning",
        content: "Please select restaurant first & then you can edit.",
      });
      setStyle({
        pointerEvents: "none",
        opacity: 0.5,
      });
    }
    setLoading(false)
  }

  useEffect(() => {
    const restChainId = localStorage.getItem("RestChainId")
    if (restChainId == 33566 || restChainId == 33590) {
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
    }
    if (
      localStorage.getItem("RestLocationId") == -1 ||
      localStorage.getItem("RestLocationId") == null
    ) {
      messageApi.open({
        type: "warning",
        content: "Please fill restaurant info first.",
      });
      setStyle({
        pointerEvents: "none",
        opacity: 0.5,
      });
    } else handleUpdate();
  }, []);

  function handleUpdate() {
    var RestLocationId = localStorage.getItem("RestLocationId");

    Axios.post(APIUrl + "GetReceiveOrders", {
      data: {
        token: Token,
        RestLocationId: RestLocationId,
      },
    })
      .then((response) => {
        if (response.data.status == "s") {
          var Details = response.data.data;
          setOrderOption(Details.ReceiveOrder ? Details.ReceiveOrder : 'POS Setup')
          setShowPhoneValid(Details.Telephone)
          setInputs((prevState) => ({
            ...prevState,
            // printerAppPriority: Details.printerPriority,
            // printerAppTimeout: Details.printerTimeOut,
            // faxPriority: Details.faxPriority,
            // faxTimeout: Details.faxTimeOut,
            // emailId: Details.EmailNotificationId,
            // emailPriority: Details.emailPriority,
            // emailTimeout: Details.emailTimeOut,
            // tapPriority: Details.tapPriority,
            // tapTimeout: Details.tapTimeOut,
            // autoConfimrationTimeout: Details.AutoConfirmTimeOut,
            manualEmail: Details.ManualRMCEmail,
            emailCopy: Details.EmailCopy,
            feedbackEmail: Details.FeedbackEmail,
            reminder: Details.SetReminder,

            // confirmation start
            // printerAppConfirmMethod: Details.printerConfirmationMethod,
            // printerAppConfirmTimeout: Details.printerConfirmationtimeOut,
            // printerAppRepeat: Details.printerRepeat,

            // emailConfirmMethod: Details.emailConfirmationMethod,
            // emailConfirmTimeout: Details.emailConfirmationtimeOut,
            // emailRepeat: Details.emailRepeat,

            // tapConfirmMethod: Details.tapConfirmationMethod,
            // tapConfirmTimeout: Details.tapConfirmationtimeOut,
            // tapRepeat: Details.tapRepeat,
            // confirmation end

            possetupname: Details.POSSetupName
          }));
          setShowPhoneValid(Details.Telephone);
          setShowFaxValid(Details.faxNotificationId);
          // setPhoneNumber(Details.Telephone.split('-')[1].split(',')[0])
          // var d = {
          //   "iso2": Details.Telephone.split(',')[1],
          //   "dialCode": Details.Telephone.split('-')[0].split('+')[1],
          //   "phone": ""
          // }
          // setCountryCode(d)
          setnewNo(Details.Telephone)

          document.getElementById("sendReminder").checked = Details.SetReminder;
        }
      })
      .catch((error) => {
        // alert(error);
        if (error?.response?.data == "Unauthorized") {
          setUnauthorized(true)
        }
      });
  }

  return (
    <div className="smlwindowHeight">
      <div className="contentForm smallPageWindow receive-ordersForm">
        <div className="formHeading">
          <div className="btnwithText">
            <Button
              onClick={() => {
                if (localStorage.getItem("RestLocationId")) {
                  navigate("/reseller/setup/menu");
                } else {
                  navigate("/reseller/restaurant/list");
                }
              }}
            >
              Back
            </Button>
            <h2>Receive Orders</h2>
          </div>
        </div>
        {contextHolder}
        <Form onSubmit={SaveOrder} style={style}>
          <div className="pageBtmScroll">
            <div className="Form_root_Inner">
              {/* <div className="formRow fourCol">
              <div className="formCol">
                <h3>Notification Method</h3>
              </div>
              <div className="formCol">
                <h3>Notification Id</h3>
              </div>
              <div className="formCol">
                <h3>Priority</h3>
              </div>
              <div className="formCol">
                <h3>Notification Timeout</h3>
              </div> */}
              {/* confirmation start */}
              {/* <div className="formCol">
                <h3>Confirmation Method</h3>
              </div>
              <div className="formCol">
                <h3>Confirmation Timeout</h3>
              </div>
              <div className="formCol">
                <h3>Repeat</h3>
              </div> */}
              {/* confirmation end */}
              {/* </div> */}
              {/* <hr></hr> */}
              {/* <div className="formRow fourCol orderCols">
              <div className="formCol">
                <label>Printer App</label>
              </div>
              <div className="formCol">
                <label>-</label>
              </div>
              <div className="formCol">
                <div className="InputSelect dropdownField">
                  <select
                    name="printerAppPriority"
                    onChange={onChange}
                    value={inputs.printerAppPriority}
                  >
                    <option selected="true" value="0">
                      N/A
                    </option>
                    <option value="1">First</option>
                    <option value="2">Second</option>
                    <option value="3">Third</option>
                    <option value="4">Fourth</option>
                  </select>
                </div>
              </div>
              <div className="formCol">
                <Input
                  onChange={onChange}
                  value={inputs.printerAppTimeout}
                  name="printerAppTimeout"
                />
              </div> */}

              {/* confirmation start */}
              {/* <div className="formCol">
                <div className="InputSelect dropdownField">
                  <select
                    name="printerAppConfirmMethod"
                    onChange={onChange}
                    value={inputs.printerAppConfirmMethod}
                  >
                    <option value="0" selected="true">Select</option>
                    <option value="1">Physical</option>
                    <option value="2">Auto</option>
                    <option value="3">ManualCSR</option>
                    <option value="4">ManualRMC</option>
                    <option value="5">eMailLink</option>
                    <option value="6">NoConfirmation</option>
                  </select>
                </div>
              </div>
              <div className="formCol">
                <Input
                  onChange={onChange}
                  value={inputs.printerAppConfirmTimeout}
                  name="printerAppConfirmTimeout"
                />
              </div>
              <div className="formCol">
                <div className="InputSelect dropdownField">
                  <select
                    name="printerAppRepeat"
                    onChange={onChange}
                    value={inputs.printerAppRepeat}
                  >
                    <option value="0" selected="true">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div> */}
              {/* confirmation end   */}
              {/* </div> */}
              {/* <div className="formRow fourCol" style={{ display: "none" }}>
              <div className="formCol">
                <label>Fax</label>
              </div>
              <div className="formCol">
                <Input name="faxId" onChange={onChange} value={showfaxvalid} />
              </div>
              <div className="formCol">
                <div className="InputSelect dropdownField">
                  <select
                    name="faxPriority"
                    onChange={onChange}
                    value={inputs.faxPriority}
                  >
                    <option value="0">N/A</option>
                    <option value="1">First</option>
                    <option value="2">Second</option>
                    <option value="3">Third</option>
                    <option value="4">Fourth</option>
                  </select>
                </div>
              </div>
              <div className="formCol">
                <Input
                  name="faxTimeout"
                  onChange={onChange}
                  value={inputs.faxTimeout}
                />
              </div>
            </div> */}
              {/* <div className="formRow fourCol">
              <div className="formCol">
                <label>eMail</label>
              </div>
              <div className="formCol">
                <Input
                  name="emailId"
                  onChange={onChange}
                  value={inputs.emailId}
                  type="email"
                />
              </div>
              <div className="formCol">
                <div className="InputSelect dropdownField">
                  <select
                    name="emailPriority"
                    onChange={onChange}
                    value={inputs.emailPriority}
                  >
                    <option value="0">N/A</option>
                    <option value="1">First</option>
                    <option value="2">Second</option>
                    <option value="3">Third</option>
                    <option value="4">Fourth</option>
                  </select>
                </div>
              </div>
              <div className="formCol">
                <Input
                  name="emailTimeout"
                  onChange={onChange}
                  value={inputs.emailTimeout}
                />
              </div> */}

              {/* confirmation start */}
              {/* <div className="formCol">
                <div className="InputSelect dropdownField">
                  <select
                    name="emailConfirmMethod"
                    onChange={onChange}
                    value={inputs.emailConfirmMethod}
                  >
                     <option value="0" selected="true">Select</option>
                    <option value="1">Physical</option>
                    <option value="2">Auto</option>
                    <option value="3">ManualCSR</option>
                    <option value="4">ManualRMC</option>
                    <option value="5">eMailLink</option>
                    <option value="6">NoConfirmation</option>
                  </select>
                </div>
              </div>
              <div className="formCol">
                <Input
                  onChange={onChange}
                  value={inputs.emailConfirmTimeout}
                  name="emailConfirmTimeout"
                />
              </div>
              <div className="formCol">
                <div className="InputSelect dropdownField">
                  <select
                    name="emailRepeat"
                    onChange={onChange}
                    value={inputs.emailRepeat}
                  >
                    <option value="0" selected="true">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div> */}
              {/* confirmation end   */}
              {/* </div> */}
              {/* <div className="formRow fourCol">
              <div className="formCol">
                <label>Tap</label>
              </div>
              <div className="formCol">
                <label>-</label>
              </div>
              <div className="formCol">
                <div className="InputSelect dropdownField">
                  <select
                    name="tapPriority"
                    onChange={onChange}
                    value={inputs.tapPriority}
                  >
                    <option value="0">N/A</option>
                    <option value="1">First</option>
                    <option value="2">Second</option>
                    <option value="3">Third</option>
                    <option value="4">Fourth</option>
                  </select>
                </div>
              </div>
              <div className="formCol">
                <Input
                  name="tapTimeout"
                  onChange={onChange}
                  value={inputs.tapTimeout}
                />
              </div> */}

              {/* confirmation start */}
              {/* <div className="formCol">
                <div className="InputSelect dropdownField">
                  <select
                    name="tapConfirmMethod"
                    onChange={onChange}
                    value={inputs.tapConfirmMethod}
                  >
                    <option value="0" selected="true">Select</option>
                    <option value="1">Physical</option>
                    <option value="2">Auto</option>
                    <option value="3">ManualCSR</option>
                    <option value="4">ManualRMC</option>
                    <option value="5">eMailLink</option>
                    <option value="6">NoConfirmation</option>
                  </select>
                </div>
              </div>
              <div className="formCol">
                <Input
                  onChange={onChange}
                  value={inputs.tapConfirmTimeout}
                  name="tapConfirmTimeout"
                />
              </div>
              <div className="formCol">
                <div className="InputSelect dropdownField">
                  <select
                    name="tapRepeat"
                    onChange={onChange}
                    value={inputs.tapRepeat}
                  >
                    <option value="0" selected="true">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </div>
              </div> */}
              {/* confirmation end   */}
              {/* </div> */}

              <Form>
                <div className='Form_root_Inner1' style={style}>
                  <div className='InputSelect'>
                    <label>Receive Order Through *</label>
                    <select value={receiveOrderOption} onChange={(e) => { setOrderOption(e.target.value) }}>
                      <option value="POS Setup">POS Setup</option>
                      <option value="Tablet">Tablet & Printer</option>
                      <option value="Email">Email</option>
                    </select>
                  </div>
                  {receiveOrderOption === 'POS Setup' &&
                    <div>
                      <Input
                        label="POS setup name *"
                        name="possetupname"
                        required={true}
                        onChange={onChange}
                        value={inputs.possetupname}
                      />
                      <div className="posnameErrDiv"><span className="posnameErr">{posnameerr}</span></div>
                    </div>
                  }
                </div>
              </Form>

              <hr></hr>
              <div className="formRow threeCol contectDtls">
                {/* <Input
                  label="AutoConfirmaion Timeout"
                  name="autoConfimrationTimeout"
                  onChange={onChange}
                  value={inputs.autoConfimrationTimeout}
                /> */}
                {/* <Input
                  label="Telephone"
                  name="telephone"
                  onChange={onChange}
                  value={showphonevalid}
                /> */}
                {/* <div className="phonenocode">
                  <label>Telephone</label>
                  <ReactIntlTelInput
                    intlTelOpts={{ preferredCountries: ['us'] }}
                    value={countryCode}
                    name="phone"
                    onChange={async (phone) => { setCountryCode(phone); console.log(phone); }}
                  />
                  <span className="countryCode" >+{countryCode?.dialCode}</span>
                  <input
                    type="tel"
                    name="phoneNumber"
                    className="form-control"
                    required
                    value={phoneNumber}
                    onChange={async (e) => { setPhoneNumber(e.target.value) }}
                    onFocus={() => { }}
                  />
                </div> */}
                <div className="ph-num-field">
                  <label>
                    Phone number</label>
                  <PhoneInput
                    country={'us'}
                    // value={PhoneNumber}
                    // value={showphonevalid}
                    // onChange={onChange}
                    value={newNO?.split('+')[1]?.split(' ')[0] + "" + newNO?.split('+')[1]?.split(' ')[1]}
                    onChange={(value, country, event, formattedValue) => { setnewNo("+" + country.dialCode + " " + value.slice(country.dialCode.length)); }}
                    inputProps={{ required: true }}
                  ></PhoneInput>
                </div>
                <Input
                  label="Manual RMC Email"
                  name="manualEmail"
                  onChange={onChange}
                  value={inputs.manualEmail}
                  type="email"
                />
                <Input
                  label="Email Copy"
                  name="emailCopy"
                  onChange={onChange}
                  value={inputs.emailCopy}
                  type="email"
                />
              </div>
              <div className="formRow threeCol contectDtls">
                <Input
                  label="Feedback Email"
                  name="feedbackEmail"
                  onChange={onChange}
                  value={inputs.feedbackEmail}
                  type="email"
                />
                <div className="reminder-checkbox">
                  <label>
                    <input
                      name="reminder"
                      type="checkbox"
                      onChange={onChange}
                      id="sendReminder"
                    />
                    Send Reminder
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="bottomDiv">
            {/* <button className="savebtn" type="submit">
              Save
            </button> */}
            <LoadingButton
              className="savebtn"
              // onClick={() => SaveOrder()}
              type="submit"
              loading={loading}
              loadingPosition="end"
              endIcon={loading ? <CheckBoxOutlineBlankIcon color={"#2C394B"} style={{ color: "#2C394B" }} /> : ''}
            >
              <span>Save</span>
            </LoadingButton>
          </div>
        </Form>
      </div>
      <SessionEnd openOrNot={unauthorized} />
    </div>
  );
}
