import { Button, Dialog } from "@mui/material";
import { Input } from "@start-base/react-form-elements";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Axios from "axios";
import { APIUrl } from "../..";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import EditNoteIcon from "@mui/icons-material/EditNote";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { message } from 'antd'
import { PiWarningCircle } from "react-icons/pi";
import SessionEnd from './SessionEnd';

export default function RestaurantChainList() {
  const [messageApi, contextHolder] = message.useMessage();
  const [unauthorized, setUnauthorized] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [chainIdForDeletion, setChainIdForDeletion] = useState()

  var ResellerId = -1;
  if (localStorage.getItem("UserDetails"))
    ResellerId = JSON.parse(localStorage.getItem("UserDetails")).ResellerId;

  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [newChainList, setNewChainList] = useState([])

  function AddChain() {
    localStorage.setItem("RestChainId", -1)
    navigate("/reseller/setup/chaindts", { state: { RestChainDts: -1 } });
  }

  const deleteChain = async () => {
    let Token = ""
    let ResellerId = ""
    if (localStorage.getItem("UserDetails")) {
      Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
      ResellerId = JSON.parse(localStorage.getItem("UserDetails")).ResellerId;
    }
    try {
      if (Token) {
        const deleteResponse = await axios.post(APIUrl + 'DeleteReserllerChain', { "data": { "token": Token, "ResellerId": ResellerId, "RestChainId": chainIdForDeletion } }, {
          headers: { 'Content-Type': 'application/json' }
        })
        console.log(deleteResponse.data)
        if (deleteResponse.data.serviceStatus == 200) {
          setOpenDeleteDialog(false)
          messageApi.open({
            type: 'success',
            content: 'Demo restaurant deleted !'
          })
        }
        getRC();
      }
    } catch (error) {
      if (error.response.data == "Unauthorized") {
        setUnauthorized(true)
      }
    }
  }

  const [columnDefs, setColumnDefs] = useState([
    { headerName: "Restaurant Chain Name", field: "Name", width: 250 },
    { headerName: "Email", field: "email", width: 250 },
    { headerName: "Telephone", field: "Telephone", width: 150 },
    // { headerName: "Fax", field: "Fax" },
    {
      headerName: "Action",
      width: 400,
      cellRenderer: (params) => (
        <div>
          <Button
            className="grid-action-btn grid-blue-btn"
            onClick={() => {
              navigate("/reseller/restaurant/list", {
                state: { RestChainId: params.data.RestChainId },
              });
            }}
          >
            View Restaurants
          </Button>
          {/* <RestaurantIcon
            className="action-btn"
            onClick={() => {
              navigate("/reseller/restaurant/list", {
                state: { RestChainId: params.data.RestChainId },
              });
            }}
          />
          <EditNoteIcon
            className="action-btn"
            onClick={() => {
              navigate("/reseller/setup/chaindts", {
                state: { RestChainDts: params.data },
              });
            }}
          ></EditNoteIcon>
 */}
          <Button
            className="grid-action-btn grid-dark-btn"
            onClick={() => {
              localStorage.setItem(
                "RestChainId",
                params.data.RestChainId
              );
              navigate("/reseller/setup/chaindts", {
                state: { RestChainDts: params.data },
              });
            }}
          >
            Edit Chain
          </Button>
          {/* </a> */}

          {params.data.RestChainId == 33566 &&
            <Button
              className="grid-action-btn grid-red-btn"
              onClick={() => { deleteChain() }}
            >
              Delete Chain
            </Button>
          }
        </div>
      ),
    },
  ]);

  const getRC = () => {
    var Token = "";
    if (localStorage.getItem("UserDetails"))
      Token = JSON.parse(localStorage.getItem("UserDetails")).Token;

    Axios.post(APIUrl + "GetRestChainByReseller", {
      data: { token: Token, ResellerId: ResellerId },
    })
      .then((response) => {
        setRowData(response.data.data.restChains);
        setNewChainList(response.data.data.restChains);
      })
      .catch((e) => {
        if (e.response.data == "Unauthorized") {
          setUnauthorized(true)
        }
        setRowData([]);
      });
  };
  React.useEffect(() => getRC(), []);
  // React.useEffect(() => {
  //   if (document.getElementById('lasttd')) {
  //     console.log('found');
  //     document.getElementById('lasttd').style.width = '50%'
  //   }
  // }, [])
  const [style, setStyle] = useState({})
  useEffect(() => {
    var isClass = "",
      IsSuperAdmin = false;
    if (localStorage.getItem("UserDetails")) {
      var IsApprovedUser = JSON.parse(
        localStorage.getItem("UserDetails")
      ).IsApprovedUser;

      IsSuperAdmin = JSON.parse(localStorage.getItem("UserDetails")).IsSuperAdmin;
      if (IsApprovedUser === false) setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      });
    }
  }, [])


  return (
    <div className="contentForm chainList-wrapper">
      {contextHolder}
      <div className="gridHeading" style={style}>
        <h2>Restaurant Chain List</h2>
        <Button className="black-btn" onClick={() => AddChain()}>
          Add Restaurant Chain
        </Button>
      </div>
      {/* <div className="gridPanel">
        <div className="ag-theme-alpine" style={{ height: 550 }}>
          <AgGridReact rowData={rowData} columnDefs={columnDefs} />
        </div>
      </div> */}
      <div className="resListTableOuter">
        <div className="resListTable">
          <table>
            <tr>
              <th>Restaurant Chain Name</th>
              <th>Email</th>
              <th>Telephone</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            {newChainList.map((list) => (
              <tr>
                <td>{list.Name}</td>
                <td>{list.email}</td>
                <td>{list.Telephone}</td>
                <td id="lasttd">
                  {/* <div className="gridBtns"> */}
                  <button
                    className="grid-action-btn grid-blue-btn viewRest"
                    onClick={() => {
                      navigate("/reseller/restaurant/list", {
                        state: { RestChainId: list.RestChainId },
                      });
                    }}
                  >
                    View Restaurants
                  </button>
                </td>
                <td>
                  <button
                    className="grid-action-btn grid-dark-btn editChain"
                    onClick={() => {
                      localStorage.setItem(
                        "RestChainId",
                        list.RestChainId
                      );
                      navigate("/reseller/setup/chaindts", {
                        state: { RestChainDts: list },
                      });
                    }}
                  >
                    Edit Chain
                  </button>
                </td>
                <td>
                  {list.RestChainId == 33566 || list.RestChainId == 33590 ? (
                    <button
                      className="grid-action-btn grid-red-btn deleteChain"
                      onClick={() => { setOpenDeleteDialog(true); setChainIdForDeletion(list.RestChainId) }}
                    >
                      Delete Chain
                    </button>) : (<div></div>)
                  }
                </td>
              </tr>
            ))
            }
          </table>
        </div>
      </div>

      <Dialog
        PaperProps={{ sx: { width: 400 } }}
        open={openDeleteDialog}
      >
        <div className="sessionEnd1">
          <PiWarningCircle color="red" size={25} />&nbsp;&nbsp;<h2>Confirm Delete !</h2>
        </div>
        <div className="sessionEnd2">
          <h4>Are you sure you want to delete this restaurant chain? </h4>
        </div>
        <div className="deleteChainPopup">
          <button className="deleteChainBtn" onClick={() => deleteChain()}>Delete</button>
          <button className="cancelDeleteChainBtn" onClick={() => setOpenDeleteDialog(false)}>Cancel</button>
        </div>
      </Dialog>
      <SessionEnd openOrNot={unauthorized} />
    </div>
  );
}
