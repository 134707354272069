import { Button } from "@mui/material";
import { Input } from "@start-base/react-form-elements";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { TbWorld } from "react-icons/tb";
import { LiaTagSolid } from "react-icons/lia";
import { LiaCreditCard } from "react-icons/lia";
import { CiMobile2 } from "react-icons/ci";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { FaTrash } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect, useMemo } from "react";
import React, { useState } from "react";
import Axios from "axios";
import { APIUrl } from "../..";
import { useNavigate, useLocation } from "react-router-dom";
import SessionEnd from "../Setup/SessionEnd";
import { BsLink } from "react-icons/bs";
import { BsLink45Deg } from "react-icons/bs";

export default function Restaurantlist() {
  const [inputs, setInputs] = useState({});
  const [chainList, setChainList] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [newChainList, setNewChainList] = useState([])
  const [style, setStyle] = useState({})

  var obj = [];

  const navigate = useNavigate();

  var RestChainId = -1;
  var StateInfo = useLocation().state;

  if (StateInfo) {
    RestChainId = StateInfo.RestChainId;
    obj.search = "";
    obj.chain = RestChainId;
  }

  useEffect(() => {
    var isClass = "",
      IsSuperAdmin = false;
    if (localStorage.getItem("UserDetails")) {
      var IsApprovedUser = JSON.parse(
        localStorage.getItem("UserDetails")
      ).IsApprovedUser;

      IsSuperAdmin = JSON.parse(localStorage.getItem("UserDetails")).IsSuperAdmin;
      if (IsApprovedUser === false) setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      });
    }
  }, [])

  const SetChain = () => {
    setInputs((prevState) => ({
      ...prevState,
      restaurantChain: RestChainId,
    }));
  };
  React.useEffect(() => {
    SetChain();
    window.onload = function () {
      adjustSecondDivWidth();
      window.addEventListener('resize', adjustSecondDivWidth);
    };
  }, []);

  function adjustSecondDivWidth() {
    console.log(window.innerWidth);
  }

  const onChange = (e) => {
    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));

    if (e.target.name == "search") {
      obj.search = value;
      obj.chain = inputs.restaurantChain;
    } else if (e.target.name == "restaurantChain") {
      obj.search = inputs.search;
      obj.chain = value;
    }

    getRestaurantList(obj);
  };

  var ResellerId = -1,
    Token = "";
  if (localStorage.getItem("UserDetails")) {
    ResellerId = JSON.parse(localStorage.getItem("UserDetails")).ResellerId;
    Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
  }

  const [rowData, setRowData] = useState([]);

  const [columnDefs, setColumnDefs] = useState([
    { headerName: "Restaurant Name", field: "Name", width: 350 },
    {
      headerName: "URL",
      width: 150,
      cellRenderer: (params) => (
        <div className="rListAction">
          <button onClick={() => RedirectURL(params.data.RestChainId)} style={{ border: "none", color: "#F3F6F9" }}>
            <BsLink color="grey" size={18} />
          </button>
        </div>

        // <div>
        //   <BsLink onClick={() => RedirectURL(params.data.RestChainId)}>
        //     {/* <u>https://restadmin.imenu360.com/index.html</u> */}
        //   </BsLink>
        // </div>
      ),
    },
    {
      headerName: "Ordering URL",
      width: 175,
      cellRenderer: (params) => (
        <div className="rListAction">
          <button onClick={() =>
            window.open(
              "https://orderonlinemenu.com/new_ui/default.aspx?urlname=" +
              params.data.UrlName,
              "_blank"
            )
          }
            style={{ border: "none", color: "#F3F6F9" }}>
            <BsLink45Deg color="grey" size={18} />
          </button>
        </div>
        // <div>
        //   <BsLink45Deg
        //     onClick={() =>
        //       window.open(
        //         "https://orderonlinemenu.com/new_ui/default.aspx?urlname=" +
        //           params.data.UrlName,
        //         "_blank"
        //       )
        //     }
        //   >
        //     {/* <u>
        //       https://orderonlinemenu.com/new_ui/default.aspx?urlname=
        //       {params.data.UrlName}
        //     </u> */}
        //   </BsLink45Deg>
        // </div>
      ),
    },
    // {
    //   headerName: "Services",
    //   width: 315,
    //   cellRenderer: (params) => (
    //     <div>
    //       <div style={{ display: "flex" }}>
    //         <div>
    //           <LiaTagSolid
    //             color="#2C394B"
    //             size={40}
    //             style={{
    //               padding: 8,
    //               backgroundColor: "#C9F7F5",
    //               borderRadius: "50%",
    //             }}
    //           />
    //         </div>
    //         <div>
    //           <TbWorld
    //             color="#F64E60"
    //             size={40}
    //             style={{
    //               padding: 8,
    //               backgroundColor: "#FFE2E5",
    //               borderRadius: "50%",
    //             }}
    //           />
    //         </div>
    //         <div>
    //           <LiaCreditCard
    //             color="#FFA800"
    //             size={40}
    //             style={{
    //               padding: 8,
    //               backgroundColor: "#FFF4DE",
    //               borderRadius: "50%",
    //             }}
    //           />
    //         </div>
    //         <div>
    //           <CiMobile2
    //             color="#6993FF"
    //             size={40}
    //             style={{
    //               padding: 8,
    //               backgroundColor: "#E1E9FF",
    //               borderRadius: "50%",
    //             }}
    //           />
    //         </div>
    //         <div>
    //           <LiaExchangeAltSolid
    //             color="#424242"
    //             size={40}
    //             style={{
    //               padding: 8,
    //               backgroundColor: "#D1D3E0",
    //               borderRadius: "50%",
    //             }}
    //           />
    //         </div>
    //         <div>
    //           <LiaFileDownloadSolid
    //             color="#2C394B"
    //             size={40}
    //             style={{
    //               padding: 8,
    //               backgroundColor: "#C9F7F5",
    //               borderRadius: "50%",
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      headerName: "Last Updated",
      field: "CreationDate",
      width: 160,
      cellRenderer: (params) => (
        <span>{new Date(params.data.LastUpdated).toLocaleDateString()}</span>
      ),
    },
    {
      headerName: "Status",
      field: "isActive",
      width: 120,
      cellRenderer: (params) =>
        params.data.isActive === true ? (
          <span className="activetag">Active</span>
        ) : (
          <span className="inactivetag">Inactive</span>
        ),
    },
    {
      headerName: "Action",
      width: 100,
      cellRenderer: (params) => (
        <div className="actionDiv">
          <div className="rListAction">
            <button
              className="actionBtns"
              onClick={() => {
                localStorage.setItem(
                  "RestLocationId",
                  params.data.RestLocationId
                );
                localStorage.setItem(
                  "RestChainId",
                  params.data.RestChainId
                );
                navigate("/reseller/setup/name_adress");
              }}
            ><FiEdit color="grey" size={18} /></button>
          </div>
          {/* {params.data.RestChainId == 33566 &&
            <div className="rListAction">
              <button
                className="actionBtns"
                onClick={() => {
                  
                }}
              ><FaTrash color="grey" size={18} /></button>
            </div>
          } */}
        </div>
      ),
    },
  ]);

  const getRestaurantList = (obj) => {
    var search = "",
      chain = -1;
    if (obj) {
      search = obj.search;
      chain = obj.chain ? obj.chain : -1;
    }

    Axios.post(APIUrl + "GetLocationReseller", {
      data: {
        token: Token,
        ResellerId: ResellerId,
        RestLocationName: search,
        RestChainId: chain,
      },
    })
      .then((response) => {
        setRowData(response.data.data.list_locations);
        setNewChainList(response.data.data.list_locations);
      })
      .catch((e) => {
        if (e.response.data == "Unauthorized") {
          setUnauthorized(true);
        }
        setRowData([]);
      });
  };
  React.useEffect(() => getRestaurantList(obj), []);

  const getRC = () => {
    Axios.post(APIUrl + "GetRestChainByReseller", {
      data: { token: Token, ResellerId: ResellerId },
    })
      .then((response) => {
        const chains = response.data.data.restChains;

        const filtered = chains.filter((chains) => {
          return chains.IsActive === true;
        });
        setChainList(filtered);
      })
      .catch((e) => {
        if (e.response.data == "Unauthorized") {
          setUnauthorized(true);
        }
        setChainList([]);
      });
  };
  React.useEffect(() => getRC(), []);

  function RedirectURL(RestChainId) {
    Axios.post(APIUrl + "GetRestAdminToken", {
      data: {
        token: Token,
        RestChainId: RestChainId,
      },
    })
      .then((response) => {
        if (response.data.status == "s") {
          window.open(
            "https://restadmin.imenu360.com/index.html?tid=" +
            response.data.data.Token,
            "_blank"
          );
        }
      })
      .catch((error) => {
        // alert(error.response.data.message);
        if (error.response.data == "Unauthorized") {
          setUnauthorized(true);
        }
      });
  }

  return (
    <div className="contentForm" style={{ width: "100%" }}>
      <div className="rListTable" style={style}>
        <div>
          <h2 className="rListTitle2">Restaurants</h2>
        </div>
        <div className="rListHeader">
          <div className="rListSearch">
            <Input
              value={inputs.search}
              name="search"
              onChange={onChange}
              placeholder="Search..."
              className="rListSearch1"
            />
            <AiOutlineSearch color="grey" size={15} />
          </div>
          <div className="rListSearch">
            <select
              className="rListSearch1"
              name="restaurantChain"
              onChange={onChange}
              value={inputs.restaurantChain}
            >
              <option value="">Choose Chain</option>
              {chainList.map((item) => (
                <option key={item.RestChainId} value={item.RestChainId}>
                  {item.Name}
                </option>
              ))}
            </select>
          </div>

          <button
            className="editschedulebtn"
            onClick={() => {
              localStorage.removeItem("RestaurantDetails");
              localStorage.setItem("RestaurantEditDetails", -1);
              localStorage.setItem("RestLocationId", -1);
              localStorage.setItem("actiontype", 'add');
              localStorage.setItem("RestChainId", -1);
              navigate("/reseller/setup/name_adress", {
                state: { SelectedChainId: inputs.restaurantChain, actionType: 'add' },
              });
            }}
          >
            Add Restaurant
          </button>
        </div>
      </div>
      {/* <div className="gridPanel">
        <div className="ag-theme-alpine" style={{ height: 550 }}>
          <AgGridReact rowData={rowData} columnDefs={columnDefs} />
        </div>
      </div> */}
      <div className="resListTableOuter">
        <div className="resListTable">
          <table>
            <tr>
              <th>Restaurant Name</th>
              <th>Rest Admin</th>
              <th>Ordering URL</th>
              <th>Last Updated</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            {newChainList.map((list) => (
              <tr>
                <td>{list.Name}</td>
                <td>
                  <div className="rListAction">
                    <button onClick={() => RedirectURL(list.RestChainId)} style={{ border: "none", color: "#F3F6F9" }}>
                      <BsLink color="grey" size={18} />
                    </button>
                  </div>
                </td>
                <td>
                  <div className="rListAction">
                    <button onClick={() =>
                      window.open(
                        "https://orderonlinemenu.com/new_ui/default.aspx?urlname=" +
                        list.UrlName,
                        "_blank"
                      )
                    }
                      style={{ border: "none", color: "#F3F6F9" }}>
                      <BsLink45Deg color="grey" size={18} />
                    </button>
                  </div>
                </td>
                <td>{new Date(list.LastUpdated).toLocaleDateString()}</td>
                <td>
                  {list.isActive === true ? (
                    <span className="activetag">Active</span>
                  ) : (
                    <span className="inactivetag">Inactive</span>
                  )}
                </td>
                <td>
                  <div className="actionDiv">
                    <div className="rListAction">
                      <button
                        className="actionBtns"
                        onClick={() => {
                          localStorage.setItem("actiontype", 'edit')
                          localStorage.setItem(
                            "RestLocationId",
                            list.RestLocationId
                          );
                          localStorage.setItem(
                            "RestChainId",
                            list.RestChainId
                          );
                          localStorage.setItem("Restaurantname", list.Name)
                          navigate("/reseller/setup/name_adress", { state: { actionType: 'edit' } });
                        }}
                      ><FiEdit color="grey" size={18} /></button>
                    </div>
                  </div>
                </td>
              </tr>
            ))
            }
          </table>
        </div>
      </div>
      <SessionEnd openOrNot={unauthorized} />
    </div>
  );
}
