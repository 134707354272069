import React from 'react';
import { Form } from '@start-base/react-form-elements';
import { Button } from '@mui/material';
import logo from './images/sad-icon.png';
import { useNavigate } from 'react-router-dom';

export default function AccountConfirmation() {
  const navigate = useNavigate()

   return (
    <div className='smlwindowHeight'>
      <div className='contentForm smallPageWindow'>
        <div className='formHeading'>
          <div className='btnwithText'><Button onClick={() => { navigate('/reseller/setup/location') }}>Back</Button>
            <h2>Please validate your email address</h2></div>
          <Button className='black-colorBtn' onClick={() => { navigate('/reseller/setup/pickup') }}>Next</Button>
        </div>
        <Form>
          <div className='contentFormBody'>
            <div className='textwithimgBlock'>
              <div className='textblck'>
                <p>A moment ago we have sent you an email to <b>sanjay01694@gmail.com</b></p>
                <p>Please click on the link in order to validate your email address. If you don't see it please check your spam folder.
                  In case you find the email in spam folder, Please mark the email as <b>'safe'</b>
                </p>
                <div className='formButtonMain'>
                  <Button>Resend Activation Email</Button></div>
              </div>
              <div className='imgblck'>
                <div>
                  <img src={logo} />
                  <span>Not yet validated</span>
                </div>
              </div>
            </div>
          </div>
        </Form >
      </div >
    </div>
  )
}