import React, { useState, useEffect } from "react";
import { Input, TextArea, Form } from "@start-base/react-form-elements";
import { Button } from "@mui/material";
import { LiaEnvelope } from "react-icons/lia";
import { LiaPhoneSolid } from "react-icons/lia";
import Axios from "axios";
import { APIUrl } from "../..";
import { message } from "antd";
import SessionEnd from '../Setup/SessionEnd';
// import ReactIntlTelInput from 'react-intl-tel-input-v2';
import PhoneInput from "react-phone-input-2";

export default function ProfileUpdate() {
  const [messageApi, contextHolder] = message.useMessage();
  const [unauthorized, setUnauthorized] = useState(false)
  // const [phoneNumber, setPhoneNumber] = useState('')
  // const [countryCode, setCountryCode] = useState({
  //   "iso2": "us",
  //   "dialCode": "1",
  //   "phone": ""
  // })
  const [newNO, setnewNo] = useState('')

  function ProfileUpdate(e) {
    e.preventDefault();
    if (newNO?.split('+')[1]?.split(' ')[1].length > 10) {
      messageApi.open({
        type: "error",
        content: "Enter a valid phone number",
      });
      return
    }

    var Token = "",
      ResellerId = "-1";
    if (localStorage.getItem("UserDetails")) {
      Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
      ResellerId = JSON.parse(localStorage.getItem("UserDetails")).ResellerId;
    }

    Axios.post(APIUrl + "UpdateProfile", {
      data: {
        token: Token,
        ResellerId: ResellerId,
        signupInfo: {
          UserName: newdata.email,
          Name: newdata.name,
          Phone: newNO,
        },
      },
    })
      .then((response) => {
        if (response.data.status == "s") {
          messageApi.open({
            type: "success",
            content: "Profile Updated",
          });
          let newUserDetails = JSON.parse(localStorage.getItem("UserDetails"));
          console.log(newUserDetails);
          const newUserDetailsToAdd = {
            "ResellerId": JSON.parse(localStorage.getItem("UserDetails")).ResellerId,
            "IsApprovedUser": JSON.parse(localStorage.getItem("UserDetails")).IsApprovedUser,
            "Token": JSON.parse(localStorage.getItem("UserDetails")).Token,
            "IsSuperAdmin": JSON.parse(localStorage.getItem("UserDetails")).IsSuperAdmin,
            "UserName": newdata.email,
            "Name": newdata.name,
            "Phone": newNO
          };
          localStorage.setItem("UserDetails", JSON.stringify(newUserDetailsToAdd))
        }
      })
      .catch((error) => {
        if (error.response.data == "Unauthorized") {
          setUnauthorized(true)
        } else {
          messageApi.open({
            type: "warning",
            content: error.response.data,
          });
        }
      });
  }

  const [inputs, setInputs] = useState({});
  const [showphonevalid, setShowPhoneValid] = useState("");

  const newdata = [];
  newdata.name = inputs.name;
  newdata.email = inputs.email;

  const [style, setStyle] = useState({})
  useEffect(() => {
    var isClass = "",
      IsSuperAdmin = false;
    if (localStorage.getItem("UserDetails")) {
      var IsApprovedUser = JSON.parse(
        localStorage.getItem("UserDetails")
      ).IsApprovedUser;

      IsSuperAdmin = JSON.parse(localStorage.getItem("UserDetails")).IsSuperAdmin;
      if (IsApprovedUser === false) setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      });
    }
  }, [])

  const onChange = (e) => {
    if (e.target.name == "phone") {
      const result = e.target.value.replace(/\D/g, "");
      setShowPhoneValid(result);
    }

    const { name, value, type } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));
  };

  useEffect(() => {
    var OldEmail = "",
      OldPhone = "",
      OldName = "";
    if (localStorage.getItem("UserDetails")) {
      OldEmail = JSON.parse(localStorage.getItem("UserDetails")).UserName;
      OldPhone = JSON.parse(localStorage.getItem("UserDetails")).Phone;
      OldName = JSON.parse(localStorage.getItem("UserDetails")).Name;
    }
    const UserDetails = localStorage.getItem("UserDetails")
    const phone = JSON.parse(UserDetails).Phone
    setnewNo(phone)
    // const countryName = phone.split(',')[1];
    // const countryCode = phone.split('-')[0].split('+')[1];
    // setPhoneNumber(phone.split('-')[1].split(',')[0])
    // var d = {
    //   "iso2": countryName,
    //   "dialCode": countryCode,
    //   "phone": ""
    // }
    // setCountryCode(d)
    // console.log(d);

    setInputs((prevState) => ({
      ...prevState,
      email: OldEmail,
      name: OldName,
    }));
    setShowPhoneValid(OldPhone);
  }, []);

  return (
    <div>
      {contextHolder}
      <div className="contentForm form-container">
        <div className="formHeading">
          <h2>Profile Update</h2>
        </div>
        <Form onSubmit={ProfileUpdate} style={style}>
          <div className="Form_root_Inner">
            <div>
              <Input
                label="Name"
                name="name"
                onChange={onChange}
                value={inputs.name}
                required="true"
              />
            </div>
            <div className="formRow">
              <Input
                label="Email"
                name="email"
                onChange={onChange}
                value={inputs.email}
                required="true"
              />
              {/* <Input
                label="Phone"
                name="phone"
                onChange={onChange}
                value={showphonevalid}
                required="true"
              /> */}
              {/* <div className="phonenocode">
                <label>Phone</label>
                <ReactIntlTelInput
                  intlTelOpts={{ preferredCountries: ['us'] }}
                  value={countryCode}
                  name="phone"
                  onChange={async (phone) => { setCountryCode(phone); }}
                />
                <span className="countryCode" >+{countryCode?.dialCode}</span>
                <input
                  type="tel"
                  name="phoneNumber"
                  className="form-control"
                  required
                  value={phoneNumber}
                  onChange={async (e) => { setPhoneNumber(e.target.value) }}
                  onFocus={() => { }}
                />
              </div> */}
              <div className="ph-num-field halfField">
                <label>
                  Phone number</label>
                <PhoneInput
                  country={'us'}
                  // value={PhoneNumber}
                  // value={showphonevalid}
                  // onChange={onChange}
                  value={newNO?.split('+')[1]?.split(' ')[0] + "" + newNO?.split('+')[1]?.split(' ')[1]}
                  onChange={(value, country, event, formattedValue) => { setnewNo("+" + country.dialCode + " " + value.slice(country.dialCode.length)); }}
                  inputProps={{ required: true }}
                ></PhoneInput>
              </div>
            </div>
          </div>
          <div className="formButtonMain btnRow">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
      <SessionEnd openOrNot={unauthorized} />
    </div>
  );
}
