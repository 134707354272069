import React, { useState } from "react";
import {
  Input,
  PasswordInput,
  Form,
  TextArea,
} from "@start-base/react-form-elements";
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Axios from "axios";
import validator from "validator";
import { APIUrl } from "../..";

import "intl-tel-input/build/css/intlTelInput.css";
import ReactIntlTelInput from "react-intl-tel-input-v2";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SessionEnd from './SessionEnd';
import { message } from "antd";
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function AddChain() {
  //const [PhoneNumber, setPhoneNumber] = useState('');
  const [showphonevalid, setShowPhoneValid] = useState("");
  const navigate = useNavigate();
  const [errormsg, setErrorMsg] = useState("");
  const [inputs, setInputs] = useState({});
  const [style, setStyle] = useState(null)
  const [unauthorized, setUnauthorized] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState(
    // {
    //   "iso2": "us",
    //   "dialCode": "1",
    //   "phone": ""
    // }
    // {
    //   "name": "Ukraine",
    //   "dialCode": "380",
    //   "countryCode": "ua",
    //   "format": "+... (..) ... .. .."
    // }
  )
  const [messageApi, contextHolder] = message.useMessage();
  const [newNO, setnewNo] = useState('')

  var RestChainDts = useLocation().state.RestChainDts;
  var RestChainId = -1;

  if (RestChainDts != -1) RestChainId = RestChainDts.RestChainId;

  const handleUpdate = () => {
    if (!JSON.parse(localStorage.getItem("UserDetails")).IsApprovedUser) {
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
    }
    console.log(RestChainDts);
    document.getElementById("chkIsActive").checked = RestChainDts.IsActive;
    setShowPhoneValid(RestChainDts.Telephone);
    console.log(RestChainDts.Telephone);
    setPhoneNumber(RestChainDts?.Telephone)
    setnewNo(RestChainDts?.Telephone)
    // console.log(RestChainDts?.Telephone?.split(',')[1]);
    // setPhoneNumber(RestChainDts?.Telephone?.split('-')[1].split(',')[0])
    // var d = {
    //   "iso2": RestChainDts?.Telephone?.split(',')[1],
    //   "dialCode": RestChainDts?.Telephone?.split('-')[0].split('+')[1],
    //   "phone": ""
    // }
    // setCountryCode(d)
    setInputs((prevState) => ({
      ...prevState,
      Name: RestChainDts.Name,
      Email: RestChainDts.Email,
      Description: RestChainDts.Description,
      email: RestChainDts.email,
      // Fax: RestChainDts.Fax,
    }));
    document.getElementById("chkIsActive").checked = RestChainDts.IsActive;
    setShowPhoneValid(RestChainDts.Telephone);
    const restChainId = localStorage.getItem("RestChainId")
    if (restChainId == 33566 || restChainId == 33590) {
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
    }
  };
  React.useEffect(() => {
    handleUpdate();
  }, []);

  const newdata = [];
  newdata.Name = inputs.Name;
  newdata.Description = inputs.Description;
  newdata.email = inputs.email;
  //newdata.Fax = inputs.Fax;
  newdata.IsActive = inputs.IsActive;

  const onChange = (e) => {
    setErrorMsg("");

    // if (e.target.name == "Telephone") {
    //   const result = e.target.value.replace(/\D/g, "");
    //   setShowPhoneValid(result);
    // }

    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));
  };

  function SaveChainInfo(e) {
    e.preventDefault();
    setLoading(true)
    if (newNO?.split('+')[1]?.split(' ')[1].length > 10) {
      messageApi.open({
        type: "error",
        content: "Enter a valid phone number",
      });
      setLoading(false)
      return
    }
    var ResellerId = -1;
    if (localStorage.getItem("UserDetails"))
      ResellerId = JSON.parse(localStorage.getItem("UserDetails")).ResellerId;


    var IsActiveChain = true;
    if (newdata.IsActive === undefined) IsActiveChain = false;

    var Token = "";
    if (localStorage.getItem("UserDetails"))
      Token = JSON.parse(localStorage.getItem("UserDetails")).Token;

    Axios.post(APIUrl + "AddUpdateRestChain", {
      data: {
        token: Token,
        restchain: {
          RestChainId: RestChainId,
          ResellerId: ResellerId, //localStorage.getItem("ResellerId"),
          Name: newdata.Name,
          Description: newdata.Description,
          email: newdata.email,
          Fax: " ", //newdata.Fax,
          Telephone: newNO,
          // Telephone: "+" + countryCode?.dialCode + "-" + phoneNumber + "," + countryCode?.iso2,
          IsActive: document.getElementById("chkIsActive").checked,
        },
      },
    })
      .then((response) => {
        setLoading(false)
        if (response.data.status == "s") {
          navigate("/reseller/setup/chainlist");
        } else if (response.data.status == "f")
          setErrorMsg(response.data.message);
      })
      .catch((error) => {
        setLoading(false)
        // console.log(error.response.data);
        if (error.response.data == "Unauthorized") {
          setUnauthorized(true)
        }
      });
    setLoading(false)
  }



  //const [valid, setValid] = useState(true);

  // const validatePhoneNumber = (phoneNumber) => {
  //   const phoneNumberPattern = /^\d{10}$/;
  //   return phoneNumberPattern.test(phoneNumber);
  // };

  const handleChange = (value) => {
    setShowPhoneValid(value);
    //setValid(validatePhoneNumber(value));
  };

  return (
    <div className="contentForm">
      {contextHolder}
      <div className="formHeading">
        <Button
          onClick={() => {
            navigate("/reseller/setup/chainlist");
          }}
        >
          Back
        </Button>
        <h2>Chain Details</h2>
      </div>
      <Form onSubmit={SaveChainInfo}>
        <div className="Form_root_Inner" style={style}>
          <Input
            label="Name"
            name="Name"
            onChange={onChange}
            value={inputs.Name}
            placeholder="Enter Chain Name..."
            required="true"
          />

          <div className="formRow halfField">
            <Input
              label="Email"
              name="email"
              onChange={onChange}
              value={inputs.email}
              placeholder="Enter Email..."
              required="true"
              type="email"
            />
            {/* <Input
              label="Fax"
              name="Fax"
              onChange={onChange}
              value={inputs.Fax}
              placeholder="Fax..."
            /> */}
            <div className="ph-num-field halfField ">
              <label>
                Phone number</label>
              <PhoneInput
                country={'us'}
                value={newNO?.split('+')[1]?.split(' ')[0] + "" + newNO?.split('+')[1]?.split(' ')[1]}
                onChange={(value, country, event, formattedValue) => { setnewNo("+" + country.dialCode + " " + value.slice(country.dialCode.length)); }}
                inputProps={{ required: true }}
              ></PhoneInput>
            </div>
            {/* <div className="phonenocode">
              <label>Phone number</label>
              <ReactIntlTelInput
                intlTelOpts={{ preferredCountries: ['us'] }}
                value={countryCode}
                name="phone"
                onChange={async (phone) => { setCountryCode(phone); console.log(phone); }}
              />
              <span className="countryCode" >+{countryCode?.dialCode}</span>
              <input
                type="tel"
                name="phoneNumber"
                className="form-control"
                required
                value={phoneNumber}
                onChange={async (e) => { setPhoneNumber(e.target.value) }}
                onFocus={() => { }}
              />
            </div> */}
            {/* {!valid && <p>Please enter a valid phone number</p>} */}

            {/* <Input
              label="Phone number"
              name="Telephone"
              onChange={onChange}
              value={showphonevalid}
              placeholder="Phone number..."
              required="true"
            /> */}
          </div>
          <TextArea
            rows={3}
            label="Description"
            name="Description"
            onChange={onChange}
            value={inputs.Description}
            required="true"
          ></TextArea>

          <div class="checkbox-group">
            <input
              type="checkbox"
              id="chkIsActive"
              onChange={onChange}
              name="IsActive"
            />
            <label for="chkIsActive">is Active?</label>
          </div>

          {/* <Checkbox
            style={{ color: '#2C394B' }}
            onChange={onChange}
            checkedIcon={<CheckBoxIcon />}
          /> */}
        </div>
        <div className="error-msg">{errormsg}</div>
        <div className="formButtonMain" style={style}>
          {/* <Button
            // type="submit"
            onClick={() => SaveChainInfo()}
          >
            Next
          </Button> */}
          <LoadingButton
            // onClick={() => SaveChainInfo()}
            type="submit"
            loading={loading}
            loadingPosition="end"
            endIcon={loading ? <CheckBoxOutlineBlankIcon color={"#2C394B"} style={{ color: "#2C394B" }} /> : ''}
          >
            <span>Next</span>
          </LoadingButton>
        </div>
      </Form>
      <SessionEnd openOrNot={unauthorized} />
    </div>
  );
}
