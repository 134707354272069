import { APIUrl } from '..'
import axios from 'axios'

export async function addServiceHelper(serviceData) {
    console.log('posting service...', serviceData)
    try {
        const getServiceResponse = await axios.post(APIUrl + 'AddRestService', serviceData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        console.log(getServiceResponse.data)
        if (getServiceResponse.data.serviceStatus === 200) {
            return 200
        }
    } catch (error) {
        console.log(error)
        if (error.response.data.serviceStatus === 400) {
            return 400
        } else if (error.response.data == "Unauthorized") {
            return 401
        }
    }
}