import { Menu } from "antd";
import { HomeOutlined, BarsOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SettingsPhoneOutlinedIcon from "@mui/icons-material/SettingsPhoneOutlined";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { LiaEnvelope } from "react-icons/lia";
import { LiaPhoneSolid } from "react-icons/lia";
import { useEffect } from "react";

const SidebarMenuList = () => {
  const navigate = useNavigate();

  var isClass = "",
    IsSuperAdmin = false;
  if (localStorage.getItem("UserDetails")) {
    var IsApprovedUser = JSON.parse(
      localStorage.getItem("UserDetails")
    ).IsApprovedUser;

    IsSuperAdmin = JSON.parse(localStorage.getItem("UserDetails")).IsSuperAdmin;
    if (IsApprovedUser === false) isClass = "form-overlay";
  }

  return (
    <Menu
      theme="dark"
      mode="inline"
    // style={{width: 256}}
    // onClick={({ key }) => {
    //   alert(key);
    // }}
    >
      <Menu.SubMenu
        key="Setup"
        icon={<PhonelinkSetupIcon />}
        title="Setup"
        // className="menu-setup"
        id="menu-setup"
      // onTitleClick={() => {
      //   navigate("/reseller/setup/chainlist");
      // }}
      >
        <Menu.SubMenu key="RestaurantBasics" title="Restaurant Basics">
          <Menu.Item
            key="NameAdress"
            onClick={() => {
              navigate("/reseller/setup/name_adress");
            }}
          >
            Name & Address
          </Menu.Item>
          <Menu.Item
            key="OwnerPersonalInformation"
            onClick={() => {
              navigate("/reseller/setup/owner_personal_info");
            }}
          >
            Owner Personal Information
          </Menu.Item>
          <Menu.Item
            key="Location"
            onClick={() => {
              navigate("/reseller/setup/location");
            }}
          >
            Location
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          key="ServicesOpeningHours"
          title="Services & hours"
        >
          <Menu.Item
            key="OnlinePayment"
            onClick={() => {
              navigate("/reseller/setup/online_payment");
            }}
          >
            Online Payment
          </Menu.Item>
          <Menu.Item
            key="Pickup"
            onClick={() => {
              navigate("/reseller/setup/pickup");
            }}
          >
            Pickup
          </Menu.Item>
          <Menu.Item
            key="Delivery"
            onClick={() => {
              navigate("/reseller/setup/delivery");
            }}
          >
            Delivery
          </Menu.Item>

          <Menu.Item
            key="DineIn"
            onClick={() => {
              navigate("/reseller/setup/dine_in");
            }}
          >
            Dine-In
          </Menu.Item>
          <Menu.Item
            key="Curbside"
            onClick={() => {
              navigate("/reseller/setup/curbside");
            }}
          >
            Curbside
          </Menu.Item>
          <Menu.Item
            key="OpeningHours"
            onClick={() => {
              navigate("/reseller/setup/opening_hours");
            }}
          >
            Opening Hours
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key="PaymentTaxesLegal" title="Payment, taxes & legal">
          <Menu.Item
            key="Taxation"
            onClick={() => {
              navigate("/reseller/setup/taxation");
            }}
          >
            Taxation
          </Menu.Item>
          <Menu.Item
            key="ServiceCharges"
            onClick={() => {
              navigate("/reseller/setup/service_charges");
            }}
          >
            Service Charges
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="Menu" onClick={()=>{navigate('/reseller/setup/menu')}}>Menu</Menu.Item>
        <Menu.SubMenu
          key="MenuSetupReceiveOrders"
          title="Receive orders"
        >
          <Menu.Item
            key="ReceiveOrders"
            onClick={() => {
              navigate("/reseller/setup/receive_orders");
            }}
          >
            Receive orders
          </Menu.Item>
        </Menu.SubMenu>
      </Menu.SubMenu>

      <Menu.SubMenu
        key="Restaurant"
        icon={<RestaurantIcon />}
        title="Restaurant"
        // className="menu-restaurant"
        id="menu-restaurant"
      >
        <Menu.Item
          key="RestaurantChainList"
          onClick={() => {
            navigate("/reseller/setup/chainlist");
          }}
        >
          Restaurant Chain List
        </Menu.Item>
        <Menu.Item
          key="RestaurantList"
          onClick={() => {
            navigate("/reseller/restaurant/list");
          }}
        >
          Restaurant List
        </Menu.Item>
        <Menu.Item
          key="RestaurantReports"
          onClick={() => {
            navigate("/reseller/restaurant/reports");
          }}
        >
          Restaurant Reports
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.Item
        key="Commission"
        icon={<Diversity1Icon />}
        // className="menu-commission"
        id="menu-commission"
        onClick={() => {
          navigate("/reseller/commission/commission");
        }}
      >
        Commission
      </Menu.Item>

      <Menu.Item
        key="Support"
        icon={<ContactSupportIcon />}
        // title="Support"
        // className="menu-support"
        id="menu-support"
        onClick={() => {
          navigate("/reseller/support/contact_us");
        }}
      >
        Support
        {/* <Menu.SubMenu key="CustomerSupport" title="Customer Support">
          <Menu.Item key="CustomerSupportEmail" icon={<LiaEnvelope />}>
            <a href="mailto:support@imenu360.com">support@imenu360.com</a>
          </Menu.Item>
          <Menu.Item key="CustomerSupportPhone" icon={<LiaPhoneSolid />}>
            <a href="tel:86-629-9646">(866)629-9646 Ext. 1</a>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="ContactSales" title="Contact Sales">
          <Menu.Item key="ContactSalesEmail" icon={<LiaEnvelope />}>
            <a href="mailto:sales@imenu360.com">sales@imenu360.com</a>
          </Menu.Item>
          <Menu.Item key="ContactSalesPhone" icon={<LiaPhoneSolid />}>
            <a href="tel:86-629-9646">(866)629-9646 Ext. 2</a>
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.SubMenu key="Billing" title="Billing">
          <Menu.Item key="BillingEmail" icon={<LiaEnvelope />}>
            <a href="mailto:billing@imenu360.com">billing@imenu360.com</a>
          </Menu.Item>
          <Menu.Item key="BillingPhone" icon={<LiaPhoneSolid />}>
            <a href="tel:86-629-9646">(866)629-9646 Ext. 4</a>
          </Menu.Item>
        </Menu.SubMenu> */}
      </Menu.Item>

      {IsSuperAdmin && (
        <Menu.Item
          key="PendingApprovals"
          icon={<PendingActionsIcon />}
          // className="menu-pendingapprovals"
          id="menu-pendingapprovals"
          onClick={() => {
            navigate("/reseller/approval/pending_approval");
          }}
        >
          Pending Approvals
        </Menu.Item>
      )}

      <Menu.Item
        key="UpdateProfile"
        icon={<AccountBoxIcon />}
        // className="menu-udpdateprofile"
        id="menu-updateprofile"
        onClick={() => {
          navigate("/reseller/profileupdate");
        }}
      >
        Update Profile
      </Menu.Item>

      {!IsApprovedUser && (
        <Menu.Item
          key="ScheduleCall"
          icon={<SettingsPhoneOutlinedIcon />}
          // className="menu-schedulecall"
          id="menu-schedulecall"
          onClick={() => {
            navigate("/reseller/schedulecall");
          }}
        >
          Schedule Call
        </Menu.Item>
      )}
    </Menu>
  );
};

export default SidebarMenuList;
