import React, { useState, useEffect } from "react";
import { Input, PasswordInput, Form } from "@start-base/react-form-elements";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import Axios from "axios";
import { APIUrl } from "../..";
import SessionEnd from './SessionEnd';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function ServiceCharges() {
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const [unauthorized, setUnauthorized] = useState(false)

  const [showservicechargevalid, setShowServiceChargeValid] = useState("");

  const [showpickupminflatfeevalid, setShowPickupMinFlatFeeValid] =
    useState("");
  const [showpickupminorderamntvalid, setShowPickupMinOrderAmntValid] =
    useState("");
  const [showpickupmaxorderamntvalid, setShowPickupMaxOrderAmntValid] =
    useState("");

  const [showdineinminflatfeevalid, setShowDineInMinFlatFeeValid] =
    useState("");
  const [showdineinminorderamntvalid, setShowDineInMinOrderAmntValid] =
    useState("");
  const [showdineinmaxorderamntvalid, setShowDineInMaxOrderAmntValid] =
    useState("");

  const [showdeliveryminflatfeevalid, setShowDeliveryMinFlatFeeValid] =
    useState("");

  const [showcurbminflatfeevalid, setShowCurbMinFlatFeeValid] = useState("");
  const [showcurbminorderamntvalid, setShowCurbMinOrderAmntValid] =
    useState("");
  const [showcurbmaxorderamntvalid, setShowCurbMaxOrderAmntValid] =
    useState("");

  //curb more fees
  const [showcurborderamnt1valid, setShowCurbOrderAmnt1Valid] = useState("");
  const [showcurborderamnt2valid, setShowCurbOrderAmnt2Valid] = useState("");
  const [showcurborderamnt3valid, setShowCurbOrderAmnt3Valid] = useState("");
  const [showcurbflatfee1valid, setShowCurbFlatFee1Valid] = useState("");
  const [showcurbflatfee2valid, setShowCurbFlatFee2Valid] = useState("");
  const [showcurbflatfee3valid, setShowCurbFlatFee3Valid] = useState("");
  const [showcurbminvarfeevalid, setShowCurbMinVarFeeValid] = useState("");
  const [showcurbvarfee1valid, setShowCurbVarFee1Valid] = useState("");
  const [showcurbvarfee2valid, setShowCurbVarFee2Valid] = useState("");
  const [showcurbvarfee3valid, setShowCurbVarFee3Valid] = useState("");
  //curb more fees

  //deliery more fees
  const [showminorderamntvalid, setShowMinOrderAmntValid] = useState("");
  const [showmaxorderamntvalid, setShowMaxOrderAmntValid] = useState("");
  const [showorderamnt1valid, setShowOrderAmnt1Valid] = useState("");
  const [showorderamnt2valid, setShowOrderAmnt2Valid] = useState("");
  const [showorderamnt3valid, setShowOrderAmnt3Valid] = useState("");
  const [showflatfee1valid, setShowFlatFee1Valid] = useState("");
  const [showflatfee2valid, setShowFlatFee2Valid] = useState("");
  const [showflatfee3valid, setShowFlatFee3Valid] = useState("");
  const [showminvarfeevalid, setShowMinVarFeeValid] = useState("");
  const [showvarfee1valid, setShowVarFee1Valid] = useState("");
  const [showvarfee2valid, setShowVarFee2Valid] = useState("");
  const [showvarfee3valid, setShowVarFee3Valid] = useState("");
  //deliery more fees

  const [errormsg, setErrorMsg] = useState("");

  const [showdeliveryfee, setShowDeliveryFee] = useState(false);
  const [showcurbsidefee, setShowCurbsideFee] = useState(false);

  const [showpickupfee, setShowPickupFee] = useState(false);
  const [showdineinfee, setShowDineInFee] = useState(false);
  const [showmaincurbfee, setShowMainCurbFee] = useState(false);
  const [showmaindeliveryfee, setShowMainDeliveryFee] = useState(false);

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [style, setStyle] = useState(null);

  var Token = "";
  if (localStorage.getItem("UserDetails"))
    Token = JSON.parse(localStorage.getItem("UserDetails")).Token;

  useEffect(() => {
    // if (localStorage.getItem("RestLocationId")) {

    const restChainId = localStorage.getItem("RestChainId")
    if (restChainId == 33566 || restChainId == 33590) {
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
    }
    if (
      localStorage.getItem("RestLocationId") == -1 ||
      localStorage.getItem("RestLocationId") == null
    ) {
      messageApi.open({
        type: "warning",
        content: "Please select restaurant first & then you can edit",
      });
      setStyle({
        pointerEvents: "none",
        opacity: 0.5,
      });
    } else handleUpdate();
    //if (localStorage.getItem("RestLocationId") != -1) handleUpdate();
    // }
  }, []);

  function handleUpdate() {
    var RestLocationId = localStorage.getItem("RestLocationId");
    Axios.post(APIUrl + "GetServiceCharges", {
      data: {
        token: Token,
        RestLocationId: RestLocationId,
      },
    })
      .then((response) => {
        if (response.data.status == "s") {
          var r = response.data.data;
          setInputs((prevState) => ({
            ...prevState,
            selMethod: r.PercentOrDollar,
            selTot: r.NetSalesOrTotal,
          }));

          setShowPickupFee(r.isPickUp);
          setShowDineInFee(r.isDineIn);
          setShowMainCurbFee(r.isCurbSide);
          setShowMainDeliveryFee(r.isDelivery);

          setShowServiceChargeValid(r.ServiceCharge);
          setShowPickupMinFlatFeeValid(r.PickUpMinflatFee);
          setShowPickupMinOrderAmntValid(r.PickupMinOrdAmt);
          setShowPickupMaxOrderAmntValid(r.PickUpMaxOrdAmt);

          setShowDineInMinFlatFeeValid(r.DineInMinflatFee);
          setShowDineInMinOrderAmntValid(r.DineInMinOrdAmt);
          setShowDineInMaxOrderAmntValid(r.DineInMaxOrdAmt);

          //curb fees
          setShowCurbMinFlatFeeValid(r.CurbSideMinflatFee);
          setShowCurbFlatFee1Valid(r.CurbSideFlatFee1);
          setShowCurbFlatFee2Valid(r.CurbSideFlatFee2);
          setShowCurbFlatFee3Valid(r.CurbSideFlatFee3);

          setShowCurbMinVarFeeValid(r.CurbSideMinvarFee);
          setShowCurbVarFee1Valid(r.CurbSideVarFee1);
          setShowCurbVarFee2Valid(r.CurbSideVarFee2);
          setShowCurbVarFee3Valid(r.CurbSideVarFee3);

          setShowCurbMinOrderAmntValid(r.CurbSideMinOrdAmt);
          setShowCurbMaxOrderAmntValid(r.CurbSideMaxOrdAmt);
          setShowCurbOrderAmnt1Valid(r.CurbSideOrdAmt1);
          setShowCurbOrderAmnt2Valid(r.CurbSideOrdAmt2);
          setShowCurbOrderAmnt3Valid(r.CurbSideOrdAmt3);
          //curb fees

          //delivery fees
          setShowDeliveryMinFlatFeeValid(r.DeliveryMinflatFee);
          setShowFlatFee1Valid(r.DeliveryFlatFee1);
          setShowFlatFee2Valid(r.DeliveryFlatFee2);
          setShowFlatFee3Valid(r.DeliveryFlatFee3);

          setShowMinVarFeeValid(r.DeliveryMinvarFee);
          setShowVarFee1Valid(r.DeliveryVarFee1);
          setShowVarFee2Valid(r.DeliveryVarFee2);
          setShowVarFee3Valid(r.DeliveryVarFee3);

          setShowMinOrderAmntValid(r.DeliveryMinOrdAmt);
          setShowMaxOrderAmntValid(r.DeliveryMaxOrdAmt);
          setShowOrderAmnt1Valid(r.DeliveryOrdAmt1);
          setShowOrderAmnt2Valid(r.DeliveryOrdAmt2);
          setShowOrderAmnt3Valid(r.DeliveryOrdAmt3);
          //delivery fees
        }
      })
      .catch((error) => {
        // alert(error.response.data.message);
        if (error.response.data == "Unauthorized") {
          setUnauthorized(true)
        }
      });
  }

  const newdata = [];
  newdata.serviceCharge = inputs.serviceCharge;
  newdata.selTot = inputs.selTot;
  newdata.selMethod = inputs.selMethod;

  newdata.pickupMinFlatFee = inputs.pickupMinFlatFee;
  newdata.pickupMinOrderAmnt = inputs.pickupMinOrderAmnt;
  newdata.pickupMaxOrderAmnt = inputs.pickupMaxOrderAmnt;

  newdata.dineInMinFlatFee = inputs.dineInMinFlatFee;
  newdata.dineInMinOrderAmnt = inputs.dineInMinOrderAmnt;
  newdata.dineInMaxOrderAmnt = inputs.dineInMaxOrderAmnt;

  newdata.curbSideMinFlatFee = inputs.curbSideMinFlatFee;
  newdata.curbSideMinOrderAmnt = inputs.curbSideMinOrderAmnt;
  newdata.curbSideMaxOrderAmnt = inputs.curbSideMaxOrderAmnt;
  newdata.curborderAmount1 = inputs.curborderAmount1;
  newdata.curborderAmount2 = inputs.curborderAmount2;
  newdata.curborderAmount3 = inputs.curborderAmount3;
  newdata.curbflatFee1 = inputs.curbflatFee1;
  newdata.curbflatFee2 = inputs.curbflatFee2;
  newdata.curbflatFee3 = inputs.curbflatFee3;
  newdata.curbminVarFee = inputs.curbminVarFee;
  newdata.curbvarFee1 = inputs.curbvarFee1;
  newdata.curbvarFee2 = inputs.curbvarFee2;
  newdata.curbvarFee3 = inputs.curbvarFee3;

  newdata.deliveryMinFlatFee = inputs.deliveryMinFlatFee;
  newdata.minOrderAmount = inputs.minOrderAmount;
  newdata.maxOrderAmount = inputs.maxOrderAmount;
  newdata.orderAmount1 = inputs.orderAmount1;
  newdata.orderAmount2 = inputs.orderAmount2;
  newdata.orderAmount3 = inputs.orderAmount3;
  newdata.flatFee1 = inputs.flatFee1;
  newdata.flatFee2 = inputs.flatFee2;
  newdata.flatFee3 = inputs.flatFee3;
  newdata.minVarFee = inputs.minVarFee;
  newdata.varFee1 = inputs.varFee1;
  newdata.varFee2 = inputs.varFee2;
  newdata.varFee3 = inputs.varFee3;

  const onChange = (e) => {
    if (e.target.name == "serviceCharge") {
      const result = e.target.value.replace(/\D/g, "");
      setShowServiceChargeValid(result);
    } else if (e.target.name == "pickupMinFlatFee") {
      const result = e.target.value.replace(/\D/g, "");
      setShowPickupMinFlatFeeValid(result);
    } else if (e.target.name == "pickupMinOrderAmnt") {
      const result = e.target.value.replace(/\D/g, "");
      setShowPickupMinOrderAmntValid(result);
    } else if (e.target.name == "pickupMaxOrderAmnt") {
      const result = e.target.value.replace(/\D/g, "");
      setShowPickupMaxOrderAmntValid(result);
    } else if (e.target.name == "dineInMinFlatFee") {
      const result = e.target.value.replace(/\D/g, "");
      setShowDineInMinFlatFeeValid(result);
    } else if (e.target.name == "dineInMinOrderAmnt") {
      const result = e.target.value.replace(/\D/g, "");
      setShowDineInMinOrderAmntValid(result);
    } else if (e.target.name == "dineInMaxOrderAmnt") {
      const result = e.target.value.replace(/\D/g, "");
      setShowDineInMaxOrderAmntValid(result);
    } else if (e.target.name == "deliveryMinFlatFee") {
      const result = e.target.value.replace(/\D/g, "");
      setShowDeliveryMinFlatFeeValid(result);
    } else if (e.target.name == "minOrderAmount") {
      const result = e.target.value.replace(/\D/g, "");
      setShowMinOrderAmntValid(result);
    } else if (e.target.name == "maxOrderAmount") {
      const result = e.target.value.replace(/\D/g, "");
      setShowMaxOrderAmntValid(result);
    } else if (e.target.name == "orderAmount1") {
      const result = e.target.value.replace(/\D/g, "");
      setShowOrderAmnt1Valid(result);
    } else if (e.target.name == "orderAmount2") {
      const result = e.target.value.replace(/\D/g, "");
      setShowOrderAmnt2Valid(result);
    } else if (e.target.name == "orderAmount3") {
      const result = e.target.value.replace(/\D/g, "");
      setShowOrderAmnt3Valid(result);
    } else if (e.target.name == "flatFee1") {
      const result = e.target.value.replace(/\D/g, "");
      setShowFlatFee1Valid(result);
    } else if (e.target.name == "flatFee2") {
      const result = e.target.value.replace(/\D/g, "");
      setShowFlatFee2Valid(result);
    } else if (e.target.name == "flatFee3") {
      const result = e.target.value.replace(/\D/g, "");
      setShowFlatFee3Valid(result);
    } else if (e.target.name == "minVarFee") {
      const result = e.target.value.replace(/\D/g, "");
      setShowMinVarFeeValid(result);
    } else if (e.target.name == "varFee1") {
      const result = e.target.value.replace(/\D/g, "");
      setShowVarFee1Valid(result);
    } else if (e.target.name == "varFee2") {
      const result = e.target.value.replace(/\D/g, "");
      setShowVarFee2Valid(result);
    } else if (e.target.name == "varFee3") {
      const result = e.target.value.replace(/\D/g, "");
      setShowVarFee3Valid(result);
    } else if (e.target.name == "curbSideMinFlatFee") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbMinFlatFeeValid(result);
    } else if (e.target.name == "curbSideMinOrderAmnt") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbMinOrderAmntValid(result);
    } else if (e.target.name == "curbSideMaxOrderAmnt") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbMaxOrderAmntValid(result);
    } else if (e.target.name == "curborderAmount1") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbOrderAmnt1Valid(result);
    } else if (e.target.name == "curborderAmount2") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbOrderAmnt2Valid(result);
    } else if (e.target.name == "curborderAmount3") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbOrderAmnt3Valid(result);
    } else if (e.target.name == "curbflatFee1") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbFlatFee1Valid(result);
    } else if (e.target.name == "curbflatFee2") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbFlatFee2Valid(result);
    } else if (e.target.name == "curbflatFee3") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbFlatFee3Valid(result);
    } else if (e.target.name == "curbminVarFee") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbMinVarFeeValid(result);
    } else if (e.target.name == "curbvarFee1") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbVarFee1Valid(result);
    } else if (e.target.name == "curbvarFee2") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbVarFee2Valid(result);
    } else if (e.target.name == "curbvarFee3") {
      const result = e.target.value.replace(/\D/g, "");
      setShowCurbVarFee3Valid(result);
    }

    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));
  };

  function SaveServiceCharge(e) {
    e.preventDefault();
    setLoading(true)
    if (
      localStorage.getItem("RestLocationId") != -1 &&
      localStorage.getItem("RestLocationId") != null
    ) {
      Axios.post(APIUrl + "UpdateServiceCharges", {
        data: {
          token: Token,
          RestLocationId: localStorage.getItem("RestLocationId"),
          serviceCharges: {
            ServiceCharge: showservicechargevalid,
            PercentOrDollar: newdata.selMethod,
            NetSalesOrTotal: newdata.selTot,

            minPickupFlatFee: showpickupminflatfeevalid,
            pickupMinOrderAmt: showpickupminorderamntvalid,
            pickupMaxOrderAmt: showpickupmaxorderamntvalid,
            minPickupVarFee: 0.0,
            pickupOrderAmt1: 0.0,
            pickupOrderAmt2: 0.0,
            pickupOrderAmt3: 0.0,
            pickupFlatFee1: 0.0,
            pickupFlatFee2: 0.0,
            pickupFlatFee3: 0.0,
            pickupVarFee1: 0.0,
            pickupVarFee3: 0.0,
            pickupVarFee3: 0.0,

            minDineInFlatFee: showdineinminflatfeevalid,
            dineInMinOrderAmt: showdineinminorderamntvalid,
            dineInMaxOrderAmt: showdineinmaxorderamntvalid,
            minDineInVarFee: 0.0,
            dineInOrderAmt1: 0.0,
            dineInOrderAmt2: 0.0,
            dineInOrderAmt3: 0.0,
            dineInFlatFee1: 0.0,
            dineInFlatFee2: 0.0,
            dineInFlatFee3: 0.0,
            dineInVarFee1: 0.0,
            dineInVarFee2: 0.0,
            dineInVarFee3: 0.0,

            minCurbSideFlatFee: showcurbminflatfeevalid,
            CurbSideMinOrderAmt: showcurbminorderamntvalid,
            CurbSideMaxOrderAmt: showcurbmaxorderamntvalid,
            CurbSideOrderAmt1: showcurborderamnt1valid,
            CurbSideOrderAmt2: showcurborderamnt2valid,
            CurbSideOrderAmt3: showcurborderamnt3valid,
            CurbSideFlatFee1: showcurbflatfee1valid,
            CurbSideFlatFee2: showcurbflatfee2valid,
            CurbSideFlatFee3: showcurbflatfee3valid,
            minCurbSideVarFee: showcurbminvarfeevalid,
            CurbSideVarFee1: showcurbvarfee1valid,
            CurbSideVarFee2: showcurbvarfee2valid,
            CurbSideVarFee3: showcurbvarfee3valid,

            minDeliveryFlatFee: showdeliveryminflatfeevalid,
            deliveryFlatFee1: showflatfee1valid,
            deliveryFlatFee2: showflatfee2valid,
            deliveryFlatFee3: showflatfee3valid,

            minDeliveryVarFee: showminvarfeevalid,
            deliveryVarFee1: showvarfee1valid,
            deliveryVarFee2: showvarfee2valid,
            deliveryVarFee3: showvarfee3valid,

            deliveryMinOrderAmt: showminorderamntvalid,
            deliveryMaxOrderAmt: showmaxorderamntvalid,
            deliveryOrderAmt1: showorderamnt1valid,
            deliveryOrderAmt2: showorderamnt2valid,
            deliveryOrderAmt3: showorderamnt3valid,
          },
        },
      })
        .then((response) => {
          if (response.data.status == "s") {
            setLoading(false)
            messageApi.open({
              type: "success",
              content: "Serivce Charges Updated !",
            });
            setTimeout(() => {
              navigate("/reseller/setup/menu");
            }, 500);
          } else if (response.data.status == "f") {
            setLoading(false)
            messageApi.open({
              type: "error",
              content: "Something went wrong, try again",
            });
          }
        })
        .catch((error) => {
          // alert(error.response.data.message);
          setLoading(false)
          if (error.response.data == "Unauthorized") {
            setUnauthorized(true)
          } else {
            messageApi.open({
              type: "error",
              content: "Something went wrong, try again",
            });
          }
        });
    } else {
      // setErrorMsg("Please fill restaurant info first.");
      setLoading(false)
      messageApi.open({
        type: "warning",
        content: "Please select restaurant first & then you can edit",
      });
      setStyle({
        pointerEvents: "none",
        opacity: 0.5,
      });
    }
  }

  const [anchorTextDelivery, setAnchorTextDelivery] = useState(
    "Add more delivery fees"
  );
  const [anchorTextCurb, setAnchorTextCurb] = useState(
    "Add more curbside fees"
  );

  function AddRemoveFees(FeeType) {
    if (FeeType == "Delivery") {
      if (!showdeliveryfee) setAnchorTextDelivery("Remove delivery fees");
      else {
        setShowFlatFee1Valid("");
        setShowFlatFee2Valid("");
        setShowFlatFee3Valid("");
        setShowMinVarFeeValid("");
        setShowVarFee1Valid("");
        setShowVarFee2Valid("");
        setShowVarFee3Valid("");
        setShowOrderAmnt1Valid("");
        setShowOrderAmnt2Valid("");
        setShowOrderAmnt3Valid("");

        setAnchorTextDelivery("Add more delivery fees");
      }
      setShowDeliveryFee(!showdeliveryfee);
    } else if (FeeType == "Curb") {
      if (!showcurbsidefee) setAnchorTextCurb("Remove curbside fees");
      else {
        setShowCurbFlatFee1Valid("");
        setShowCurbFlatFee2Valid("");
        setShowCurbFlatFee3Valid("");
        setShowCurbMinVarFeeValid("");
        setShowCurbVarFee1Valid("");
        setShowCurbVarFee2Valid("");
        setShowCurbVarFee3Valid("");
        setShowCurbOrderAmnt1Valid("");
        setShowCurbOrderAmnt2Valid("");
        setShowCurbOrderAmnt3Valid("");

        setAnchorTextCurb("Add more curbside fees");
      }
      setShowCurbsideFee(!showcurbsidefee);
    }
  }

  return (
    <div className="smlwindowHeight">
      <div className="contentForm">
        <div className="formHeading">
          <Button
            onClick={() => {
              if (localStorage.getItem("RestLocationId")) {
                navigate("/reseller/setup/taxation");
              } else {
                navigate("/reseller/restaurant/list");
              }
            }}
          >
            Back
          </Button>
          <h2>Service Charges</h2>
        </div>
        <Form onSubmit={SaveServiceCharge}>
          <div className="Form_root_Inner" style={style}>
            {contextHolder}
            <label className="OnlineOrderingLabel">
              Service Charge <small> (This is usually used for CC processing charges) *</small>
            </label>

            <div className="formRow">

              <div className="serviceCharge-field">
                <label>&nbsp;</label>
                <Input
                  name="serviceCharge"
                  onChange={onChange}
                  value={showservicechargevalid}
                  placeholder="0"
                />
              </div>

              <div className="InputSelect dropdownField serviceCharger">
                <label>&nbsp;</label>
                <select name="selTot" onChange={onChange} value={inputs.selTot}>
                  <option value="1">On Net Sales</option>
                  <option value="2">On Total Amount</option>
                </select>
              </div>
              <div className="InputSelect dropdownField serviceCharger">
                <label>&nbsp;</label>
                <select
                  name="selMethod"
                  onChange={onChange}
                  value={inputs.selMethod}
                >
                  <option value="$">$</option>
                  <option value="%">%</option>
                </select>
              </div>
            </div>

            {showpickupfee && (
              <div id="divPickup">
                <hr />
                <h3>Pickup Fees</h3>
                <div className="formRow threeCol">
                  <Input
                    label="Pick up min flat fee:"
                    name="pickupMinFlatFee"
                    onChange={onChange}
                    placeholder="0"
                    // required="true"
                    value={showpickupminflatfeevalid}
                  />
                  <Input
                    label="Pickup min order amount"
                    name="pickupMinOrderAmnt"
                    onChange={onChange}
                    placeholder="0"
                    // required="true"
                    value={showpickupminorderamntvalid}
                  />
                  <Input
                    label="Pickup max order amount"
                    name="pickupMaxOrderAmnt"
                    onChange={onChange}
                    placeholder="0"
                    // required="true"
                    value={showpickupmaxorderamntvalid}
                  />
                </div>
              </div>
            )}

            {showdineinfee && (
              <div id="divDineIn">
                <hr />
                <h3>Dine-In Fees</h3>
                <div className="formRow threeCol">
                  <Input
                    label="Dine in min flat fee:"
                    name="dineInMinFlatFee"
                    onChange={onChange}
                    placeholder="0"
                    // required="true"
                    value={showdineinminflatfeevalid}
                  />
                  <Input
                    label="Dine In min order amount"
                    name="dineInMinOrderAmnt"
                    onChange={onChange}
                    placeholder="0"
                    // required="true"
                    value={showdineinminorderamntvalid}
                  />
                  <Input
                    label="Dine In max order amount"
                    name="dineInMaxOrderAmnt"
                    onChange={onChange}
                    placeholder="0"
                    // required="true"
                    value={showdineinmaxorderamntvalid}
                  />
                </div>
              </div>
            )}

            {showmaincurbfee && (
              <div id="divCurbside">
                <hr />
                <h3>Curbside Fees</h3>
                <div className="formRow threeCol">
                  <Input
                    label="Curbside min flat fee:"
                    name="curbSideMinFlatFee"
                    onChange={onChange}
                    placeholder="0"
                    // required="true"
                    value={showcurbminflatfeevalid}
                  />
                  <Input
                    label="Curbside min order amount"
                    name="curbSideMinOrderAmnt"
                    onChange={onChange}
                    placeholder="0"
                    // required="true"
                    value={showcurbminorderamntvalid}
                  />
                  <Input
                    label="Curbside max order amount"
                    name="curbSideMaxOrderAmnt"
                    onChange={onChange}
                    placeholder="0"
                    // required="true"
                    value={showcurbmaxorderamntvalid}
                  />
                </div>
                <a
                  id="aAddCurbFee"
                  className="add-more add-btn add-more-btn"
                  onClick={() => {
                    AddRemoveFees("Curb");
                  }}
                >
                  {anchorTextCurb}
                </a>

                {showcurbsidefee && (
                  <div>
                    <div className="formRow threeCol">
                      <Input
                        label="Order amount 1:"
                        name="curborderAmount1"
                        onChange={onChange}
                        placeholder="0"
                        value={showcurborderamnt1valid}
                      // required="true"
                      />
                      <Input
                        label="Order amount 2:"
                        name="curborderAmount2"
                        onChange={onChange}
                        placeholder="0"
                        value={showcurborderamnt2valid}
                      // required="true"
                      />
                      <Input
                        label="Order amount 3:"
                        name="curborderAmount3"
                        onChange={onChange}
                        placeholder="0"
                        value={showcurborderamnt3valid}
                      // required="true"
                      />
                    </div>
                    <div className="formRow threeCol">
                      <Input
                        label="Flat Fee 1:"
                        name="curbflatFee1"
                        onChange={onChange}
                        placeholder="0"
                        value={showcurbflatfee1valid}
                      // required="true"
                      />
                      <Input
                        label="Flat Fee 2:"
                        name="curbflatFee2"
                        onChange={onChange}
                        placeholder="0"
                        value={showcurbflatfee2valid}
                      // required="true"
                      />
                      <Input
                        label="Flat Fee 3:"
                        name="curbflatFee3"
                        onChange={onChange}
                        placeholder="0"
                        value={showcurbflatfee3valid}
                      // required="true"
                      />
                    </div>

                    <div id="divVarFee">
                      <div className="formRow bigLbl">
                        <Input
                          label="Min. Var Fee:"
                          name="curbminVarFee"
                          onChange={onChange}
                          placeholder="0"
                          value={showcurbminvarfeevalid}
                        // required="true"
                        />
                      </div>
                      <div className="formRow threeCol">
                        <Input
                          label="Var Fee 1:"
                          name="curbvarFee1"
                          onChange={onChange}
                          placeholder="0"
                          value={showcurbvarfee1valid}
                        // required="true"
                        />
                        <Input
                          label="Var Fee 2:"
                          name="curbvarFee2"
                          onChange={onChange}
                          placeholder="0"
                          value={showcurbvarfee2valid}
                        // required="true"
                        />
                        <Input
                          label="Var Fee 3:"
                          name="curbvarFee3"
                          onChange={onChange}
                          placeholder="0"
                          value={showcurbvarfee3valid}
                        // required="true"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {showmaindeliveryfee && (
              <div id="divDelivery">
                <hr />
                <h3>Delivery Fees <small> (This is usually used for CC processing charges)</small></h3>
                <div className="formRow threeCol">
                  <Input
                    label="Delivery min flat fee:"
                    name="deliveryMinFlatFee"
                    onChange={onChange}
                    placeholder="0"
                    // required="true"
                    value={showdeliveryminflatfeevalid}
                  />
                  <Input
                    label="Min Order amount:"
                    name="minOrderAmount"
                    onChange={onChange}
                    placeholder="0"
                    value={showminorderamntvalid}
                  // required="true"
                  />
                  <Input
                    label="Max Order amount:"
                    name="maxOrderAmount"
                    onChange={onChange}
                    placeholder="0"
                    value={showmaxorderamntvalid}
                  // required="true"
                  />
                </div>
                <a
                  id="aAddDeliveryFee"
                  className="add-more add-btn add-more-btn"
                  onClick={() => {
                    AddRemoveFees("Delivery");
                  }}
                >
                  {anchorTextDelivery}
                </a>
                {showdeliveryfee && (
                  <div>
                    <div className="formRow threeCol">
                      <Input
                        label="Order amount 1:"
                        name="orderAmount1"
                        onChange={onChange}
                        placeholder="0"
                        value={showorderamnt1valid}
                      // required="true"
                      />
                      <Input
                        label="Order amount 2:"
                        name="orderAmount2"
                        onChange={onChange}
                        placeholder="0"
                        value={showorderamnt2valid}
                      // required="true"
                      />
                      <Input
                        label="Order amount 3:"
                        name="orderAmount3"
                        onChange={onChange}
                        placeholder="0"
                        value={showorderamnt3valid}
                      // required="true"
                      />
                    </div>
                    <div className="formRow threeCol">
                      <Input
                        label="Flat Fee 1:"
                        name="flatFee1"
                        onChange={onChange}
                        placeholder="0"
                        value={showflatfee1valid}
                      // required="true"
                      />
                      <Input
                        label="Flat Fee 2:"
                        name="flatFee2"
                        onChange={onChange}
                        placeholder="0"
                        value={showflatfee2valid}
                      // required="true"
                      />
                      <Input
                        label="Flat Fee 3:"
                        name="flatFee3"
                        onChange={onChange}
                        placeholder="0"
                        value={showflatfee3valid}
                      // required="true"
                      />
                    </div>

                    <div id="divVarFee">
                      <div className="formRow bigLbl">
                        <Input
                          label="Min. Var Fee:"
                          name="minVarFee"
                          onChange={onChange}
                          placeholder="0"
                          value={showminvarfeevalid}
                        // required="true"
                        />
                      </div>
                      <div className="formRow threeCol">
                        <Input
                          label="Var Fee 1:"
                          name="varFee1"
                          onChange={onChange}
                          placeholder="0"
                          value={showvarfee1valid}
                        // required="true"
                        />
                        <Input
                          label="Var Fee 2:"
                          name="varFee2"
                          onChange={onChange}
                          placeholder="0"
                          value={showvarfee2valid}
                        // required="true"
                        />
                        <Input
                          label="Var Fee 3:"
                          name="varFee3"
                          onChange={onChange}
                          placeholder="0"
                          value={showvarfee3valid}
                        // required="true"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {/* <div className="error-msg">{errormsg}</div> */}
          <div className="formButtonMain" style={style}>
            {/* <Button >Save</Button> */}
            {/* <Button type="submit">Next</Button> */}
            <LoadingButton
              // onClick={() => SaveServiceCharge()}
              type="submit"
              loading={loading}
              loadingPosition="end"
              endIcon={loading ? <CheckBoxOutlineBlankIcon color={"#2C394B"} style={{ color: "#2C394B" }} /> : ''}
            >
              <span>Save</span>
            </LoadingButton>
          </div>
        </Form>
      </div>
      <SessionEnd openOrNot={unauthorized} />
    </div>
  );
}
