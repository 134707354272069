import React, { useEffect, useState, useRef } from "react";
import { Input, Form } from "@start-base/react-form-elements";
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Axios from "axios";
import validator from "validator";
import { APIUrl } from "../..";
import { message } from "antd";
import SessionEnd from './SessionEnd';
import PhoneInput from "react-phone-input-2";
// import ReactIntlTelInput from 'react-intl-tel-input-v2'
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function NameAdress(props) {
  const [loading, setLoading] = useState(false);
  const inputRefEmail = useRef(null);
  const inputRefOrderingURL = useRef(null);

  const StateChainId = useLocation().state;

  const [messageApi, contextHolder] = message.useMessage();
  const [inputs, setInputs] = useState({});
  const [showphonevalid, setShowPhoneValid] = useState("");

  const [errormsg, setErrorMsg] = useState("");

  //const [fcs, setFcs] = useState(false);

  const [optionList, setOptionList] = useState([]);
  const [chainList, setChainList] = useState([]);
  const [optionCoutriesList, setOptionCoutriesList] = useState([]);
  const [optionStateList, setOptionStateList] = useState([]);
  const [AllStatesList, setAllStatesList] = useState([]);
  const [style, setStyle] = useState(null)
  const [unauthorized, setUnauthorized] = useState(false)
  const location = useLocation()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState()
  const [newNO, setnewNo] = useState('')

  var RestLocationId = -1;
  if (localStorage.getItem("RestLocationId"))
    RestLocationId = localStorage.getItem("RestLocationId");

  var StateList = [];
  //Get Coutries and States
  const getCS = () => {
    Axios.post(APIUrl + "CountriesAndStates", {
      data: { token: Token },
    })
      .then((response) => {
        const countries = response.data.data.countries;
        const states = response.data.data.states;
        setOptionCoutriesList(countries);
        setAllStatesList(states);
        StateList = states;

        if (RestLocationId != -1) handleUpdate();
        else if (StateChainId) {
          if (StateChainId.SelectedChainId != "") {
            setInputs((prevState) => ({
              ...prevState,
              restaurantChain: StateChainId.SelectedChainId,
            }));
          }
        }
      })
      .catch((e) => {
        console.log(e);
        if (e?.response?.data == "Unauthorized") {
          setUnauthorized(true)
        }
        setOptionCoutriesList([]);
        setAllStatesList([]);
      });


  };

  React.useEffect(() => {
    const restChainId = localStorage.getItem("RestChainId")
    if (restChainId == 33566 || restChainId == 33590) {
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
    }
    if (!JSON.parse(localStorage.getItem("UserDetails")).IsApprovedUser) {
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
    }
  }, [])
  React.useEffect(() => getCS(), []);
  //Get Coutries and States

  // useEffect(() => {
  //   if (StateInfo) {
  //     RestLocationId = StateInfo.RestLocationId;
  //     //handleUpdate();
  //   }
  // }, [StateInfo]);

  var ResellerId = -1,
    Token = "";
  if (localStorage.getItem("UserDetails")) {
    ResellerId = JSON.parse(localStorage.getItem("UserDetails")).ResellerId;
    Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
  }

  const navigate = useNavigate();

  function handleUpdate() {
    Axios.post(APIUrl + "GetLocationById", {
      data: {
        token: Token,
        RestLocationId: RestLocationId,
      },
    })
      .then((response) => {
        if (response.data.status == "s") {
          console.log(response);

          localStorage.setItem(
            "RestaurantEditDetails",
            JSON.stringify(response.data.data.OwnerDetails)
          );

          var RestoDetails = response.data.data.RestoDetails;

          const filtered = StateList.filter((StateList) => {
            return StateList.CountryCode === RestoDetails.RestAddress.Country;
          });
          setOptionStateList(filtered);

          setInputs((prevState) => ({
            ...prevState,
            restaurantChain: RestoDetails.RestChainId,
            restaurantName: RestoDetails.RestName,
            restaurantEmail: RestoDetails.RestEmail,
            restaurantWebsite: RestoDetails.RestWebsite,
            restaurantURL: RestoDetails.RestOrderingURL,
            restaurantTimezone: RestoDetails.TimeZoneId,
            restaurantCountry: RestoDetails.RestAddress.Country,
            street: RestoDetails.RestAddress.StreetNameNumber,
            restaurantState: RestoDetails.RestAddress.State,
            city: RestoDetails.RestAddress.City,
            postalcode: RestoDetails.RestAddress.PostalCode,
          }));
          // setShowPhoneValid(RestoDetails.RestPhone);
          // setPhoneNumber(RestoDetails?.RestPhone?.split('-')[1].split(',')[0])
          // var d = {
          //   "iso2": RestoDetails?.RestPhone?.split(',')[1],
          //   "dialCode": RestoDetails?.RestPhone?.split('-')[0].split('+')[1],
          //   "phone": ""
          // }
          // setCountryCode(d)
          setnewNo(RestoDetails.RestPhone)

          if (RestoDetails.Lat == "" || RestoDetails.Long == "") {
            var RestaurantAdress =
              RestoDetails.RestAddress.StreetNameNumber +
              " " +
              RestoDetails.RestAddress.City +
              " " +
              RestoDetails.RestAddress.PostalCode +
              " " +
              RestoDetails.RestAddress.State +
              " " +
              RestoDetails.RestAddress.Country;
            getLatLong(RestLocationId, RestaurantAdress);
          } else {
            var obj = {
              RestLocationId: RestLocationId,
              RestLattitude: RestoDetails.Lat,
              RestLongitude: RestoDetails.Long,
              route: RestoDetails.RestAddress.StreetNameNumber,
              city: RestoDetails.RestAddress.City,
              postal_code: RestoDetails.RestAddress.PostalCode,
              state: RestoDetails.RestAddress.LookupState,
              country: RestoDetails.RestAddress.Country,
            };
            localStorage.setItem("RestaurantDetails", JSON.stringify(obj));
          }
        }
      })
      .catch((error) => {
        // alert(error.response.data.message);
        if (error?.response?.data == "Unauthorized") {
          setUnauthorized(true)
        }
      });
  }

  const newdata = [];
  newdata.restaurantName = inputs.restaurantName;
  newdata.restaurantEmail = inputs.restaurantEmail;
  newdata.restaurantPhone = newNO;
  // newdata.restaurantPhone = inputs.restaurantPhone;
  newdata.restaurantWebsite = inputs.restaurantWebsite;
  newdata.restaurantURL = inputs.restaurantURL;
  newdata.restaurantCountry = inputs.restaurantCountry;
  newdata.street = inputs.street;
  newdata.restaurantState = inputs.restaurantState;
  newdata.city = inputs.city;
  newdata.postalcode = inputs.postalcode;
  newdata.restaurantTimezone = inputs.restaurantTimezone;
  newdata.restaurantChain = inputs.restaurantChain;

  const onChange = (e) => {
    if (e.target.name == "restaurantPhone") {
      const result = e.target.value.replace(/\D/g, "");
      setShowPhoneValid(result);
    }

    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));

    if (e.target.name == "restaurantCountry") {
      const filtered = AllStatesList.filter((AllStatesList) => {
        return AllStatesList.CountryCode === e.target.value;
      });
      setOptionStateList(filtered);
    }
  };

  function SaveRestInfo(e) {
    e.preventDefault();
    try {
      if (newdata.restaurantEmail) {
      setLoading(true)
      if (newNO?.split('+')[1]?.split(' ')[1].length > 10) {
        messageApi.open({
          type: "error",
          content: "Enter a valid phone number",
        });
        setLoading(false)
        return
      }
   
      if (validator.isEmail(newdata.restaurantEmail)) {
        //if (validator.isMobilePhone(showphonevalid)) {
        Axios.post(APIUrl + "RestNameAddress", {
          data: {
            token: Token,
            RestLocationId: RestLocationId,
            RestChainId: newdata.restaurantChain,
            restAddressName: {
              RestName: newdata.restaurantName,
              TimeZoneId: newdata.restaurantTimezone,
              RestEmail: newdata.restaurantEmail,
              RestPhone: newdata.restaurantPhone,
              RestWebsite: newdata.restaurantWebsite,
              RestOrderingURL: newdata.restaurantURL,
              isAddressUpdate: true,
              Lat: "0",
              Long: "0",
              RestAddress: {
                Country: newdata.restaurantCountry,
                StreetNameNumber: newdata.street,
                State: newdata.restaurantState,
                City: newdata.city,
                PostalCode: newdata.postalcode,
              },
            },
          },
        })
          .then((response) => {
            if (response.data.status == "s") {
              setLoading(false)
              messageApi.open({
                type: "success",
                content: "Name & Adress Info Updated !",
              });

              var RestaurantAdress =
                newdata.street +
                " " +
                newdata.city +
                " " +
                newdata.postalcode +
                " " +
                newdata.restaurantState +
                " " +
                newdata.restaurantCountry;
              getLatLong(response.data.data.RestLocationId, RestaurantAdress);

              localStorage.setItem(
                "RestLocationId",
                response.data.data.RestLocationId
              );
              //navigate("/reseller/setup/owner_personal_info");
              setTimeout(() => {
                navigate("/reseller/setup/owner_personal_info");
                const getChainName = chainList.filter((list) => list.RestChainId == inputs.restaurantChain)
                console.log(getChainName[0].Name);
                localStorage.setItem("RestChainName", getChainName[0].Name)
              }, 500);
            } else if (response.data.status == "f") {
              setLoading(false)
              messageApi.open({
                type: "warning",
                content: response.data.message,
              });
              if (response.data.message == "Ordering link must be unique")
                inputRefOrderingURL.current.focus();
              //setErrorMsg(response.data.message);
            }
          })
          .catch((error) => {
            // alert(error.response.data);
            setLoading(false)
            if (error?.response?.data == "Unauthorized") {
              setUnauthorized(true)
            } else {
              messageApi.open({
                type: "warning",
                content: error?.response?.data,
              });
            }
          });
        // } else setErrorMsg("Invalid phone number");
      } else {
        inputRefEmail?.current.focus();
        setLoading(false)
        messageApi.open({
          type: "warning",
          content: "Email is not valid",
        });
      }
      setLoading(false)
    } 
    } catch (error) {
      console.log(error);
    }
    //setErrorMsg("Email is not valid");
  }

  const getTZ = () => {
    Axios.post(APIUrl + "getTimeZones", {
      data: { token: Token },
    })
      .then((response) => {
        setOptionList(response.data.data.listTimeZones);
      })
      .catch((e) => {
        setOptionList([]);
        if (e?.response?.data == "Unauthorized") {
          setUnauthorized(true)
        }
      });
  };

  React.useEffect(() => getTZ(), []);

  const getRC = () => {
    Axios.post(APIUrl + "GetRestChainByReseller", {
      data: { token: Token, ResellerId: ResellerId }, //localStorage.getItem("ResellerId")
    })
      .then((response) => {
        const chains = response.data.data.restChains;

        const filtered = chains.filter((chains) => {
          return chains.IsActive === true;
        });
        console.log(filtered);
        const filtered1 = filtered.filter((chains) => {
          return chains.RestChainId != 33566
        })
        const filtered2 = filtered1.filter((chains) => {
          return chains.RestChainId != 33590
        })
        setChainList(filtered2);
      })
      .catch((e) => {
        setChainList([]);
        if (e?.response?.data == "Unauthorized") {
          setUnauthorized(true)
        }
      });
  };
  React.useEffect(() => getRC(), []);

  function getLatLong(RestaurantLocationId, RestaurantAdress) {
    setDefaults({
      key: "AIzaSyAG9hS8iXTNp6l--cpiTTNNiqUFPKtqgPU",
      language: "en",
      region: "es",
    });

    setKey("AIzaSyAG9hS8iXTNp6l--cpiTTNNiqUFPKtqgPU");
    setLanguage("en");
    setRegion("es");

    // Get latitude & longitude from address.
    fromAddress(RestaurantAdress)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;

        var obj = {
          RestLocationId: RestaurantLocationId,
          RestLattitude: parseFloat(lat.toFixed(6)),
          RestLongitude: parseFloat(lng.toFixed(6)),
          route: newdata.street,
          city: newdata.city,
          postal_code: newdata.postalcode,
          state: newdata.restaurantState,
          country: newdata.restaurantCountry,
        };
        localStorage.setItem("RestaurantDetails", JSON.stringify(obj));
      })
      .catch(({ error }) => {
        var obj = {
          RestLocationId: RestaurantLocationId,
          RestLattitude: -1,
          RestLongitude: -1,
          route: newdata.street,
          city: newdata.city,
          postal_code: newdata.postalcode,
          state: newdata.restaurantState,
          country: newdata.restaurantCountry,
        };
        localStorage.setItem("RestaurantDetails", JSON.stringify(obj));
      });
  }

  return (
    <div className="contentForm">
      {contextHolder}
      <div className="formHeading">
        <Button
          onClick={() => { navigate("/reseller/restaurant/list"); }}
        >
          Back
        </Button>
        <h2>What's your restaurant address?</h2>
      </div>
      <Form onSubmit={SaveRestInfo}>
        <div className="Form_root_Inner" style={style}>
          <div className="RestChain-field">
            <div className="InputSelect">
              <label>Restaurant Chain *</label>
              <select
                required="true"
                name="restaurantChain"
                onChange={onChange}
                value={inputs.restaurantChain}
              >
                <option value="">Choose Chain</option>
                {chainList.map((item) => (
                  <option key={item.RestChainId} value={item.RestChainId}>
                    {item.Name}
                  </option>
                ))}
              </select>
            </div>
            <Button
              onClick={() => {
                navigate("/reseller/setup/chaindts", {
                  state: { RestChainDts: -1 },
                });
              }}
            >
              Add Chain
            </Button>
          </div>
          <Input
            label="Restaurant Name *"
            name="restaurantName"
            onChange={onChange}
            value={inputs.restaurantName}
            placeholder="Enter Name..."
            required="true"
          />
          <div className="formRow halfField ">
            <Input
              label="Restaurant Email *"
              name="restaurantEmail"
              onChange={onChange}
              value={inputs.restaurantEmail}
              placeholder="Enter Email..."
              required="true"
              type="email"
              //autoFocus={fcs}
              ref={inputRefEmail}
            />
            {/* <Input
              label="Restaurant Phone Number *"
              name="restaurantPhone"
              onChange={onChange}
              //value={inputs.restaurantPhone}
              placeholder="Enter Phone..."
              required="true"
              value={showphonevalid}
            /> */}
            <div className="ph-num-field halfField ">
              <label>
                Phone number</label>
              <PhoneInput
                country={'us'}
                // value={PhoneNumber}
                // value={phoneNumber}
                // onChange={(value) => {console.log(value); setPhoneNumber(value)}}
                value={newNO?.split('+')[1]?.split(' ')[0] + "" + newNO?.split('+')[1]?.split(' ')[1]}
                onChange={(value, country, event, formattedValue) => { setnewNo("+" + country.dialCode + " " + value.slice(country.dialCode.length)); }}
                inputProps={{ required: true }}
              ></PhoneInput>
            </div>

            {/* <div className="phonenocode">
              <label>Phone number</label>
              <ReactIntlTelInput
                intlTelOpts={{ preferredCountries: ['us'], }}
                value={countryCode}
                name="phone"
                onChange={async (phone) => { setCountryCode(phone); }}
              />
              <span className="countryCode" >+{countryCode?.dialCode}</span>
              <input
                type="tel"
                name="phoneNumber"
                className="form-control"
                required
                value={phoneNumber}
                onChange={async (e) => { setPhoneNumber(e.target.value) }}
                onFocus={() => { }}
              />
            </div> */}
          </div>
          <Input
            //label="Restaurant Website"
            label="Restaurant Website"
            name="restaurantWebsite"
            onChange={onChange}
            value={inputs.restaurantWebsite}
            placeholder="Enter website..."
          // required="true"
          />

          <div className="InputOnlineOrdering">
            <label className="OnlineOrderingLabel">
              {/* Choose Online Ordering URL */}
              Ordering Page *
              <b>This URL will be used as online ordering link</b>
            </label>
            <Input
              label="https://orderonlinemenu.com/"
              name="restaurantURL"
              onChange={onChange}
              value={inputs.restaurantURL}
              required="true"
              ref={inputRefOrderingURL}
            />
          </div>

          <div className="InputSelect">
            <label>Timezone *</label>
            <select
              value={inputs.restaurantTimezone}
              required="true"
              name="restaurantTimezone"
              onChange={onChange}
            >
              <option value="">Choose Timezone</option>
              {optionList.map((item) => (
                <option key={item.TimezoneId} value={item.TimezoneId}>
                  {item.TimeZoneName}
                </option>
              ))}
            </select>
          </div>

          <div className="InputSelect">
            <label>Country *</label>
            <select
              required="true"
              name="restaurantCountry"
              onChange={onChange}
              value={inputs.restaurantCountry}
            >
              <option value="">Choose Country</option>
              {optionCoutriesList.map((item) => (
                <option key={item.CountryCode} value={item.CountryCode}>
                  {item.CountryCode}
                </option>
              ))}
            </select>
          </div>
          <Input
            label="Street Name & Number *"
            name="street"
            onChange={onChange}
            value={inputs.street}
            placeholder="Enter Street Name & Number..."
            required="true"
          />
          <div className="InputSelect">
            <label>State *</label>
            <select
              required="true"
              name="restaurantState"
              onChange={onChange}
              value={inputs.restaurantState}
            >
              <option value="">Choose State</option>
              {optionStateList.map((item) => (
                <option key={item.State} value={item.State}>
                  {item.State}
                </option>
              ))}
            </select>
          </div>
          <div className="formRow">
            <Input
              label="City *"
              name="city"
              onChange={onChange}
              value={inputs.city}
              placeholder="Enter City..."
              required="true"
            />
            <Input
              label="Postal Code *"
              name="postalcode"
              onChange={onChange}
              value={inputs.postalcode}
              placeholder="Enter Postal Code..."
              required="true"
            />
          </div>
        </div>
        <div className="error-msg">{errormsg}</div>
        <div className="formButtonMain" style={style}>
          {/* <Button
            // type="submit"
            onClick={() => SaveRestInfo()}
          >
            Next
          </Button> */}
          <LoadingButton
            // onClick={() => SaveRestInfo()}
            type="submit"
            loading={loading}
            loadingPosition="end"
            endIcon={loading ? <CheckBoxOutlineBlankIcon color={"#2C394B"} style={{ color: "#2C394B" }} /> : ''}
          >
            <span>Next</span>
          </LoadingButton>
        </div>
      </Form>
      <SessionEnd openOrNot={unauthorized} />
    </div>
  );
}
