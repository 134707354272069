import React, { useEffect, useState } from "react";
import { Input, PasswordInput, Form } from "@start-base/react-form-elements";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import LocationMap from "./LocationMap";
import Axios from "axios";
import { APIUrl } from "../..";
import SessionEnd from './SessionEnd';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function Location() {
  const [errormsg, setErrorMsg] = useState("");
  const [style, setStyle] = useState(null)
  const navigate = useNavigate();
  const [unauthorized, setUnauthorized] = useState(false)
  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({});
  const onChange = (e) => {
    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));
  };

  useEffect(() => {
    const restChainId = localStorage.getItem("RestChainId")
    if (!localStorage.getItem("RestLocationId")) {
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
    }
    if (restChainId == 33566 || restChainId == 33590) {
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
    }
    if (!JSON.parse(localStorage.getItem("UserDetails")).IsApprovedUser) {
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
    }
  }, [])

  function UpdateLatLong() {
    setLoading(true)
    var RestLocationId = -1,
      RestLattitude = 0,
      RestLongitude = 0,
      route = "",
      city = "",
      postal_code = "",
      state = "",
      country = "";

    if (localStorage.getItem("RestaurantNewDetails")) {
      var RestaurantDetails = JSON.parse(
        localStorage.getItem("RestaurantNewDetails")
      );
    } else if (localStorage.getItem("RestaurantDetails")) {
      var RestaurantDetails = JSON.parse(
        localStorage.getItem("RestaurantDetails")
      );
    } else {
      alert("Please fill all information from Name Adress section first.");
      return false;
    }

    var Token = "";
    if (localStorage.getItem("UserDetails"))
      Token = JSON.parse(localStorage.getItem("UserDetails")).Token;

    RestLocationId = RestaurantDetails.RestLocationId;
    RestLattitude = RestaurantDetails.RestLattitude;
    RestLongitude = RestaurantDetails.RestLongitude;
    route = RestaurantDetails.route;
    city = RestaurantDetails.city;
    postal_code = RestaurantDetails.postal_code;
    state = RestaurantDetails.state;
    country = RestaurantDetails.country;

    Axios.post(APIUrl + "RestNameAddress", {
      data: {
        token: Token,
        RestLocationId: RestLocationId,
        RestChainId: -1,
        restAddressName: {
          RestName: "",
          TimeZoneId: -1,
          RestEmail: "",
          RestPhone: "",
          RestWebsite: "",
          RestOrderingURL: "",
          isAddressUpdate: false,
          Lat: RestLattitude,
          Long: RestLongitude,
          RestAddress: {
            Country: country,
            StreetNameNumber: route,
            State: state,
            City: city,
            PostalCode: postal_code,
          },
        },
      },
    })
      .then((response) => {
        if (response.data.status == "s") {
          if (localStorage.getItem("RestaurantNewDetails")) {
            var obj = {
              RestLocationId: JSON.parse(
                localStorage.getItem("RestaurantNewDetails")
              ).RestLocationId,
              RestLattitude: RestLattitude,
              RestLongitude: RestLongitude,
              route: route,
              city: city,
              postal_code: postal_code,
              state: state,
              country: country,
            };
            localStorage.setItem("RestaurantDetails", JSON.stringify(obj));
            localStorage.removeItem("RestaurantNewDetails");
          }

          navigate("/reseller/setup/online_payment");
          setLoading(false)
        } else if (response.data.status == "f")
          setErrorMsg(response.data.message);
      })
      .catch((error) => {
        setLoading(false)
        // alert(error.message);
        if (error.response.data == "Unauthorized") {
          setUnauthorized(true)
        }
      });
      setLoading(false)
  }

  return (
    <div className="mapDiv">
      <div className="mapWrapper">
        {localStorage.getItem("RestaurantDetails") && <LocationMap />}
      </div>
      <div className="smlwindowHeight">
        <div
          className="contentForm smallPageWindow mapPopup"
        //style={{backgroundColor: "lightblue"}}
        >
          <div className="formHeading" >
            <div className="btnwithText">
              <Button
                onClick={() => {
                  //getLatLong()
                  if (localStorage.getItem("RestLocationId")) {
                    navigate("/reseller/setup/owner_personal_info"); 
                  } else {
                    navigate("/reseller/restaurant/list");
                  }
                }}
              >
                Back
              </Button>
              <h2>Let your customer know your exact location</h2>
            </div>
            <div className="error-msg">{errormsg}</div>
            {/* <Button
              style={style}
              className="black-colorBtn"
              onClick={() => {
                UpdateLatLong();
              }}
            >
              Save
            </Button> */}
            <LoadingButton
              className="black-colorBtn"
              style={style}
              onClick={() => UpdateLatLong()}
              loading={loading}
              loadingPosition="end"
              endIcon={loading ? <CheckBoxOutlineBlankIcon color={"#2C394B"} style={{ color: "#2C394B" }} /> : ''}
            >
              <span>Save</span>
            </LoadingButton>
          </div>
          <Form>
            <div className="contentFormBody" style={style}>
              <div style={{ textAlign: "center" }}>
                <a
                  onClick={() => {
                    navigate("/reseller/setup/name_adress");
                  }}
                >
                  Do you want to change your restaurant address?
                </a>
              </div>
            </div>
          </Form>
        </div>
      </div>{" "}
      <SessionEnd openOrNot={unauthorized} />
    </div>
  );
}
