import React, { useEffect, useState } from "react";
import { Input, TextArea, Form } from "@start-base/react-form-elements";
import { Button } from "@mui/material";
import { LiaEnvelope } from "react-icons/lia";
import { LiaPhoneSolid } from "react-icons/lia";
import Axios from "axios";
import { message } from "antd";
import { APIUrl } from "../..";
import SessionEnd from '../Setup/SessionEnd';
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import PhoneInput from "react-phone-input-2";

export default function ContactUs() {
  const [unauthorized, setUnauthorized] = useState(false)
  const [style, setStyle] = useState({})
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState()
  const [newNO, setnewNo] = useState('')
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    var isClass = "",
      IsSuperAdmin = false;
    if (localStorage.getItem("UserDetails")) {
      var IsApprovedUser = JSON.parse(
        localStorage.getItem("UserDetails")
      ).IsApprovedUser;

      IsSuperAdmin = JSON.parse(localStorage.getItem("UserDetails")).IsSuperAdmin;
      if (IsApprovedUser === false) setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      });
    }
  }, [])
  function ContactUs(e) {
    e.preventDefault();
    if (newNO?.split('+')[1]?.split(' ')[1].length > 10) {
      messageApi.open({
        type: "error",
        content: "Enter a valid phone number",
      });
      return
    }
    var Token = "";
    if (localStorage.getItem("UserDetails"))
      Token = JSON.parse(localStorage.getItem("UserDetails")).Token;

    Axios.post(APIUrl + "ContactUs", {
      data: {
        token: Token,
        contactUs: {
          Name: newdata.name,
          RestName: newdata.restname,
          Email: newdata.email,
          Phone: newNO,
          Message: newdata.reachingout,
        },
      },
    })
      .then((response) => {
        if (response.data.status == "s") {
          alert("Email Sent");
          setInputs({});
        }
      })
      .catch((error) => {
        // alert(error.response.data);
        if (error.response.data == "Unauthorized") {
          setUnauthorized(true)
        }
      });
  }

  const [inputs, setInputs] = useState({});
  const [showphonevalid, setShowPhoneValid] = useState("");

  const newdata = [];
  newdata.name = inputs.name;
  newdata.restname = inputs.restname;
  newdata.email = inputs.email;
  newdata.reachingout = inputs.reachingout;

  const onChange = (e) => {
    if (e.target.name == "phone") {
      const result = e.target.value.replace(/\D/g, "");
      setShowPhoneValid(result);
    }

    const { name, value, type } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));
  };

  return (
    <div>
      <div className="contentForm form-container">
        <div className="formHeading">
          <h2>Send Us Email</h2>
        </div>{contextHolder}
        <Form onSubmit={ContactUs} style={style}>
          <div className="Form_root_Inner">
            <div className="formRow">
              <Input
                label="Name"
                name="name"
                onChange={onChange}
                value={inputs.name}
                required="true"
              />
              <Input
                label="Restaurant Name"
                name="restname"
                onChange={onChange}
                value={inputs.restname}
                required="true"
              />
            </div>
            <div className="formRow">
              <Input
                label="Email"
                name="email"
                onChange={onChange}
                value={inputs.email}
                required="true"
                type="email"
              />
              {/* <Input
                label="Phone"
                name="phone"
                onChange={onChange}
                value={showphonevalid}
                required="true"
              /> */}
              <div className="ph-num-field halfField">
                <label>
                  Phone number</label>
                <PhoneInput
                  country={'us'}
                  // value={PhoneNumber}
                  // value={showphonevalid}
                  // onChange={onChange}
                  value={newNO?.split('+')[1]?.split(' ')[0] + "" + newNO?.split('+')[1]?.split(' ')[1]}
                  onChange={(value, country, event, formattedValue) => { setnewNo("+" + country.dialCode + " " + value.slice(country.dialCode.length)); }}
                  inputProps={{ required: true }}
                ></PhoneInput>
              </div>
              {/* <div className="phonenocode">
                <label>Phone</label>
                <ReactIntlTelInput
                  intlTelOpts={{ preferredCountries: ['us'] }}
                  value={countryCode}
                  name="phone"
                  onChange={async (phone) => { setCountryCode(phone); console.log(phone); }}
                />
                <span className="countryCode" >+{countryCode?.dialCode}</span>
                <input
                  type="tel"
                  name="phoneNumber"
                  className="form-control"
                  required
                  value={phoneNumber}
                  onChange={async (e) => { setPhoneNumber(e.target.value) }}
                  onFocus={() => { }}
                />
              </div> */}
            </div>
            <TextArea
              rows="7"
              label="Let us know why you are reaching out"
              name="reachingout"
              onChange={onChange}
              value={inputs.reachingout}
              required="true"
            ></TextArea>
          </div>
          <div className="formButtonMain btnRow">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>

      <div className="contactUsDetailsInner">

        <div className="contactDetailsSub">
          <h4>Customer Support</h4>
          <div className="contacteEmailPhone">
            <div className="contactIconEmail"><LiaEnvelope size={20} /></div>
            <span className="contactSpan"><a href="mailto:support@imenu360.com">support@imenu360.com</a></span>
          </div>
          <div className="contacteEmailPhone">
            <div className="contactIconPhone"><LiaPhoneSolid size={20} /></div>
            <span className="contactSpan"><a href="tel:86-629-9646">(866)629-9646 Ext. 1</a></span>
          </div>
        </div>

        <div className="contactDetailsSub">
          <h4>Contact Sales</h4>
          <div className="contacteEmailPhone">
            <div className="contactIconEmail"><LiaEnvelope size={20} /></div>
            <span className="contactSpan"><a href="mailto:sales@imenu360.com">sales@imenu360.com</a></span>
          </div>
          <div className="contacteEmailPhone">
            <div className="contactIconPhone"><LiaPhoneSolid size={20} /></div>
            <span className="contactSpan"><a href="tel:86-629-9646">(866)629-9646 Ext. 2</a></span>
          </div>
        </div>

        <div className="contactDetailsSub">
          <h4>Billing</h4>
          <div className="contacteEmailPhone">
            <div className="contactIconEmail"><LiaEnvelope size={20} /></div>
            <span className="contactSpan"><a href="mailto:billing@imenu360.com">billing@imenu360.com</a></span>
          </div>
          <div className="contacteEmailPhone">
            <div className="contactIconPhone"><LiaPhoneSolid size={20} /></div>
            <span className="contactSpan"><a href="tel:86-629-9646">(866)629-9646 Ext. 4</a></span>
          </div>
        </div>
      </div>
      {/* <div className="contentForm form-container contactInfo">
        <div class="contact-block">
          <h1>Customer Support</h1>
          <div class="icon emailIcon">
            <a href="mailto:support@imenu360.com">
              <span>
                <LiaEnvelope />{" "}
              </span>
              support@imenu360.com
            </a>
          </div>

          <div class="icon callIcon">
            <a href="tel:86-629-9646">
              <span>
                <LiaPhoneSolid />{" "}
              </span>
              (866)629-9646 Ext. 1
            </a>
          </div>
        </div>
        <div class="contact-block">
          <h1>Contact Sales</h1>
          <div class="icon emailIcon">
            <a href="mailto:sales@imenu360.com">
              <span>
                <LiaEnvelope />{" "}
              </span>
              sales@imenu360.com
            </a>
          </div>

          <div class="icon callIcon">
            <a href="tel:86-629-9646">
              <span>
                <LiaPhoneSolid />{" "}
              </span>
              (866)629-9646 Ext. 2
            </a>
          </div>
        </div>
        <div class="contact-block">
          <h1>Billing</h1>
          <div class="icon emailIcon">
            <a href="mailto:billing@imenu360.com">
              <span>
                <LiaEnvelope />{" "}
              </span>
              billing@imenu360.com
            </a>
          </div>
          <div class="icon callIcon">
            <a href="tel:86-629-9646">
              <span>
                <LiaPhoneSolid />{" "}
              </span>
              (866)629-9646 Ext. 4
            </a>
          </div>
        </div>
      </div> */}
      <SessionEnd openOrNot={unauthorized} />
    </div>
  );
}
