import React,{ useContext} from 'react'
import { Outlet } from 'react-router-dom';
import { LevelContext } from '../../App';

function CommissionContent() {
    var isClass = "";
    if (localStorage.getItem("UserDetails")){
    var IsApprovedUser = JSON.parse(
        localStorage.getItem("UserDetails")
      ).IsApprovedUser;
      if(IsApprovedUser===false)
      isClass="form-overlay";
    }
    //const {isapproveduser, setIsApprovedUser} = useContext(LevelContext) 

    return (
        <div id="divCommision" className='sidebarOuter'>
            <div className='sidebarInner'>
                <div id="submenuHeading">
                    <div class="sidebar-item open">
                        <div id="menuHeading" class="sidebar-title toggle-btn">
                            <span></span>
                        </div>
                        <div class="sidebar-content nosetup">
                            <a><a href="#" class="sidebar-item plain">Coming Soon</a></a>
                            </div>
                    </div>
                </div></div>

            <div id="divSub" className='RightMenu main'>
            <div className={isClass}>
                <Outlet></Outlet>
                </div>
            </div>
        </div>
    )
}
export default CommissionContent