import Login from './Components/Login';
import Sidebar from './Components/Sidebar';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import SetupContent from './Components/Setup/SetupContent';

import OwnerPersonalInfo from './Components/Setup/OwnerPersonalInfo';
import NameAdress from './Components/Setup/NameAdress';
import Location from './Components/Setup/Location';
import AccountConfirmation from './Components/Setup/AccountConfirmation';

import Pickup from './Components/Setup/Pickup';
import Delivery from './Components/Setup/Delivery';
import Dine_In from './Components/Setup/Dine_In';
import Curbside from './Components/Setup/Curbside';
import Opening_Hours from './Components/Setup/OpeningHours';
import ManageSchedule from './Components/Setup/ManageSchedule';

import Taxation from './Components/Setup/Taxation';
import ServiceCharges from './Components/Setup/ServiceCharges';
import PaymentMethods from './Components/Setup/PaymentMethods';
import OnlinePayment from './Components/Setup/OnlinePayment';

//import ReceiveOrders from './Components/Setup/ReceiveOrders';
import ReceiveOrders from './Components/Setup/ReceiveOrder';
import MenuSetup from './Components/Setup/MenuSetup';
import AlertCall from './Components/Setup/AlertCall';

import Restaurant_AddUpdate from './Components/Setup/Restaurant_AddUpdate';

import RestaurantContent from './Components/Restaurant/RestaurantContent';

import Restaurantlist from './Components/Restaurant/Restaurantlist';
import RestaurantReports from './Components/Restaurant/RestaurantReports';

import ActivateServiceContent from './Components/ActivateServices/ActivateServiceContent';

import DeliveryIntegration from './Components/ActivateServices/DeliveryIntegration';
import POSIntegration from './Components/ActivateServices/POSIntegration';
import Review from './Components/ActivateServices/Review';
import NewWebsites from './Components/ActivateServices/NewWebsites';
import NewMobileApps from './Components/ActivateServices/NewMobileApps';
import EmailMarketing from './Components/ActivateServices/EmailMarketing';

import CommissionContent from './Components/Commission/CommissionContent';

import SupportContent from './Components/Support/SupportContent';
import ContactUs from './Components/Support/ContactUs'
import Delivery_Zone from './Components/Setup/Delivery_Zone';
import Confirmation from './Components/Setup/Confirmation';
import ScheduleCall from './Components/ScheduleCall';
import { useState,createContext } from 'react';
import RestaurantChainList from './Components/Setup/RestaurantChainList';
import AddChain from './Components/Setup/AddChain';
import ApprovalContent from './Components/Approval/ApprovalContent';
import PendingApproval from './Components/Approval/PendingApproval';
import Commission from './Components/Commission/Commission';
import SidebarContent from './Components/SidebarContent';
import ProfileUpdate from './Components/ProfileUpdate/ProfileUpdate';
import TestSidebar from './Components/Test';
import Menu from './Components/Setup/Menu';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />
  },
  {path:"/reseller/test",element:<TestSidebar/> },
  {
    path: "/reseller",
    element: <Sidebar />,
    // children: [{
    //   path: "/reseller/setup",
    //   element: <SetupContent />,
      children: 
      [{
        path: "/reseller/setup/owner_personal_info",
        element: <OwnerPersonalInfo />
      },
      {
        path: "/reseller/setup/name_adress",
        element: <NameAdress />
      },
      {
        path: "/reseller/setup/location",
        element: <Location />
      },
      {
        path: "/reseller/setup/account_confirmation",
        element: <AccountConfirmation />
      },
    
      {
        path: "/reseller/setup/pickup",
        element: <Pickup />
      },
      {
        path: "/reseller/setup/delivery",
        element: <Delivery />
      },
      {
        path: "/reseller/setup/dine_in",
        element: <Dine_In />
      },
      {
        path: "/reseller/setup/curbside",
        element: <Curbside />
      },
      {
        path: "/reseller/setup/opening_hours",
        element: <Opening_Hours />
      },
      // {
      //   path: "/reseller/setup/manage_schedule",
      //   element: <ManageSchedule />
      // },
    
      {
        path: "/reseller/setup/taxation",
        element: <Taxation />
      },
      {
        path: "/reseller/setup/service_charges",
        element: <ServiceCharges />
      },
      // {
      //   path: "/reseller/setup/payment_methods",
      //   element: <PaymentMethods />
      // },
      {
        path: "/reseller/setup/online_payment",
        element: <OnlinePayment />
      },
      {
        path: "/reseller/setup/menu",
        element: <Menu/> 
      },
    
      {
        path: "/reseller/setup/receive_orders",
        element: <ReceiveOrders />
      },
      // {
      //   path: "/reseller/setup/menu_setup",
      //   element: <MenuSetup />
      // },
      // {
      //   path: "/reseller/setup/alert_call",
      //   element: <AlertCall />
      // },

      {
        path: "/reseller/setup/rest_add_update",
        element: <Restaurant_AddUpdate />
      },
      {
        path: "/reseller/setup/delivery_zone",
        element: <Delivery_Zone/>
      },
      {
        path: "/reseller/setup/confirmation",
        element: <Confirmation />
      },
      {
        path: "/reseller/setup/chainlist",
        element: <RestaurantChainList/>
      },
      {
        path: "/reseller/setup/chaindts",
        element: <AddChain/>
      },
      {
        path: "/reseller/restaurant/list",
        element: <Restaurantlist />
      },
      {
        path: "/reseller/restaurant/reports",
        element: <RestaurantReports />
      },{
        path: "/reseller/activateservice/delivery_integration",
        element: <DeliveryIntegration />
      },
      {
        path: "/reseller/activateservice/POS_integration",
        element: <POSIntegration />
      },
      {
        path: "/reseller/activateservice/review",
        element: <Review />
      },
      {
        path: "/reseller/activateservice/new_websites",
        element: <NewWebsites />
      },
      {
        path: "/reseller/activateservice/new_mobile_apps",
        element: <NewMobileApps />
      },
      {
        path: "/reseller/activateservice/email_marketing",
        element: <EmailMarketing />
      },
      {
        path: "/reseller/commission/commission",
        element: <Commission/>
      },
      {
        path: "/reseller/support/contact_us",
        element: <ContactUs/>
      },
      {
        path: "/reseller/approval/pending_approval",
        element: <PendingApproval/>
      },
      {path:"/reseller/profileupdate",element:<ProfileUpdate/>},
      {
        path: "/reseller/schedulecall",
        element: <ScheduleCall/>
      }
      // ,
      // {
      //   path: "/reseller/setup/testMap",
      //   element: <TestMap/>
      // }
    ]
    },

    {
      path: "/reseller/restaurant",
      element: <RestaurantContent />,
      children: 
      [{
        path: "/reseller/restaurant/list",
        element: <Restaurantlist />
      },
      {
        path: "/reseller/restaurant/reports",
        element: <RestaurantReports />
      }]
    },
  
    {
      path: "/reseller/activateservice",
      element: <ActivateServiceContent />,
      children: 
      [{
        path: "/reseller/activateservice/delivery_integration",
        element: <DeliveryIntegration />
      },
      {
        path: "/reseller/activateservice/POS_integration",
        element: <POSIntegration />
      },
      {
        path: "/reseller/activateservice/review",
        element: <Review />
      },
      {
        path: "/reseller/activateservice/new_websites",
        element: <NewWebsites />
      },
      {
        path: "/reseller/activateservice/new_mobile_apps",
        element: <NewMobileApps />
      },
      {
        path: "/reseller/activateservice/email_marketing",
        element: <EmailMarketing />
      }]
     },

     {
      path: "/reseller/commission",
      element: <CommissionContent />,
      children: 
     [{
       path: "/reseller/commission/commission",
       element: <Commission/>
     }]
    },

    {
     path: "/reseller/support",
     element: <SupportContent/>,
     children: 
     [{
       path: "/reseller/support/contact_us",
       element: <ContactUs/>
     }]
   },

   {
    path: "/reseller/approval",
    element: <ApprovalContent/>,
    children: 
    [{
      path: "/reseller/approval/pending_approval",
      element: <PendingApproval/>
    }]
  }
  ]
//   }
// ]
)
export const LevelContext = createContext("");
function App() {
  //const [isapproveduser, setIsApprovedUser]= useState("")
  return (
    //<LevelContext.Provider value={{isapproveduser, setIsApprovedUser}}>
        <RouterProvider router={router} />
    //</LevelContext.Provider>
  );
}

export default App;