import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import { Deblur } from "@mui/icons-material";

class LocationMap extends Component {

  state = {
    markers: [
      {
        name: "Current position",
        position: {
          lat:
            localStorage.getItem("RestaurantDetails") === undefined
              ? -1
              : JSON.parse(localStorage.getItem("RestaurantDetails"))
                  .RestLattitude,
          lng:
            localStorage.getItem("RestaurantDetails") === undefined
              ? -1
              : JSON.parse(localStorage.getItem("RestaurantDetails"))
                  .RestLongitude,
        },
      },
    ],
  };

  onMarkerDragEnd = (coord, index, t, map) => {
    
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setDefaults({
      key: "AIzaSyAG9hS8iXTNp6l--cpiTTNNiqUFPKtqgPU", // Your API key here.
      language: "en", // Default language for responses.
      region: "es", // Default region for responses.
    });

    var latlng = parseFloat(lat.toFixed(6)) + "," + parseFloat(lng.toFixed(6));
    geocode(RequestType.LATLNG, latlng, {
      location_type: "ROOFTOP", // Override location type filter for this request.
      enable_address_descriptor: true, // Include address descriptor in response.
    })
      .then(({ results }) => {
        const address = results[0].formatted_address;
        const { city, state, country, postal_code, route } =
          results[0].address_components.reduce((acc, component) => {
            if (component.types.includes("locality"))
              acc.city = component.long_name ? component.long_name : "";
            else if (component.types.includes("administrative_area_level_1"))
              acc.state = component.long_name ? component.long_name : "";
            else if (component.types.includes("country"))
              acc.country = component.long_name ? component.long_name : "";
            else if (component.types.includes("postal_code"))
              acc.postal_code = component.long_name ? component.long_name : "";
            else if (component.types.includes("route"))
              acc.route = component.long_name ? component.long_name : "";
            return acc;
          }, {});

        var obj = {
          RestLocationId: JSON.parse(localStorage.getItem("RestaurantDetails"))
            .RestLocationId,
          RestLattitude: parseFloat(lat.toFixed(6)),
          RestLongitude: parseFloat(lng.toFixed(6)),
          route: route,
          city: city,
          postal_code: postal_code,
          state: state,
          country: country,
        };
        
        localStorage.setItem("RestaurantNewDetails", JSON.stringify(obj));
      })
      .catch(console.error);

    this.setState((prevState) => {
      const markers = [...this.state.markers];
      markers[index] = { ...markers[index], position: { lat, lng } };
      return { markers };
    });
  };

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        initialCenter={{
          lat:
            localStorage.getItem("RestaurantDetails") === undefined
              ? -1
              : JSON.parse(localStorage.getItem("RestaurantDetails"))
                  .RestLattitude - 0.015,
          lng:
            localStorage.getItem("RestaurantDetails") === undefined
              ? -1
              : JSON.parse(localStorage.getItem("RestaurantDetails"))
                  .RestLongitude - 0.0005,
        }}
      >
        {this.state.markers.map((marker, index) => (
          <Marker
            position={marker.position}
            draggable={true}
            onDragend={(t, map, coord) =>
              this.onMarkerDragEnd(coord, index, t, map)
            }
            name={marker.name}
          />
        ))}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAG9hS8iXTNp6l--cpiTTNNiqUFPKtqgPU",
})(LocationMap);
