import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, { useState } from "react";
import Axios from "axios";
import { APIUrl } from "../..";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Button } from "@mui/material";
import SessionEnd from "../Setup/SessionEnd";

export default function PendingApproval() {
  const [messageApi, contextHolder] = message.useMessage();
  const [unauthorized, setUnauthorized] = useState(false)
  const [approveList, setApproveList] = useState([])
  const navigate = useNavigate();

  var ResellerId = -1,
    Token = "";
  if (localStorage.getItem("UserDetails")) {
    ResellerId = JSON.parse(localStorage.getItem("UserDetails")).ResellerId;
    Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
  }

  const [rowData, setRowData] = useState([]);

  const [columnDefs, setColumnDefs] = useState([
    { headerName: "Name", field: "Name", width: 300 },
    { headerName: "Email", field: "UserName", width: 300 },
    { headerName: "Phone", field: "Phone", width: 250 },
    {
      headerName: "Action",
      width: 200,
      cellRenderer: (params) => (
        <div>
          <Button
            className="grid-action-btn grid-dark-btn"
            onClick={() =>
              approveReseller(params.data.UserId, params.data.Name)
            }
          >
            Approve Now
          </Button>
        </div>
      ),
    },
  ]);

  const getResellerList = () => {
    Axios.post(APIUrl + "GetUnApprovedReseller", {
      data: {
        token: Token,
      },
    })
      .then((response) => {
        setRowData(response.data.data.listResellers);
        setApproveList(response.data.data.listResellers)
      })
      .catch((e) => {
        if (e.response.data == "Unauthorized") {
          setUnauthorized(true)
        }
        setRowData([]);
      });
  };
  React.useEffect(() => getResellerList(), []);

  function approveReseller(ResellerId, Name) {
    confirmAlert({
      title: "Are you sure to approve reseller - " + Name + "?",
      overlayClassName: "alert-popup",
      //message: "Are you sure to approve reseller - " + Name + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            Axios.post(APIUrl + "ApproveReseller", {
              data: {
                token: Token,
                ResellerId: ResellerId,
              },
            })
              .then((response) => {
                var tp = "success";
                if (response.data.status == "f") tp = "failure";

                messageApi.open({
                  type: tp,
                  content: response.data.message,
                });

                getResellerList();
              })
              .catch((e) => {
                if (e.response.data == "Unauthorized") {
                  setUnauthorized(true)
                } else {
                  messageApi.open({
                    type: "warning",
                    content: "Please try again.",
                  });
                }
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  return (
    <div className="contentForm chainList-wrapper">
      {contextHolder}
      <div className="gridHeading">
        <h2>Pending Approvals for Resellers</h2>
      </div>
      {/* <div className="gridPanel">
        <div className="ag-theme-alpine" style={{ height: 550 }}>
          <AgGridReact rowData={rowData} columnDefs={columnDefs} />
        </div>
      </div> */}
      <div className="resListTableOuter">
        <div className="resListTable">
          <table>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Company Name</th>
              <th>Message</th>
              <th>Action</th>
            </tr>
            {approveList.map((list) => (
              <tr>
                <td>{list.Name}</td>
                <td>{list.UserName}</td>
                <td>{list.Phone}</td>
                <td>{list.CompanyName}</td>
                <td>{list.SignupMsg}</td>
                <td>
                  <div>
                    <button
                      className="grid-action-btn grid-dark-btn viewRest"
                      onClick={() => { approveReseller(list.UserId, list.Name) }}
                    >Approve Now</button>
                  </div>
                </td>
              </tr>
            ))
            }
          </table>
        </div>
      </div>
      <SessionEnd openOrNot={unauthorized} />
    </div>

    // <div className="contentForm" style={{ width: "95%" }}>
    //   {contextHolder}
    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       padding: "25px 25px 25px 25px",
    //     }}
    //   >
    //     <div>
    //       <p
    //         style={{
    //           color: "#424242",
    //           fontSize: 16.25,
    //           fontFamily: "Open Sans, sans-serif",
    //         }}
    //       >
    //         Pending Approvals for Resellers
    //       </p>
    //     </div>
    //   </div>
    //   <div
    //     className="ag-theme-alpine"
    //     style={{ height: 500, width: "100%", padding: "10px 25px 10px 25px" }}
    //   >
    //     <AgGridReact
    //       scrollbarWidth={7}
    //       rowStyle={{ height: 10 }}
    //       rowData={rowData}
    //       columnDefs={columnDefs}
    //     />
    //   </div>
    // </div>
  );
}
