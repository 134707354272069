import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Input, TextField } from "@material-ui/core";
import Axios from "axios";
import { message } from 'antd'
import { APIUrl } from "..";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export default function ForgotPasswordDialog({ open, handleClose }) {
  const [show, setShow] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [messageShow, setMessageShow] = useState("");
  const [inputs, setInputs] = useState({});
  const newdata = [];
  newdata.forgotEmail = inputs.forgotEmail;
  newdata.verificationCode = inputs.verificationCode;
  newdata.forgotPassword = inputs.forgotPassword;

  function SendEmail(e) {
    if (newdata.forgotEmail) {
      Axios.post(APIUrl + "ForgotPassword", {
        data: {
          UserName: newdata.forgotEmail,
        },
      })
        .then((response) => {
          if (response.data.status == "s") {
            // setMessageShow(response.data.message);
            setShow(true);
          } else if (response.data.status == "f")
            // messageApi.open({
            //   type: 'error',
            //   content: 'Enter correct email !'
            // })
            setMessageShow('Enter correct Mail !');
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    }
  }

  function resetPassword() {
    if (
      newdata.forgotEmail &&
      newdata.verificationCode &&
      newdata.forgotPassword
    ) {
      Axios.post(APIUrl + "SetPassword", {
        data: {
          SetPassword: {
            Email: newdata.forgotEmail,
            Code: newdata.verificationCode,
            NewPassword: newdata.forgotPassword,
          },
        },
      })
        .then((response) => {
          if (response.data.status == "s") {
            messageApi.open({
              type: 'success',
              content: 'Password changed successfully !'
            })
            setShow(false)
            handleClose();
            // setMessageShow(response.data.message);
          }
          else if (response.data.status == "f")
            // messageApi.open({
            //   type: 'error',
            //   content: 'Enter correct verification code !'
            // })
            setMessageShow('Enter correct verification code !');
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      if (!newdata.verificationCode || !newdata.forgotEmail || !newdata.forgotPassword) {
        setMessageShow('Enter all the details !');
      }
    }
  }

  const onChange = (e) => {
    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));
  };

  function CloseWindow() {
    setShow(false)
    setMessageShow(false)
    handleClose();
  }

  return (

    <>{contextHolder}
      <Dialog
        // fullWidth={true}
        PaperProps={{sx: {width: 400}}}
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Forgot password?</DialogTitle>
        {messageShow != "" && (<div className="errorMsg"> <span>
          <PriorityHighIcon></PriorityHighIcon>
        </span>{messageShow}
        </div>)
        }
        <form>
          <DialogContent>
            {/* <form onSubmit={SendEmail}> */}
            <TextField
              name="forgotEmail"
              required="true"
              placeholder="Enter Email"
              label="Email"
              fullWidth
              //value={inputs.email}
              onChange={onChange}
            />

            {show && (
              <div>
                <TextField
                  name="verificationCode"
                  placeholder="Enter Verification Code"
                  label="Verification Code"
                  fullWidth
                  onChange={onChange}
                  required="true"
                />
                <TextField
                  name="forgotPassword"
                  placeholder="Enter Password"
                  label="Password"
                  fullWidth
                  type="password"
                  onChange={onChange}
                  required="true"
                />
              </div>
            )}
            {/* </form> */}
          </DialogContent>

          {/* {messageShow != "" && (<div className="errorMsg"> <span>
          <PriorityHighIcon></PriorityHighIcon>
        </span>{messageShow}
        </div>)
        } */}

          <DialogActions>
            {show === false && (
              <Button onClick={() => { CloseWindow() }}>Cancel</Button>
            )}
            
            {show === true && (
              <Button onClick={() => { setShow(false); setMessageShow(false); }}>Cancel</Button>
            )}

            {show === false && (
              <Button autoFocus onClick={SendEmail}>
                Submit
              </Button>
            )}

            {show && (
              <Button
                onClick={() => {
                  resetPassword();
                }}
              >
                Reset Password
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
