import React, { useState, useEffect } from 'react';
import { Input, PasswordInput, Form } from '@start-base/react-form-elements';
import { Button, Checkbox } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd'
import { APIUrl } from '../..';
import { addServiceHelper } from '../../helper/servicehelper';
import SessionEnd from './SessionEnd';
import LoadingButton from '@mui/lab/LoadingButton';

export default function Pickup() {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage();
  const [unauthorized, setUnauthorized] = useState(false)
  const [style, setStyle] = useState(null)
  const [inputs, setInputs] = useState({
    "restService": {
      "LookupRestServiceId": 1,
      "RestServiceId": -1,
      "Name": "Pickup",
      "RestLocationId": -1,
      "Active": false,
      "openMonday": false,
      "openTuesday": false,
      "openWednesday": false,
      "openThursday": false,
      "openFriday": false,
      "openSaturday": false,
      "openSunday": false,
      "MinDelay": 1,
      "isApplePay": false,
      "isCC": false,
      "isCash": false,
      "isCardInPerson": false
    }
  });
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    const { name, value, type } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? !prevState[name] : value,
    }));
  };

  const [showOptions, setShowOptions] = useState(false)
  const getRestServicesByLocationId = async () => {
    try {
      const locationID = parseInt(localStorage.getItem("RestLocationId"))
      const restChainId = localStorage.getItem("RestChainId")
      if (restChainId == 33566 || restChainId == 33590) {
        setStyle({
          pointerEvents: 'none',
          opacity: 0.5
        })
      }
      const newState = { ...inputs }
      newState.restService.RestLocationId = locationID
      setInputs(newState)
      if (locationID) {
        if (locationID == -1) {
          messageApi.open({
            type: 'warning',
            content: 'Please select restaurant first & then you can edit'
          })
          setStyle({
            pointerEvents: 'none',
            opacity: 0.5
          })
        } else {
          let Token = ""
          if (localStorage.getItem("UserDetails")) {
            Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
          }
          const dataTosend = { "data": { "token": Token, "RestLocationId": locationID } }
          const getServices = await axios.post(APIUrl + 'RestServiceByLoc', dataTosend, {
            headers: { 'Content-Type': 'application/json' }
          })
          const pickupData = getServices.data.data.listRestService
          const data = pickupData.filter((data) => data.Name === "Pickup")
          if (data.length > 0) {
            if (data[0].Active === true) {
              setShowOptions(true)
            }
            const newState = { ...inputs }
            newState.restService.Active = data[0].Active
            newState.restService.RestServiceId = data[0].RestServiceId
            newState.restService.MinDelay = data[0].MinDelay
            newState.restService.openMonday = data[0].openMonday
            newState.restService.openTuesday = data[0].openTuesday
            newState.restService.openWednesday = data[0].openWednesday
            newState.restService.openThursday = data[0].openThursday
            newState.restService.openFriday = data[0].openFriday
            newState.restService.openSaturday = data[0].openSaturday
            newState.restService.openSunday = data[0].openSunday
            newState.restService.isApplePay = data[0].isApplePay
            newState.restService.isCC = data[0].isCC
            newState.restService.isCash = data[0].isCash
            newState.restService.isCardInPerson = data[0].isCardInPerson
            setInputs(newState)
          }
        }
      } else {
        messageApi.open({
          type: 'warning',
          content: 'Please select restaurant first & then you can edit'
        })
        setStyle({
          pointerEvents: 'none',
          opacity: 0.5
        })
      }
    } catch (error) {
      console.log(error)
      if (error.response.data == "Unauthorized") {
        setUnauthorized(true)
      }
    }
  }

  useEffect(() => {
    getRestServicesByLocationId();
  }, [])

  const getMinDelay = (minutes) => {
    const newState = { ...inputs }
    newState.restService.MinDelay = minutes
    setInputs(newState)
  }

  const getDays = (index) => {
    const newState = { ...inputs }
    if (index === 0) {
      newState.restService.openMonday = !newState.restService.openMonday
      setInputs(newState)
    } else if (index === 1) {
      newState.restService.openTuesday = !newState.restService.openTuesday
      setInputs(newState)
    } else if (index === 2) {
      newState.restService.openWednesday = !newState.restService.openWednesday
      setInputs(newState)
    } else if (index === 3) {
      newState.restService.openThursday = !newState.restService.openThursday
      setInputs(newState)
    } else if (index === 4) {
      newState.restService.openFriday = !newState.restService.openFriday
      setInputs(newState)
    } else if (index === 5) {
      newState.restService.openSaturday = !newState.restService.openSaturday
      setInputs(newState)
    } else if (index === 6) {
      newState.restService.openSunday = !newState.restService.openSunday
      setInputs(newState)
    }
  }

  const getPayment = (index) => {
    const newState = { ...inputs }
    if (index === 0) {
      newState.restService.isCC = !newState.restService.isCC
      setInputs(newState)
    } else if (index === 1) {
      newState.restService.isCash = !newState.restService.isCash
      setInputs(newState)
    } else if (index === 2) {
      newState.restService.isApplePay = !newState.restService.isApplePay
      setInputs(newState)
    } else if (index === 3) {
      newState.restService.isCardInPerson = !newState.restService.isCardInPerson
      setInputs(newState)
    }
  }

  const sendPickupServices = async () => {
    setLoading(true)
    let x = inputs.restService
    const checkPayment = x.isApplePay || x.isCardInPerson || x.isCash || x.isCC
    const checkDays = x.openMonday || x.openTuesday || x.openWednesday || x.openThursday || x.openFriday || x.openSaturday || x.openSunday

    if (showOptions) {
      if (!checkPayment && !checkDays) {
        messageApi.open({
          type: 'warning',
          content: 'Please select working days & payment methods'
        })
        return
      }
      if (!checkPayment) {
        messageApi.open({
          type: 'warning',
          content: 'Please payment methods'
        })
        return
      }
      if (!checkDays) {
        messageApi.open({
          type: 'warning',
          content: 'Please select working days'
        })
        return
      }
    }

    let Token = ""
    if (localStorage.getItem("UserDetails")) {
      Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
    }
    let pickupDataToSend
    if (showOptions) {
      const newState = { ...inputs }
      newState.restService.Active = true
      setInputs(newState)
      pickupDataToSend = { "data": { "token": Token, "restService": inputs.restService } }
    } else {
      const newState = { ...inputs }
      newState.restService.Active = false
      setInputs(newState)
      // newState.restService.MinDelay = 1 
      // newState.restService.openMonday = false
      // newState.restService.openTuesday = false
      // newState.restService.openWednesday = false
      // newState.restService.openThursday = false
      // newState.restService.openFriday = false
      // newState.restService.openSaturday = false
      // newState.restService.openSunday = false
      // newState.restService.isApplePay = false
      // newState.restService.isCC = false
      // newState.restService.isCash = false
      // newState.restService.isCardInPerson = false
      pickupDataToSend = { "data": { "token": Token, "restService": inputs.restService } }
    }

    if (inputs.restService.RestLocationId) {
      const res = await addServiceHelper(pickupDataToSend)
      if (res === 200) {
        setLoading(false)
        await getRestServicesByLocationId()
        messageApi.open({
          type: 'success',
          content: 'Pickup Service Updated !'
        })
        setTimeout(() => {
          navigate('/reseller/setup/delivery')
        }, 500);
      } else if (res === 400) {
        messageApi.open({
          type: 'error',
          content: 'Something went wrong, try again'
        })
      } else if (res === 401) {
        setLoading(false)
        setUnauthorized(true)
      }
    } else {
      setLoading(false)
      messageApi.open({
        type: 'warning',
        content: 'Please select restaurant first & then you can edit'
      })
    }
    setLoading(false)
  }

  return (
    <div className='smlwindowHeight'>
      <div className='contentForm smallPageWindow'>
        <div className='formHeading'>
          <div className='btnwithText'>
            <Button onClick={() => {
              if (localStorage.getItem("RestLocationId")) {
                navigate('/reseller/setup/online_payment')
              } else {
                navigate("/reseller/restaurant/list");
              }
            }}>Back</Button>
            {/* <Button onClick={() => { navigate('/reseller/setup/account_confirmation') }}>Back</Button> */}
            <h2>Pickup options</h2>
          </div>
          <Button className='black-colorBtn' onClick={() => { navigate('/reseller/setup/delivery') }}>Skip</Button>
        </div>
        {contextHolder}
        {/* <div className='contentFormBody'>
          <div className='servicePickup'>
            <p>Do you offer pickup from your location?</p>
            <div className='servicePickupBtns'>
              <Button className='pickupBtns dark-clr' onClick={() => setShowOptions(true)}>Yes</Button>
              <Button className='pickupBtns white-clr' onClick={() => setShowOptions(false)}>No</Button>
            </div>
          </div>
        </div> */}
        <div className='activeServicesBox' style={style} >
          <label className='checkboxes'>
            <Checkbox
              style={{ color: '#2C394B' }}
              checked={showOptions}
              onChange={() => setShowOptions(!showOptions)}
              icon={<CheckBoxOutlineBlankIcon />}
              checkedIcon={<CheckBoxIcon />}
            />
            <span>{showOptions ? 'Uncheck this box to deactivate pickup services' : 'Check this box to activate pickup services'}</span>
          </label>
        </div>

        {showOptions ?
          (
            <div className='mainService' style={style} >
              <div className='delay'>
                <label className='paymentlabel'>Min Delay In Minutes</label><br />
                <input className='delayinput' type='number' min={1} value={inputs.restService.MinDelay} onChange={(e) => getMinDelay(e.target.valueAsNumber)} />
              </div>

              <label className='paymentlabel'>Choose Active Days *</label>
              <div className='daysoptions'>
                <label>
                  <div className='daysbox'>
                    <Checkbox size='small' style={{ color: '#2C394B' }} checked={inputs.restService.openMonday} onChange={() => getDays(0)} />
                    <span className='daylabel'>Mon</span>
                  </div>
                </label>
                <label>
                  <div className='daysbox'>
                    <Checkbox size='small' style={{ color: '#2C394B' }} checked={inputs.restService.openTuesday} onChange={() => getDays(1)} />
                    <span className='daylabel'>Tue</span>
                  </div>
                </label>
                <label>
                  <div className='daysbox'>
                    <Checkbox size='small' style={{ color: '#2C394B' }} checked={inputs.restService.openWednesday} onChange={() => getDays(2)} />
                    <span className='daylabel'>Wed</span>
                  </div>
                </label>
                <label><div className='daysbox'>
                  <Checkbox size='small' style={{ color: '#2C394B' }} checked={inputs.restService.openThursday} onChange={() => getDays(3)} />
                  <span className='daylabel'>Thu</span>
                </div>
                </label>
                <label>
                  <div className='daysbox'>
                    <Checkbox size='small' style={{ color: '#2C394B' }} checked={inputs.restService.openFriday} onChange={() => getDays(4)} />
                    <span className='daylabel'>Fri</span>
                  </div>
                </label>
                <label>
                  <div className='daysbox'>
                    <Checkbox size='small' style={{ color: '#2C394B' }} checked={inputs.restService.openSaturday} onChange={() => getDays(5)} />
                    <span className='daylabel'>Sat</span>
                  </div>
                </label>
                <label>
                  <div className='daysbox'>
                    <Checkbox size='small' style={{ color: '#2C394B' }} checked={inputs.restService.openSunday} onChange={() => getDays(6)} />
                    <span className='daylabel'>Sun</span>
                  </div>
                </label>
              </div>

              <div>
                <span className='paymentlabel'>Payment Methods *</span>
                <div className='paymentmethodslist'>
                  <label className='choosepayment'>
                    <div>
                      <Checkbox
                        size='small'
                        style={{ color: '#2C394B' }}
                        // icon={<RadioButtonUncheckedIcon />}
                        // checkedIcon={<RadioButtonCheckedIcon />}
                        checked={inputs.restService.isCC}
                        onChange={() => getPayment(0)}
                      />
                      <span className='daylabel'>Credit/Debit Cards</span>
                    </div>
                    {/* <img src='http://staging.m4.imenu360.com/iMenu360Reseller/assets/media/002-credit.png'></img> */}
                  </label>
                  <label className='choosepayment'>
                    <div>
                      <Checkbox
                        size='small'
                        style={{ color: '#2C394B' }}
                        // icon={<RadioButtonUncheckedIcon />}
                        // checkedIcon={<RadioButtonCheckedIcon />}
                        checked={inputs.restService.isCash}
                        onChange={() => getPayment(1)}
                      />
                      <span className='daylabel'> Cash</span>
                    </div>
                    {/* <img src='http://staging.m4.imenu360.com/iMenu360Reseller/assets/media/003-money.png'></img> */}
                  </label>
                  <label className='choosepayment'>
                    <div>
                      <Checkbox
                        size='small'
                        style={{ color: '#2C394B' }}
                        // icon={<RadioButtonUncheckedIcon />}
                        // checkedIcon={<RadioButtonCheckedIcon />}
                        checked={inputs.restService.isApplePay}
                        onChange={() => getPayment(2)}
                      />
                      <span className='daylabel'> Apple Pay</span>
                    </div>
                    {/* <img src='http://staging.m4.imenu360.com/iMenu360Reseller/assets/media/001-apple-pay.png'></img> */}
                  </label>
                  <label className='choosepayment'>
                    <div>
                      <Checkbox
                        size='small'
                        style={{ color: '#2C394B' }}
                        // icon={<RadioButtonUncheckedIcon />}
                        // checkedIcon={<RadioButtonCheckedIcon />}
                        checked={inputs.restService.isCardInPerson}
                        onChange={() => getPayment(3)}
                      />
                      <span className='daylabel'> Card In-person</span>
                    </div>
                    {/* <img src='http://staging.m4.imenu360.com/iMenu360Reseller/assets/media/005-credit-card.png'></img> */}
                  </label>
                </div>

                {/* <div className='paymentmethodslist'>
                  <label className='choosepayment'>
                    <div><Checkbox size='medium' style={{ color: '#2C394B' }} />Apple Pay</div>
                    <img src='http://staging.m4.imenu360.com/iMenu360Reseller/assets/media/001-apple-pay.png'></img>
                  </label>
                  <label className='choosepayment'>
                    <div><Checkbox size='medium' style={{ color: '#2C394B' }} />Card In-person</div>
                    <img src='http://staging.m4.imenu360.com/iMenu360Reseller/assets/media/005-credit-card.png'></img>
                  </label>
                </div>
                 */}
              </div>
            </div>
          ) :
          (null)
        }
        <div className='bottomDiv' style={style}>
          {/* <button className='savebtn' onClick={() => sendPickupServices()}>Save</button> */}
          <LoadingButton
            className='savebtn'
            onClick={() => sendPickupServices()}
            loading={loading}
            loadingPosition="end"
            endIcon={loading ? <CheckBoxOutlineBlankIcon color={"#2C394B"} style={{ color: "#2C394B" }} /> : ''}
          >
            <span>Save</span>
          </LoadingButton>
        </div>
        <SessionEnd openOrNot={unauthorized} />
      </div >
    </div>
  )
}