import React, { useEffect, useState } from 'react';
// import { Input, Checkbox, Form } from '@start-base/react-form-elements';
import { Button, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TimeField from 'react-simple-timefield';
import 'reactjs-popup/dist/index.css';
import SpecialDay from './SpecialDay';
import Model from 'react-modal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { TimePicker, Modal, DatePicker, message } from 'antd'
import { MdOutlineDeleteOutline } from "react-icons/md";
import { APIUrl } from '../..';
import axios from 'axios';
import SessionEnd from './SessionEnd';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
const { RangePicker } = DatePicker
dayjs.extend(customParseFormat)

export default function OpeningHours() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage();
  const [unauthorized, setUnauthorized] = useState(false)
  const [style, setStyle] = useState(null)
  const [isDifferentHours, setIsDifferentHours] = useState(false)
  const [isCopyMondaySchedules, setIsCopyMondaySchedules] = useState(false)
  const [InputTitles, setInputsTitles] = useState([
    {
      Title: 'Restaurant Service Timings',
      SubTitle1: 'Open Time',
      SubTitle2: 'Close Time',
    },
    {
      Title: 'Pickup Order Timings ',
      SubTitle1: 'First Order At',
      SubTitle2: 'Last Order At',
    },
    {
      Title: 'Delivery Timings',
      SubTitle1: 'First Delivery At',
      SubTitle2: 'Last Delivery At',
    }
  ])
  const [inputs, setInputs] = useState({
    "SelectedDay": 'Monday',
    "DaysOpenMask": "0000000",
    "DefaultTime": dayjs("1/1/1753 ".concat("00:00:00 AM"), { format: "MM/DD/YYYY hh:mm:ss A" }).format("MM/DD/YYYY hh:mm:ss A"),
    Days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    Holidays: [],
    RestCalendarId: -1,
    RestLocationId: -1,
    HolidayMinDate: dayjs().subtract(1, 'year'),
    HolidayMaxDate: dayjs().add(1, 'year'),
    SelectedHolidayDate: '',
    Monday: {
      "Open Time": '',
      "Close Time": '',
      "First Order At": '',
      "Last Order At": '',
      "First Delivery At": '',
      "Last Delivery At": '',
      "RestScheduleId": -1
    },
    Tuesday: {
      "Open Time": '',
      "Close Time": '',
      "First Order At": '',
      "Last Order At": '',
      "First Delivery At": '',
      "Last Delivery At": '',
      "RestScheduleId": -1
    },
    Wednesday: {
      "Open Time": '',
      "Close Time": '',
      "First Order At": '',
      "Last Order At": '',
      "First Delivery At": '',
      "Last Delivery At": '',
      "RestScheduleId": -1
    },
    Thursday: {
      "Open Time": '',
      "Close Time": '',
      "First Order At": '',
      "Last Order At": '',
      "First Delivery At": '',
      "Last Delivery At": '',
      "RestScheduleId": -1
    },
    Friday: {
      "Open Time": '',
      "Close Time": '',
      "First Order At": '',
      "Last Order At": '',
      "First Delivery At": '',
      "Last Delivery At": '',
      "RestScheduleId": -1
    },
    Saturday: {
      "Open Time": '',
      "Close Time": '',
      "First Order At": '',
      "Last Order At": '',
      "First Delivery At": '',
      "Last Delivery At": '',
      "RestScheduleId": -1
    },
    Sunday: {
      "Open Time": '',
      "Close Time": '',
      "First Order At": '',
      "Last Order At": '',
      "First Delivery At": '',
      "Last Delivery At": '',
      "RestScheduleId": -1
    }
  });

  const onChange = (e) => {
    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? !prevState[name] : value,
    }));
  };

  const [visible, setvisible] = useState(false);
  const [isHolidayModalOpen, setIsHolidayModalOpen] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)

  const getSchedules = async () => {
    try {
      const mask = inputs.DaysOpenMask
      const locationID = parseInt(localStorage.getItem("RestLocationId"))
      const restChainId = localStorage.getItem("RestChainId")
      if (restChainId == 33566 || restChainId == 33590) {
        setStyle({
          pointerEvents: 'none',
          opacity: 0.5
        })
      }
      const newState = { ...inputs }
      newState.RestLocationId = locationID
      setInputs(newState)
      if (locationID) {
        if (locationID == -1) {
          messageApi.open({
            type: 'warning',
            content: 'Please select restaurant first & then you can edit'
          })
          setStyle({
            pointerEvents: 'none',
            opacity: 0.5
          })
        } else {
          let Token = ""
          if (localStorage.getItem("UserDetails")) {
            Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
          }
          const dataTosend = { "data": { "token": Token, "RestLocationId": locationID } }
          const getScheduleResponse = await axios.post(APIUrl + 'GetRestScheduleByLocId', dataTosend, {
            headers: { 'Content-Type': 'application/json' }
          })
          if (getScheduleResponse.data.serviceStatus == 200) {
            const scheduleData = await getScheduleResponse.data?.data
            const newState = { ...inputs }
            await setIsDifferentHours(scheduleData.locationCalendar?.isDifferentHours)
            newState.RestCalendarId = scheduleData.locationCalendar?.RestCalendarId
            newState.DaysOpenMask = scheduleData.locationCalendar?.DaysOpenMask
            newState.Holidays = scheduleData.locationCalendar?.Hollidays
            if (scheduleData.daywiseSchedule == null) {
              setDefaultTime()
            } else {
              inputs.Days?.map((day) => {
                newState[day] = {
                  "Open Time": scheduleData.daywiseSchedule[day]?.OpenTime,
                  "Close Time": scheduleData.daywiseSchedule[day]?.CloseTime,
                  "First Order At": scheduleData.daywiseSchedule[day]?.FirstOrder,
                  "Last Order At": scheduleData.daywiseSchedule[day]?.LastOrder,
                  "First Delivery At": scheduleData.daywiseSchedule[day]?.FirstDelivery,
                  "Last Delivery At": scheduleData.daywiseSchedule[day]?.LastDelivery,
                  "RestScheduleId": scheduleData.daywiseSchedule[day]?.RestScheduleId
                }
              })
              setInputs({ ...newState })
            }
          }
        }
      } else {
        messageApi.open({
          type: 'warning',
          content: 'Please select restaurant first & then you can edit'
        })
        setStyle({
          pointerEvents: 'none',
          opacity: 0.5
        })
        setDefaultTime()
      }
    } catch (error) {
      console.log(error)
      if (error.response.data == "Unauthorized") {
        setUnauthorized(true)
      }
      const timeTitle = ["Open Time", "Close Time", "First Order At", "Last Order At", "First Delivery At", "Last Delivery At"]
      inputs.Days.map((day) => {
        timeTitle.map((title) => {
          setInputs(prevInputs => ({
            ...prevInputs,
            [day]: {
              ...prevInputs[day],
              [title]: inputs.DefaultTime
            },
          }))
        })
      })
    }
  }

  const setDefaultTime = () => {
    const timeTitle = ["Open Time", "Close Time", "First Order At", "Last Order At", "First Delivery At", "Last Delivery At"]
    inputs.Days.map((day) => {
      timeTitle.map((title) => {
        setInputs(prevInputs => ({
          ...prevInputs,
          [day]: {
            ...prevInputs[day],
            [title]: inputs.DefaultTime
          },
        }))
      })
    })
  }

  useEffect(() => {
    getSchedules()
  }, [])

  const getTimeFormated = (date) => {
    const originalDate = new Date(date);
    const validDate = new Date(1753, 1, 1, originalDate.getHours(), originalDate.getMinutes(), originalDate.getSeconds());
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = validDate.toLocaleTimeString([], options);
    return formattedTime
  }

  const selectDay = async (day) => {
    const newState = { ...inputs }
    newState.SelectedDay = day
    setInputs(newState)
  }

  const addTime = (time, field) => {
    const newTime = dayjs("1/1/1753 ".concat(time), { format: "M/D/YYYY hh:mm:ss A" }).format("M/D/YYYY hh:mm:ss A")
    setInputs(prevInputs => ({
      ...prevInputs,
      [inputs.SelectedDay]: {
        ...prevInputs[inputs.SelectedDay],
        [field]: newTime,
      },
    }))
  }

  const changeDiffrentHours = async () => {
    if (isDifferentHours) {
      console.log('true')
    } else {
      console.log('false')
      const newState = { ...inputs }
      newState.Monday['First Delivery At'] = inputs.Monday['First Order At']
      newState.Monday['Last Delivery At'] = inputs.Monday['Last Order At']
      newState.Tuesday['First Delivery At'] = inputs.Tuesday['First Order At']
      newState.Tuesday['Last Delivery At'] = inputs.Tuesday['Last Order At']
      newState.Wednesday['First Delivery At'] = inputs.Wednesday['First Order At']
      newState.Wednesday['Last Delivery At'] = inputs.Wednesday['Last Order At']
      newState.Thursday['First Delivery At'] = inputs.Thursday['First Order At']
      newState.Thursday['Last Delivery At'] = inputs.Thursday['Last Order At']
      newState.Friday['First Delivery At'] = inputs.Friday['First Order At']
      newState.Friday['Last Delivery At'] = inputs.Friday['Last Order At']
      newState.Saturday['First Delivery At'] = inputs.Saturday['First Order At']
      newState.Saturday['Last Delivery At'] = inputs.Saturday['Last Order At']
      newState.Sunday['First Delivery At'] = inputs.Sunday['First Order At']
      newState.Sunday['Last Delivery At'] = inputs.Sunday['Last Order At']
      await setInputs(newState)
    }
  }

  const copyMondaySchedulesToAll = () => {
    if (!isCopyMondaySchedules) {
      selectDay('Monday')
      inputs.Days.map((day) =>
        setInputs(prevInputs => ({
          ...prevInputs,
          [day]: {
            ...prevInputs[day],
            ['Open Time']: inputs.Monday['Open Time'],
            ['Close Time']: inputs.Monday['Close Time'],
            ['First Order At']: inputs.Monday['First Order At'],
            ['Last Order At']: inputs.Monday['Last Order At'],
            ['First Delivery At']: inputs.Monday['First Delivery At'],
            ['Last Delivery At']: inputs.Monday['Last Delivery At']
          },
        }))
      )
    } else {
      getSchedules()
    }
  }

  const changeOpenCloseDays = (index, checked) => {
    const newState = { ...inputs }
    if (!checked) {
      let mask = newState.DaysOpenMask.split('')
      mask[index] = '0'
      mask = mask.join('')
      newState.DaysOpenMask = mask
      setInputs(newState)
    } else {
      let mask = newState.DaysOpenMask.split('')
      mask[index] = '1'
      mask = mask.join('')
      newState.DaysOpenMask = mask
      setInputs(newState)
    }
  }

  const addHolidays = () => {
    if (inputs.SelectedHolidayDate) {
      if (inputs.Holidays.includes(inputs.SelectedHolidayDate)) {
        alert('This holiday is already added!')
      } else {
        console.log(inputs.SelectedHolidayDate)
        const newState = { ...inputs }
        newState.Holidays.unshift(inputs.SelectedHolidayDate)
        setInputs(newState)
      }
    }
  }

  const deleteHoliday = (holidayDate) => {
    const newState = { ...inputs }
    newState.Holidays = newState.Holidays.filter((days) => days != holidayDate)
    setInputs(newState)
  }

  const sendSchedules = async (types) => {
    setLoading(true)
    const locationID = localStorage.getItem("RestLocationId")
    let Token = ""
    if (localStorage.getItem("UserDetails")) {
      Token = JSON.parse(localStorage.getItem("UserDetails")).Token;
    }
    if (locationID) {
      const dataTosend = {
        "data": {
          "token": Token,
          "RestLocationId": locationID,
          "scheduleAndCalendar": {
            "locationCalendar": {
              "RestCalendarId": inputs.RestCalendarId,
              "hollidays": inputs.Holidays,
              "DaysOpenMask": inputs.DaysOpenMask,
              "isDifferentHours": isDifferentHours,
            },
            "daywiseSchedule": {
              "Monday": {
                "RestScheduleId": inputs.Monday.RestScheduleId,
                "OpenTime": inputs.Monday['Open Time'],
                "CloseTime": inputs.Monday['Close Time'],
                "FirstDelivery": inputs.DaysOpenMask.split('')[0] === '0' ? inputs.DefaultTime : inputs.Monday['First Delivery At'],
                "LastDelivery": inputs.Monday['Last Delivery At'],
                "FirstOrder": inputs.Monday['First Order At'],
                "LastOrder": inputs.Monday['Last Order At']
              },
              "Tuesday": {
                "RestScheduleId": inputs.Tuesday.RestScheduleId,
                "OpenTime": inputs.Tuesday['Open Time'],
                "CloseTime": inputs.Tuesday['Close Time'],
                "FirstDelivery": inputs.Tuesday['First Delivery At'],
                "LastDelivery": inputs.Tuesday['Last Delivery At'],
                "FirstOrder": inputs.Tuesday['First Order At'],
                "LastOrder": inputs.Tuesday['Last Order At']
              },
              "Wednesday": {
                "RestScheduleId": inputs.Wednesday.RestScheduleId,
                "OpenTime": inputs.Wednesday['Open Time'],
                "CloseTime": inputs.Wednesday['Close Time'],
                "FirstDelivery": inputs.Wednesday['First Delivery At'],
                "LastDelivery": inputs.Wednesday['Last Delivery At'],
                "FirstOrder": inputs.Wednesday['First Order At'],
                "LastOrder": inputs.Wednesday['Last Order At']
              },
              "Thursday": {
                "RestScheduleId": inputs.Thursday.RestScheduleId,
                "OpenTime": inputs.Thursday['Open Time'],
                "CloseTime": inputs.Thursday['Close Time'],
                "FirstDelivery": inputs.Thursday['First Delivery At'],
                "LastDelivery": inputs.Thursday['Last Delivery At'],
                "FirstOrder": inputs.Thursday['First Order At'],
                "LastOrder": inputs.Thursday['Last Order At']
              },
              "Friday": {
                "RestScheduleId": inputs.Friday.RestScheduleId,
                "OpenTime": inputs.Friday['Open Time'],
                "CloseTime": inputs.Friday['Close Time'],
                "FirstDelivery": inputs.Friday['First Delivery At'],
                "LastDelivery": inputs.Friday['Last Delivery At'],
                "FirstOrder": inputs.Friday['First Order At'],
                "LastOrder": inputs.Friday['Last Order At']
              },
              "Saturday": {
                "RestScheduleId": inputs.Saturday.RestScheduleId,
                "OpenTime": inputs.Saturday['Open Time'],
                "CloseTime": inputs.Saturday['Close Time'],
                "FirstDelivery": inputs.Saturday['First Delivery At'],
                "LastDelivery": inputs.Saturday['Last Delivery At'],
                "FirstOrder": inputs.Saturday['First Order At'],
                "LastOrder": inputs.Saturday['Last Order At']
              },
              "Sunday": {
                "RestScheduleId": inputs.Sunday.RestScheduleId,
                "OpenTime": inputs.Sunday['Open Time'],
                "CloseTime": inputs.Sunday['Close Time'],
                "FirstDelivery": inputs.Sunday['First Delivery At'],
                "LastDelivery": inputs.Sunday['Last Delivery At'],
                "FirstOrder": inputs.Sunday['First Order At'],
                "LastOrder": inputs.Sunday['Last Order At']
              }
            }
          }
        }
      }
      try {
        const getScheduleResponse = await axios.post(APIUrl + 'RestSchedule', dataTosend, {
          headers: { 'Content-Type': 'application/json' }
        })
        if (getScheduleResponse.data.serviceStatus == 200) {
          setLoading(false)
          messageApi.open({
            type: 'success',
            content: 'Schedule updated successfully !'
          })
          if (types == 'save') {
            setIsEditOpen(!isEditOpen)
          } else {
            setTimeout(() => {
              navigate('/reseller/setup/taxation')
            }, 500);
          }
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
        if (error.response.data == "Unauthorized") {
          setUnauthorized(true)
        } else {
          messageApi.open({
            type: 'error',
            content: 'Something went wrong, try again ?'
          })
        }
      }
    } else {
      setLoading(false)
      messageApi.open({
        type: 'warning',
        content: 'Please select restaurant first & then you can edit'
      })
    }
    setLoading(false)
  }

  return (
    <div className='smlwindowHeight'>
      {contextHolder}
      <div className='contentForm smallPageWindow'>

        <div className='formHeading'>
          <div className='btnwithText'>
            <Button onClick={() => {
              if (localStorage.getItem("RestLocationId")) {
                navigate('/reseller/setup/curbside')
              } else {
                navigate("/reseller/restaurant/list");
              }
            }}>Back</Button>
            <h2>When are you open?</h2></div>
          {/* <Button className='black-colorBtn' onClick={() => { navigate('/reseller/setup/taxation') }}>Next</Button> */}
        </div>

        <div className='openinghrsBox' style={style} >
          {isEditOpen ?
            (
              <div>

                <div className='copymonday'>
                  <h3>Copy monday schedule</h3>
                  <label className='copyMondaySpan'>
                    <Checkbox
                      value={isCopyMondaySchedules}
                      checked={isCopyMondaySchedules}
                      size='small'
                      style={{ color: '#2C394B' }}
                      onChange={(e) => { copyMondaySchedulesToAll(); setIsCopyMondaySchedules(e.target.checked); }}
                    />
                    <span>Copy monday schedule to all days</span>
                  </label>
                </div>

                <div>
                  <h3>Choose which days restaurant will be open</h3>
                  <div className='openDaysBox'>
                    {inputs.Days.map((day, index) => (
                      <label>
                        <Checkbox
                          checked={inputs.DaysOpenMask.charAt(index) == 0 ? false : true}
                          size='small'
                          style={{ color: '#2C394B' }}
                          onChange={(e) => { console.log(index); changeOpenCloseDays(index, e.target.checked) }}
                        />
                        <span>{day}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div>
                  <h3>Choose day to change below timings</h3>
                  <select className='daysDropdown' onChange={(e) => selectDay(e.target.value)} value={inputs.SelectedDay}>
                    <option value={'Monday'} >Monday</option>
                    <option value={'Tuesday'} disabled={isCopyMondaySchedules}>Tuesday</option>
                    <option value={'Wednesday'} disabled={isCopyMondaySchedules}>Wednesday</option>
                    <option value={'Thursday'} disabled={isCopyMondaySchedules}>Thursday</option>
                    <option value={'Friday'} disabled={isCopyMondaySchedules}>Friday</option>
                    <option value={'Saturday'} disabled={isCopyMondaySchedules}>Saturday</option>
                    <option value={'Sunday'} disabled={isCopyMondaySchedules}>Sunday</option>
                  </select>
                </div>


                <br />

                {InputTitles?.map((element, index) => (
                  index === (InputTitles.length - 1) && !isDifferentHours ? ('') :
                    <div>
                      <h3>{element.Title}</h3>
                      <div className='chooseTimeBox'>
                        <div>
                          <label>{element.SubTitle1}</label><br />
                          <TimePicker
                            allowClear={false}
                            className='timepicker'
                            format="hh:mm A"
                            size=''
                            value={dayjs(inputs[inputs.SelectedDay][element.SubTitle1], "M/D/YYYY hh:mm:ss A")}
                            onChange={(time) => addTime(time.format('hh:mm:ss A'), element.SubTitle1)}
                          />
                        </div>
                        <div>
                          <label>{element.SubTitle2}</label><br />
                          <TimePicker
                            allowClear={false}
                            className='timepicker'
                            format="hh:mm A"
                            size=''
                            value={dayjs(inputs[inputs.SelectedDay][element.SubTitle2], "M/D/YYYY hh:mm:ss A")}
                            onChange={(time) => addTime(time.format('hh:mm:ss A'), element.SubTitle2)}
                          />
                        </div>
                      </div>
                    </div>
                ))}
                <button className='editschedulebtn btnminwidth' onClick={() => sendSchedules('save')}>Save</button> &emsp;
                <button className='closeschedulebtn btnminwidth' onClick={() => setIsEditOpen(!isEditOpen)}>Close</button>
              </div>
            ) :
            (
              <div>
                <div className='openingHoursBox' style={style}>
                  <h3>Opening Hours</h3>
                  <div >
                    <button className='editschedulebtn' onClick={() => setIsHolidayModalOpen(!isHolidayModalOpen)}>Add Special Holiday</button> &emsp;
                    <button className='editschedulebtn' onClick={() => setIsEditOpen(!isEditOpen)}>Edit Schedule</button>
                  </div>
                </div>
                <Modal
                  style={{ maxHeight: 500 }}
                  title="Add Special Day/Holiday"
                  open={isHolidayModalOpen}
                  footer={false}
                  centered
                  onCancel={() => setIsHolidayModalOpen(!isHolidayModalOpen)}
                >
                  <div>
                    <div className='datePickerBox'>
                      <DatePicker
                        allowClear={false}
                        onChange={(date) => {
                          console.log(date)
                          const newState = { ...inputs }
                          newState.SelectedHolidayDate = date.format('YYYY-MM-DD')
                          setInputs(newState)
                        }}
                        minDate={dayjs(inputs.HolidayMinDate, 'YYYY/MM/DD')}
                        maxDate={dayjs(inputs.HolidayMaxDate, 'YYYY/MM/DD')}
                      /> &emsp;
                      <button className='editschedulebtn' onClick={() => { addHolidays(); }}>Add</button>
                    </div>
                    <br />
                    <span className='holidayTitle'>List of Special Holidays</span>
                    <div style={{ maxHeight: 300, overflowY: 'auto' }}>
                      <table className='holidayTable'>
                        {inputs.Holidays.map((holidayDate, index) => (
                          <tr>
                            <td className='dateTd'>
                              <span>{holidayDate}</span>
                            </td>
                            <td className='deleteTd'>
                              <button style={{ border: 'none', backgroundColor: 'white' }} onClick={() => deleteHoliday(holidayDate)}>
                                <MdOutlineDeleteOutline color='red' size={20} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </Modal>
                <div className='setdifferentTime'>
                  <div className='diffhrsBox' style={style}>
                    <Checkbox
                      size='small'
                      checked={isDifferentHours}
                      onChange={async (e) => { await setIsDifferentHours(e.target.checked); changeDiffrentHours() }}
                      style={{ color: '#2C394B' }}
                      id='checkdiff'
                    />
                    <label htmlFor='checkdiff'><span>Set different operating hours for pickup & delivery</span></label>
                  </div>
                  <div className='tableDiv'>
                    <table className='scheduletable'>
                      <thead>
                        <tr>
                          <th className='tableRows'>Day</th>
                          <th className='tableRows'>Open</th>
                          <th className='tableRows'>Close</th>
                          <th className='tableRows'>First Order</th>
                          <th className='tableRows'>Last Order</th>
                          {isDifferentHours &&
                            <th className='tableRows'>First Delivery</th>
                          }
                          {isDifferentHours &&
                            <th className='tableRows'>Last Delivery</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {inputs.Days?.map((day) =>
                          <tr>
                            <td className='tableRows'>{day}</td>
                            <td className='tableRows'>{getTimeFormated(inputs[day]['Open Time']) === '00:00:00 AM' ? 'Closed' : getTimeFormated(inputs[day]['Open Time'])}</td>
                            <td className='tableRows'>{getTimeFormated(inputs[day]['Close Time'])}</td>
                            <td className='tableRows'>{getTimeFormated(inputs[day]['First Order At'])}</td>
                            <td className='tableRows'>{getTimeFormated(inputs[day]['Last Order At'])}</td>
                            {isDifferentHours &&
                              <td className='tableRows'>{getTimeFormated(inputs[day]['First Delivery At'])}</td>
                            }
                            {isDifferentHours &&
                              <td className='tableRows'>{getTimeFormated(inputs[day]['Last Delivery At'])}</td>
                            }
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )
          }
          {/* <Form> */}
          {/* <div className='Form_root_Inner'> */}
          {/* <h2>Opening hours</h2>
          <br />
          <label>Monday-Sunday:	10:00 AM - 08:00 PM</label> <a id="aEdit" onClick={() => { document.getElementById('divTime').style.display = "block"; document.getElementById('aEdit').style.display = "none"; document.getElementById('aAddHours').style.display = "none"; }}>Edit</a>
          <br /> */}

          {/* <div id="divTime" hidden="true">
            <div>
              <Checkbox label="Mon" />
              <Checkbox label="Tue" />
              <Checkbox label="Wed" />
              <Checkbox label="Thu" />
              <Checkbox label="Fri" />
              <Checkbox label="Sat" />
              <Checkbox label="Sun" /></div>
            <div>
              <TimeField />
              <TimeField />
            </div>
            <div>
              <Button>Save</Button>
              <Button onClick={() => { document.getElementById('divTime').style.display = "none"; document.getElementById('aEdit').style.display = "block"; document.getElementById('aAddHours').style.display = "block"; }}>Cancel</Button>
            </div>
          </div> */}

          {/* <a id="aAddHours" onClick={() => { document.getElementById('divTime').style.display = "block"; document.getElementById('aEdit').style.display = "none"; document.getElementById('aAddHours').style.display = "none"; }}>Add Hours</a> */}
          {/* <div>
            <Checkbox checked={inputs.agree}
              label="Set different operating hours for pickup and delivery"
              name="agree"
              onChange={onChange}
            />
          </div> */}
          {/* <div><h2>Exceptions</h2> */}
          {/* <a onClick={() => setvisible(true)}>Add special day / holiday</a> */}
          {/* <Model isOpen={visible}> */}
          {/* <h1> Model Body</h1> <button onClick={()=>setvisible(false)}>Close</button>  */}
          {/* <SpecialDay /> */}
          {/* </Model> */}
          {/* <a>Pause services</a> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className='formButtonMain'> */}
          {/* <Button>Yes</Button> */}
          {/* <Button>No</Button> */}
          {/* </div> */}
          {/* </Form> */}
        </div>
        {isEditOpen ? (null) :
          (<div className='bottomDiv' style={style}>
            {/* <button className='savebtn' onClick={() => { sendSchedules('next') }}>Save</button> */}
            <LoadingButton
              className='savebtn'
              onClick={() => sendSchedules('next')}
              loading={loading}
              loadingPosition="end"
              endIcon={loading ? <CheckBoxOutlineBlankIcon color={"#2C394B"} style={{ color: "#2C394B" }} /> : ''}
            >
              <span>Save</span>
            </LoadingButton>
          </div>)}
        <SessionEnd openOrNot={unauthorized} />
      </div>
    </div>
  )
}