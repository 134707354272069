import React, { useEffect, useState } from "react";
import "../App.css";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Outlet, useNavigate } from "react-router-dom";
import SidebarContent from "./SidebarContent";
import { Button, Layout, Menu, Drawer } from "antd";
import SidebarMenuList from "./SidebarMenuList";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  BarsOutlined,
} from "@ant-design/icons";
import loginlogo from "../cloudpos.png";
import { Footer } from "antd/es/layout/layout";
import bigLogo from "../images/big-logo.png";
import smallLogo from "../images/sml-logo.png";
import { MenuOutlined } from "@mui/icons-material";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SettingsPhoneOutlinedIcon from "@mui/icons-material/SettingsPhoneOutlined";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { LiaEnvelope } from "react-icons/lia";
import { LiaPhoneSolid } from "react-icons/lia";

const { Header, Sider, Content } = Layout;

function Sidebar() {
  function Logout() {
    localStorage.clear();
    navigate("/");
  }

  var IsApprovedUser = -1,
    IsSuperAdmin = false,
    isClass = "";
  if (localStorage.getItem("UserDetails")) {
    IsApprovedUser = JSON.parse(
      localStorage.getItem("UserDetails")
    ).IsApprovedUser;
    IsSuperAdmin = JSON.parse(localStorage.getItem("UserDetails")).IsSuperAdmin;
    if (IsApprovedUser === false) isClass = "form-overlay";
  }

  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  //const [visible, setVisible] = useState(true);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [width, setWidth] = useState(256)
  useEffect(() => {
    // setWidth(window.innerWidth)
    // window.onload = function () {
    //   adjustSecondDivWidth();
    //   window.addEventListener('resize', adjustSecondDivWidth);
    // };

    window.onload = function () {
      console.log(width);
      adjustSecondDivWidth();
      window.addEventListener('resize', adjustSecondDivWidth);
    };
  }, [window.innerWidth])

  function adjustSecondDivWidth() {
    if (window.innerWidth > 800) {
      onClose()
    }
  }

  // function adjustSecondDivWidth() {
  //   if (document.getElementsByClassName('ant-layout-sider')) {
  //     var firstDivWidth = document.getElementsByClassName('ant-layout-sider').offsetWidth;
  //     var secondDivWidth = window.innerWidth - firstDivWidth;
  //     setWidth(secondDivWidth)
  //     console.log(secondDivWidth);
  //   }
  // }

  var isClass = "",
    IsSuperAdmin = false;
  if (localStorage.getItem("UserDetails")) {
    var IsApprovedUser = JSON.parse(
      localStorage.getItem("UserDetails")
    ).IsApprovedUser;

    IsSuperAdmin = JSON.parse(localStorage.getItem("UserDetails")).IsSuperAdmin;
    if (IsApprovedUser === false) isClass = "form-overlay";
  }

  return (
    // <div className="appMain">
    //   <Button
    //     onClick={() => {
    //       setVisible(true);
    //     }}
    //   >
    //     Menus
    //   </Button>
    //   <div className="appMainInner">
    //     <Drawer
    //       visible={visible}
    //       title={
    //         <img
    //           src="http://staging.m4.imenu360.com/iMenu360Reseller/assets/media/logos/logo-dark.png"
    //           alt="Logo"
    //         />
    //       }
    //       footer={
    //         <div className="MenubarLogout">
    //           <ExitToAppIcon onClick={() => Logout()} />
    //         </div>
    //       }
    //       onClose={() => setVisible(false)}
    //       placement="left"
    //     >
    //       <SidebarContent></SidebarContent>
    //     </Drawer>
    //     <div id="divSub" className="RightMenu main">
    //       <div className={isClass}>
    //         {" "}
    //         <Outlet></Outlet>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    // <div>
    //     <Layout>
    //   <Sider trigger={null} collapsible collapsed={collapsed}>
    //     <div className="demo-logo-vertical" />
    //     <Menu
    //       theme="dark"
    //       mode="inline"
    //       defaultSelectedKeys={['1']}
    //       items={
    //       //   [
    //       //   {
    //       //     key: '1',
    //       //     icon: <UserOutlined />,
    //       //     label: 'nav 1',
    //       //   },
    //       //   {
    //       //     key: '2',
    //       //     icon: <VideoCameraOutlined />,
    //       //     label: 'nav 2',
    //       //   },
    //       //   {
    //       //     key: '3',
    //       //     icon: <UploadOutlined />,
    //       //     label: 'nav 3',
    //       //   },
    //       // ]
    //       items
    //     }
    //     />
    //     {/* <SidebarContent/> */}
    //   </Sider>
    //   <Layout>
    //     <Header
    //       style={{
    //         padding: 0,
    //         background: colorBgContainer,
    //       }}
    //     >
    //       <Button
    //         type="text"
    //         icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    //         onClick={() => setCollapsed(!collapsed)}
    //         style={{
    //           fontSize: '16px',
    //           width: 64,
    //           height: 64,
    //         }}
    //       />
    //     </Header>
    //     <Content
    //       style={{
    //         margin: '24px 16px',
    //         padding: 24,
    //         minHeight: 280,
    //         background: colorBgContainer,
    //         borderRadius: borderRadiusLG,
    //       }}
    //     >
    //       Content
    //     </Content>
    //   </Layout>
    // </Layout>
    // </div>
    <>
      <div className="resellerMain1">
        <Layout>
          <div id="subResellerMain1">
            <Sider
              // style={{ width:256 }}
              trigger={null}
              collapsed={collapsed}
              collapsible
              width={256}
            >
              <div className="leftpanellogo">
                {collapsed ? (
                  <img className="smallLogo" src={smallLogo} />
                ) : (
                  <img className="bigLogo" src={bigLogo} />
                )}
              </div>
              <div className="scrollDrawer2">
                <SidebarMenuList />
              </div>
              <div className="MenubarLogout">
                <ExitToAppIcon onClick={() => Logout()} />
              </div>
            </Sider>
          </div>
          <Layout style={{ marginLeft: width }}>
            <div className="headerMobile">
              <Button
                type="text"
                onClick={() => {
                  setCollapsed(!collapsed);
                  if (width == 256) {
                    setWidth(80)
                  } else {
                    setWidth(256)
                  }
                }}
                icon={<MenuOutlined sx={{ fontSize: 30 }} />}
              ></Button>
              <h2>{localStorage.getItem("Restaurantname") ? localStorage.getItem("Restaurantname") : ""}</h2>
            </div>
            {/* <Header style={{ background: "transparent", padding: "0 16px" }}>
          <Button
            type="text"
            onClick={() => setCollapsed(!collapsed)}
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          ></Button>
        </Header> */}
            <Content>
              <div id="divSub" className="RightMenu main">
                <div >
                {/* <div className={isClass}> */}
                  <Outlet />
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>

      <div className="resellerMain2">
        <Layout>
          <Drawer
            width={256}
            placement={'left'}
            closable={false}
            onClose={onClose}
            open={open}
            key={'left'}
            style={{ backgroundColor: '#001529' }}
          >
            <Sider
              trigger={null}
              // collapsed={collapsed}
              collapsible
            >
              <div className="leftpanellogo">
                {collapsed ? (
                  <img className="smallLogo" src={smallLogo} />
                ) : (
                  <img className="bigLogo" src={bigLogo} />
                )}
              </div>
              <div className="scrollDrawer">
                <Menu
                  theme="dark"
                  mode="inline"
                >
                  <Menu.SubMenu
                    key="Setup"
                    icon={<PhonelinkSetupIcon />}
                    // className="menu-setup"
                    id="menu-setup"
                    title="Setup"
                    // onTitleClick={() => {
                    //   navigate("/reseller/setup/chainlist");
                    // }}
                  >
                    <Menu.SubMenu key="RestaurantBasics" title="Restaurant Basics">
                      <Menu.Item
                        key="NameAdress"
                        onClick={() => {
                          navigate("/reseller/setup/name_adress");
                          onClose()
                        }}
                      >
                        Name & Address
                      </Menu.Item>
                      <Menu.Item
                        key="OwnerPersonalInformation"
                        onClick={() => {
                          navigate("/reseller/setup/owner_personal_info");
                          onClose()
                        }}
                      >
                        Owner Personal Information
                      </Menu.Item>
                      <Menu.Item
                        key="Location"
                        onClick={() => {
                          navigate("/reseller/setup/location");
                          onClose()
                        }}
                      >
                        Location
                      </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu
                      key="ServicesOpeningHours"
                      title="Services & hours"
                    >
                      <Menu.Item
                        key="OnlinePayment"
                        onClick={() => {
                          navigate("/reseller/setup/online_payment");
                          onClose()
                        }}
                      >
                        Online Payment
                      </Menu.Item>
                      <Menu.Item
                        key="Pickup"
                        onClick={() => {
                          navigate("/reseller/setup/pickup");
                          onClose()
                        }}
                      >
                        Pickup
                      </Menu.Item>
                      <Menu.Item
                        key="Delivery"
                        onClick={() => {
                          navigate("/reseller/setup/delivery");
                          onClose()
                        }}
                      >
                        Delivery
                      </Menu.Item>

                      <Menu.Item
                        key="DineIn"
                        onClick={() => {
                          navigate("/reseller/setup/dine_in");
                          onClose()
                        }}
                      >
                        Dine-In
                      </Menu.Item>
                      <Menu.Item
                        key="Curbside"
                        onClick={() => {
                          navigate("/reseller/setup/curbside");
                          onClose()
                        }}
                      >
                        Curbside
                      </Menu.Item>
                      <Menu.Item
                        key="OpeningHours"
                        onClick={() => {
                          navigate("/reseller/setup/opening_hours");
                          onClose()
                        }}
                      >
                        Opening Hours
                      </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu key="PaymentTaxesLegal" title="Payment, taxes & legal">
                      <Menu.Item
                        key="Taxation"
                        onClick={() => {
                          navigate("/reseller/setup/taxation");
                          onClose()
                        }}
                      >
                        Taxation
                      </Menu.Item>
                      <Menu.Item
                        key="ServiceCharges"
                        onClick={() => {
                          navigate("/reseller/setup/service_charges");
                          onClose()
                        }}
                      >
                        Service Charges
                      </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key="Menu" onClick={()=>{navigate('/reseller/setup/menu'); onClose();}}>Menu</Menu.Item>
                    <Menu.SubMenu
                      key="MenuSetupReceiveOrders"
                      title="Receive orders"
                    >
                      <Menu.Item
                        key="ReceiveOrders"
                        onClick={() => {
                          navigate("/reseller/setup/receive_orders");
                          onClose()
                        }}
                      >
                        Receive orders
                      </Menu.Item>
                    </Menu.SubMenu>
                  </Menu.SubMenu>

                  <Menu.SubMenu
                    key="Restaurant"
                    icon={<RestaurantIcon />}
                    // className="menu-restaurant"
                    id="menu-restaurant"
                    title="Restaurant"
                  >
                    <Menu.Item
                      key="RestaurantChainList"
                      onClick={() => {
                        navigate("/reseller/setup/chainlist");
                        onClose()
                      }}
                    >
                      Restaurant Chain List
                    </Menu.Item>
                    <Menu.Item
                      key="RestaurantList"
                      onClick={() => {
                        navigate("/reseller/restaurant/list");
                        onClose()
                      }}
                    >
                      Restaurant List
                    </Menu.Item>
                    <Menu.Item
                      key="RestaurantReports"
                      onClick={() => {
                        navigate("/reseller/restaurant/reports");
                        onClose()
                      }}
                    >
                      Restaurant Reports
                    </Menu.Item>
                  </Menu.SubMenu>

                  <Menu.Item
                    key="Commission"
                    icon={<Diversity1Icon />}
                    // className="menu-commission"
                    id="menu-commission"
                    onClick={() => {
                      navigate("/reseller/commission/commission");
                      onClose()
                    }}
                  >
                    Commission
                  </Menu.Item>

                  <Menu.Item
                    key="Support"
                    icon={<ContactSupportIcon />}
                    // title="Support"
                    // className="menu-support"
                    id="menu-support"
                    onClick={() => {
                      navigate("/reseller/support/contact_us");
                      onClose()
                    }}
                  > Support
                    {/* <Menu.SubMenu key="CustomerSupport" title="Customer Support">
                      <Menu.Item key="CustomerSupportEmail" icon={<LiaEnvelope />}>
                        <a href="mailto:support@imenu360.com">support@imenu360.com</a>
                      </Menu.Item>
                      <Menu.Item key="CustomerSupportPhone" icon={<LiaPhoneSolid />}>
                        <a href="tel:86-629-9646">(866)629-9646 Ext. 1</a>
                      </Menu.Item>
                    </Menu.SubMenu>

                    <Menu.SubMenu key="ContactSales" title="Contact Sales">
                      <Menu.Item key="ContactSalesEmail" icon={<LiaEnvelope />}>
                        <a href="mailto:sales@imenu360.com">sales@imenu360.com</a>
                      </Menu.Item>
                      <Menu.Item key="ContactSalesPhone" icon={<LiaPhoneSolid />}>
                        <a href="tel:86-629-9646">(866)629-9646 Ext. 2</a>
                      </Menu.Item>
                    </Menu.SubMenu>

                    <Menu.SubMenu key="Billing" title="Billing">
                      <Menu.Item key="BillingEmail" icon={<LiaEnvelope />}>
                        <a href="mailto:billing@imenu360.com">billing@imenu360.com</a>
                      </Menu.Item>
                      <Menu.Item key="BillingPhone" icon={<LiaPhoneSolid />}>
                        <a href="tel:86-629-9646">(866)629-9646 Ext. 4</a>
                      </Menu.Item>
                    </Menu.SubMenu> */}
                  </Menu.Item>

                  {IsSuperAdmin && (
                    <Menu.Item
                      key="PendingApprovals"
                      icon={<PendingActionsIcon />}
                      // className="menu-pendingapprovals"
                      id="menu-pendingapprovals"
                      onClick={() => {
                        navigate("/reseller/approval/pending_approval");
                        onClose()
                      }}
                    >
                      Pending Approvals
                    </Menu.Item>
                  )}

                  <Menu.Item
                    key="UpdateProfile"
                    icon={<AccountBoxIcon />}
                    // className="menu-udpdateprofile"
                    id="menu-updateprofile"
                    onClick={() => {
                      navigate("/reseller/profileupdate");
                      onClose()
                    }}
                  >
                    Update Profile
                  </Menu.Item>

                  {!IsApprovedUser && (
                    <Menu.Item
                      key="ScheduleCall"
                      icon={<SettingsPhoneOutlinedIcon />}
                      // className="menu-schedulecall"
                      id="menu-schedulecall"
                      onClick={() => {
                        navigate("/reseller/schedulecall");
                      }}
                    >
                      Schedule Call
                    </Menu.Item>
                  )}
                </Menu>
              </div>
              <div className="MenubarLogout">
                <ExitToAppIcon onClick={() => Logout()} />
              </div>
            </Sider>
          </Drawer>
          <Layout>
            <div className="headerMobile">
              <Button
                type="text"
                onClick={() => showDrawer()}
                icon={<MenuOutlined sx={{ fontSize: 30 }} />}
              ></Button>
              <h2>{localStorage.getItem("Restaurantname") ? localStorage.getItem("Restaurantname") : ""}</h2>
            </div>
            <Content>
              <div id="divSub" className="RightMenu main">
                <div>
                {/* <div className={isClass}> */}
                  <Outlet />
                </div>
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>

    </>
  );
}
export default Sidebar;
