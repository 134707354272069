import React, { useEffect, useState } from "react";
import { Input, Form } from "@start-base/react-form-elements";
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Label } from "@mui/icons-material";
import Axios from "axios";
import { message } from "antd";
import validator from "validator";
import { APIUrl } from "../..";
import SessionEnd from './SessionEnd';
import PhoneInput from "react-phone-input-2";
import ReactIntlTelInput from 'react-intl-tel-input-v2';
//import { isAllowedUser } from "../Login"
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function OwnerPersonalInfo() {
  const [showphonevalid, setShowPhoneValid] = useState("");
  const [optionCoutriesList, setOptionCoutriesList] = useState([]);
  const [optionStateList, setOptionStateList] = useState([]);
  const [AllStatesList, setAllStatesList] = useState([]);
  const [errormsg, setErrorMsg] = useState("");
  const [inputs, setInputs] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [style, setStyle] = useState(null);
  const [unauthorized, setUnauthorized] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState()
  const [loading, setLoading] = useState(false);
  const [newNO, setnewNo] = useState('')

  useEffect(() => {
    if (
      localStorage.getItem("RestLocationId") == -1 ||
      localStorage.getItem("RestLocationId") == null
    ) {
      // setErrorMsg("Please fill restaurant info first.");
      messageApi.open({
        type: "warning",
        content: "Please select restaurant first & then you can edit",
      });
      setStyle({
        pointerEvents: "none",
        opacity: 0.5,
      });
    }

    const restChainId = localStorage.getItem("RestChainId")
    if (restChainId == 33566 || restChainId == 33590) {
      setStyle({
        pointerEvents: 'none',
        opacity: 0.5
      })
    }

  }, []);

  const navigate = useNavigate();
  var OwnerId = -1;

  var Token = "";
  if (localStorage.getItem("UserDetails"))
    Token = JSON.parse(localStorage.getItem("UserDetails")).Token;

  var RestaurantEditDetails = -1;
  if (localStorage.getItem("RestaurantEditDetails") != -1)
    RestaurantEditDetails = JSON.parse(
      localStorage.getItem("RestaurantEditDetails")
    );

  const newdata = [];
  newdata.ownerName = inputs.ownerName;
  newdata.ownerEmail = inputs.ownerEmail;
  newdata.ownerPhone = newNO;
  newdata.street = inputs.street;
  newdata.city = inputs.city;
  newdata.postalcode = inputs.postalcode;
  newdata.country = inputs.country;
  newdata.state = inputs.state;

  const onChange = (e) => {
    if (e.target.name == "ownerPhone") {
      const result = e.target.value.replace(/\D/g, "");
      setShowPhoneValid(result);
    }

    const { name, value, type } = e.target;

    setInputs((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? !prevState[name] : value,
    }));

    if (e.target.name == "country") {
      const filtered = AllStatesList.filter((AllStatesList) => {
        return AllStatesList.CountryCode === e.target.value;
      });
      setOptionStateList(filtered);
    }
  };

  function SaveOwnerPersonalInfo(e) {
    e.preventDefault();
    setLoading(true)
    if (newNO?.split('+')[1]?.split(' ')[1].length > 10) {
      messageApi.open({
        type: "error",
        content: "Enter a valid phone number",
      });
      setLoading(false)
      return
    }
    if (
      localStorage.getItem("RestLocationId") != -1 &&
      localStorage.getItem("RestLocationId") != null
    ) {
      if (
        newdata.ownerName &&
        newdata.ownerEmail &&
        newdata.street &&
        newdata.city &&
        newdata.postalcode &&
        newdata.country &&
        newdata.state
      ) {
        if (validator.isEmail(newdata.ownerEmail)) {
          var OwnerId = -1;
          if (RestaurantEditDetails != -1 && RestaurantEditDetails != null)
            OwnerId = RestaurantEditDetails.Oid;

          Axios.post(APIUrl + "OwnerInfo", {
            data: {
              token: Token,
              RestLocationId: localStorage.getItem("RestLocationId"),
              ownerPersonalInfo: {
                Oid: OwnerId, //-1 if new
                OwnerName: newdata.ownerName,
                OwnerEmail: newdata.ownerEmail,
                OwnerPhone: newNO,
                OwnerAddress: {
                  Country: newdata.country,
                  StreetNameNumber: newdata.street,
                  State: newdata.state,
                  City: newdata.city,
                  PostalCode: newdata.postalcode,
                },
              },
            },
          })
            .then((response) => {
              if (RestaurantEditDetails != -1) {
                var obj = {
                  Oid: response.data.data.OwnerId,
                  OwnerAddress: {
                    City: newdata.city,
                    Country: newdata.country,
                    LookupState: newdata.state,
                    PostalCode: newdata.postalcode,
                    State: newdata.state,
                    StreetNameNumber: newdata.street,
                  },
                  OwnerEmail: newdata.ownerEmail,
                  OwnerName: newdata.ownerName,
                  OwnerPhone: newNO,
                };
                localStorage.setItem(
                  "RestaurantEditDetails",
                  JSON.stringify(obj)
                );
              }
              setLoading(false)
              navigate("/reseller/setup/location");
            })
            .catch((error) => {
              setLoading(false)
              if (error?.response?.data == "Unauthorized") {
                setUnauthorized(true)
              }
            });
        } else
          messageApi.open({ type: "warning", content: "Email is not valid" });
      } else
        messageApi.open({
          type: "warning",
          content: "All fields are mandatory",
        }); //setErrorMsg("All fields are mandatory");
    } else
      messageApi.open({
        type: "warning",
        content: "Please fill restaurant info first.",
      }); //setErrorMsg("Please fill restaurant info first.");
    setLoading(false)
  }

  var StateList = [];
  //Get Coutries and States
  const getCS = () => {
    Axios.post(APIUrl + "CountriesAndStates", {
      data: { token: Token },
    })
      .then((response) => {
        const countries = response.data.data.countries;
        const states = response.data.data.states;
        setOptionCoutriesList(countries);
        setAllStatesList(states);
        StateList = states;

        if (
          RestaurantEditDetails != -1 &&
          RestaurantEditDetails != null &&
          RestaurantEditDetails.Oid != 0
        )
          handleUpdate();
      })
      .catch((e) => {
        setOptionCoutriesList([]);
        setAllStatesList([]);
        if (e?.response?.data == "Unauthorized") {
          setUnauthorized(true)
        }
      });
  };
  React.useEffect(() => getCS(), []);

  function handleUpdate() {
    var OwnerDetails = RestaurantEditDetails;

    const filtered = StateList.filter((StateList) => {
      return StateList.CountryCode === OwnerDetails.OwnerAddress.Country;
    });
    setOptionStateList(filtered);

    setInputs((prevState) => ({
      ...prevState,
      ownerName: OwnerDetails.OwnerName,
      ownerEmail: OwnerDetails.OwnerEmail,
      country: OwnerDetails.OwnerAddress.Country,
      street: OwnerDetails.OwnerAddress.StreetNameNumber,
      state: OwnerDetails.OwnerAddress.State,
      city: OwnerDetails.OwnerAddress.City,
      postalcode: OwnerDetails.OwnerAddress.PostalCode,
    }));
    setShowPhoneValid(OwnerDetails.OwnerPhone);
    setnewNo(OwnerDetails.OwnerPhone)
    // setPhoneNumber(OwnerDetails.OwnerPhone.split('-')[1].split(',')[0])
    // var d = {
    //   "iso2": OwnerDetails.OwnerPhone.split(',')[1],
    //   "dialCode": OwnerDetails.OwnerPhone.split('-')[0].split('+')[1],
    //   "phone": ""
    // }
    // setCountryCode(d)
  }

  return (
    // <div id="divFormOverlay" className="form-overlay">
    <div className="contentForm">
      <div className="formHeading">
        <Button
          onClick={() => {
            if (localStorage.getItem("RestLocationId")) {
              navigate("/reseller/setup/name_adress");
            } else {
              navigate("/reseller/restaurant/list");
            }
          }}
        >
          Back
        </Button>
        <h2>Owner Personal Information</h2>
      </div>
      <Form onSubmit={SaveOwnerPersonalInfo}>
        {contextHolder}
        <div className="Form_root_Inner" style={style}>
          <Input
            label="Owner Name *"
            name="ownerName"
            onChange={onChange}
            value={inputs.ownerName}
            placeholder="Enter Name..."
            required="true"
          />
          <div className="formRow halfField">
            <Input
              label="Owner Email *"
              name="ownerEmail"
              onChange={onChange}
              value={inputs.ownerEmail}
              placeholder="Enter Email..."
              required="true"
              type="email"
            />
            {/* <Input
              label="Owner Phone *"
              name="ownerPhone"
              onChange={onChange}
              value={showphonevalid}
              placeholder="Enter Phone..."
              required="true"
            /> */}
            <div className="ph-num-field halfField">
              <label>
                Phone number</label>
             <PhoneInput
                country={'us'}
                // value={PhoneNumber}
                // value={showphonevalid}
                // onChange={onChange}
                value={newNO?.split('+')[1]?.split(' ')[0] + "" + newNO?.split('+')[1]?.split(' ')[1]}
                onChange={(value, country, event, formattedValue) => { setnewNo("+" + country.dialCode + " " + value.slice(country.dialCode.length)); }}
                inputProps={{ required: true }}
              ></PhoneInput>
              </div>
            {/* <div className="phonenocode">
              <label>Phone number</label>
              <ReactIntlTelInput
                intlTelOpts={{ preferredCountries: ['us'] }}
                value={countryCode}
                name="phone"
                onChange={async (phone) => { setCountryCode(phone); console.log(phone); }}
              />
              <span className="countryCode" >+{countryCode?.dialCode}</span>
              <input
                type="tel"
                name="phoneNumber"
                className="form-control"
                required
                value={phoneNumber}
                onChange={async (e) => { setPhoneNumber(e.target.value) }}
                onFocus={() => { }}
              />
            </div> */}
          </div>
          <div className="InputSelect">
            <label>Country *</label>
            <select
              required="true"
              onChange={onChange}
              name="country"
              value={inputs.country}
            >
              <option value="">Choose Country</option>
              {optionCoutriesList.map((item) => (
                <option key={item.CountryCode} value={item.CountryCode}>
                  {item.CountryCode}
                </option>
              ))}
            </select>
          </div>
          <Input
            label="Street Name & Number *"
            name="street"
            onChange={onChange}
            value={inputs.street}
            placeholder="Enter Street Name & Number..."
            required="true"
          />
          <div className="InputSelect">
            <label>State *</label>
            <select
              required="true"
              onChange={onChange}
              name="state"
              value={inputs.state}
            >
              <option value="">Choose State</option>
              {optionStateList.map((item) => (
                <option key={item.State} value={item.State}>
                  {item.State}
                </option>
              ))}
            </select>
          </div>
          <div className="formRow">
            <Input
              label="City *"
              name="city"
              onChange={onChange}
              value={inputs.city}
              placeholder="Enter City..."
              required="true"
            />
            <Input
              label="Postal Code *"
              name="postalcode"
              onChange={onChange}
              value={inputs.postalcode}
              placeholder="Enter Postal Code..."
              required="true"
            />
          </div>
        </div>
        {/* {errormsg && (
          <div className="error-wrapper">
            <label>{errormsg}</label>
          </div>
        )} */}
        {/* <div className="error-msg">{errormsg}</div> */}
        <div className="formButtonMain" style={style}>
          {/* <Button
            // type="submit"
            onClick={() => SaveOwnerPersonalInfo()}
          >
            Next
          </Button> */}
          <LoadingButton
            // onClick={() => SaveOwnerPersonalInfo()}
            type="submit"
            loading={loading}
            loadingPosition="end"
            endIcon={loading ? <CheckBoxOutlineBlankIcon color={"#2C394B"} style={{ color: "#2C394B" }} /> : ''}
          >
            <span>Next</span>
          </LoadingButton>
        </div>
      </Form>
      <SessionEnd openOrNot={unauthorized} />
    </div>
    // </div>
  );
}
