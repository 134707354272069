import React from 'react';
import { Button } from '@mui/material';

export default function NewWebsites() {
   return (
    <div className='smlwindowHeight'>
      <div className='contentForm smallPageWindow'>
        <div className='formHeading'>
          <div className='btnwithText'>
            <h2>New Websites</h2></div>
          <Button className='black-colorBtn'>Next</Button>
        </div>
          <div className='contentFormBody'>
            <div className='textwithimgBlock'>
              <div className='textblck'>
                <p>
                Design, Development, Maintenance, Hosting, & Domain with an amazing multi-page website in a low monthly fee. Here are a few examples:-  simplysushiexpress.com, fierycrabseafood.com, siamthaicuisinetogo.com, manuelsburgerstogo.com
Interested?
                </p>
                <div className='formButtonMain'>
                  <Button>Schedule a Call</Button></div>
              </div>
            </div>
          </div>
      </div >
    </div>
  )
}